import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/studio.css";
import {
  selectCurrentConnectedWallet,
  selectIsSupperUser,
} from "../redux/slice/auth/auth-slice";
import {
  AimOutlined,
  ApiOutlined,
  BuildOutlined,
  CodeOutlined,
  DashboardOutlined,
  DollarOutlined,
  EditOutlined,
  KeyOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  SketchOutlined,
  TabletOutlined,
  UploadOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";

import Navbar from "../components/general/Navbar";
import { selectCurrentTab, setCurrentTab } from "../redux/slice/ui/ui-slice";
import { useNavigate } from "react-router-dom";
import { getCurrentWalletAddress } from "../utils/Metamask";
import { apiPOST } from "../apis/service";
import ic_eat_icon from "../assets/images/eat_icon.png";
import APIKeys from "../components/APIKeys/APIKeys";
import TickerEditor from "../components/TickerEditor/TickerEditor";
import Dashboard from "../components/Dashboard/Dashboard";
import ChannelWallets from "../components/ChannelWallets/ChannelWallets";
import SecondScreen from "../components/SecondScreen/SecondScreen";
import SupportedPlateforms from "../components/SupportedPlateforms/SupportedPlateforms";
import SDKs from "../components/SDKs/SDKs";
import EdgeAi from "../components/EdgeAi/EdgeAi";
import Users from "../components/Users/Users";
import ChannelPrompts from "../components/EdgeAi/ChannelPrompts";
import styles from "../styles/studio.module.scss";
const { Header, Sider, Content } = Layout;

const Studio = (props) => {
  const is_supper_user = useSelector(selectIsSupperUser);
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([
    {
      key: "dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "user_access_management",
      icon: <UserOutlined />,
      label: "User & Access Management",
      children: [
        {
          key: "users",
          icon: <UserOutlined />,
          label: "Users",
        },
        {
          key: "api_keys",
          icon: <KeyOutlined />,
          label: "API Keys",
        },
      ],
    },
    {
      key: "rewards_manager",
      icon: <UsergroupAddOutlined />,
      label: "Rewards Manager",
      children: [
        {
          key: "game_points",
          icon: <AimOutlined />,
          label: "Game Points",
        },
        {
          key: "channel_wallets",
          icon: <WalletOutlined />,
          label: "Wallets",
        },
      ],
    },
    {
      key: "ui_management",
      icon: <BuildOutlined />,
      label: "UI Management",
      children: [
        {
          key: "ticker_editor",
          icon: <EditOutlined />,
          label: "Ticker Editor",
        },
      ],
    },
    {
      key: "platform_support",
      icon: <SettingOutlined />,
      label: "Platform Support",
      children: [
        {
          key: "supported_plateforms",
          icon: <SketchOutlined />,
          label: "Supported platforms",
        },
        {
          key: "sdks",
          icon: <ApiOutlined />,
          label: "SDKs",
        },
      ],
    },
    {
      key: "edge_video_ai_tools",
      icon: <PlayCircleOutlined />,
      label: "Edge Video AI Tools",
      children: [
        {
          key: "command_prompts",
          icon: <CodeOutlined />,
          label: "Command Prompts",
        },
        {
          key: "edge_ai",
          icon: <TabletOutlined />,
          label: "QR & Second Screen",
        },
      ],
    },
  ]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const current_tab = useSelector(selectCurrentTab);

  useEffect(() => {
    setCurrentTab("dashboard");
    console.log("is_supper_user", is_supper_user);
    const filteredItems = is_supper_user
      ? items
      : items.map((item) => {
          if (item.key === "user_access_management") {
            // Remove the "Users" menu item from children
            const children = item.children.filter(
              (child) => child.key !== "users"
            );
            return { ...item, children };
          }
          return item;
        });
    setItems(filteredItems);
  }, [is_supper_user]);

  const dispatch = useDispatch();

  const handleSwitchTab = (tab_name) => {
    dispatch(setCurrentTab(tab_name.key));
  };

  useEffect(() => {
    const authenticateToken = async () => {
      console.log("checking if auth token");
      let token = JSON.parse(localStorage.getItem("user"));
      if (token == null) {
        navigate("/login");
      } else {
        // verify if it is valid still
        let current_connected_wallet = await getCurrentWalletAddress();
        try {
          const response = await apiPOST("verifyAuthToken", {
            walletAddress: current_connected_wallet,
          });
          if (!response.success) {
            navigate("/login");
          }
        } catch (error) {
          navigate("/login");
        }
      }
    };

    const tokenCheckIntervalId = setInterval(authenticateToken, 5 * 60000);

    // Clean up listeners and interval when the component unmounts
    return () => {
      clearInterval(tokenCheckIntervalId);
    };
    // No dependencies provided to run the effect only once on component mount
    // If you have dependencies, make sure to include them in the dependency array
  }, []);

  return (
    <div className={styles.container}>
      <Navbar />
      <Layout style={{ height: "90vh" }}>
        <Sider width={280} trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" />
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["dashboard"]}
            onClick={handleSwitchTab}
            items={items}
          ></Menu>
        </Sider>
        <Layout>
          <Content
            style={{
              margin: "0px 0px",
              padding: 0,
              minHeight: 280,
              background: "#F5F8FE",
            }}
          >
            {current_tab == "dashboard" && (
              <div
                className={styles.dashboard_container}
              >
                {/* <div className={styles.header}>Main Dashboard</div> */}
                <div className={styles.content}>
                  <Dashboard />
                </div>
              </div>
            )}
            {current_tab == "command_prompts" && (
              <div
                style={{
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="command_prompts-container"
              >
                <ChannelPrompts />
              </div>
            )}
            {current_tab == "users" && (
              <div
                style={{
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="users-container"
              >
                <Users />
              </div>
            )}
            {current_tab == "channel_wallets" && (
              <div
                style={{
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="channel-wallet-container"
              >
                <ChannelWallets />
              </div>
            )}
            {current_tab == "viewer_payments" && <div>Viewer payments</div>}
            {current_tab == "api_keys" && (
              <div
                style={{
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="api-keys-container"
              >
                {" "}
                <APIKeys />
              </div>
            )}
            {current_tab == "ticker_editor" && (
              <div
                style={{
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="ticker-editor-container"
              >
                <TickerEditor />
              </div>
            )}
            {current_tab == "edge_ai" && (
              <div
                style={{
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="edge_ai-container"
              >
                <EdgeAi />
              </div>
            )}
            {current_tab == "game_points" && (
              <div
                style={{
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="second-screen-container"
              >
                <SecondScreen />
              </div>
            )}
            {current_tab == "supported_plateforms" && (
              <div
                style={{
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="supported-plateforms-container"
              >
                <SupportedPlateforms />
              </div>
            )}
            {current_tab == "sdks" && (
              <div
                style={{
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                className="sdks-container"
              >
                <SDKs />
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default Studio;
