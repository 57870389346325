import { Divider, Modal, Radio, Switch, Table } from "antd";
import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../../utils/Metamask";
import { apiDELETE, apiPOST } from "../../../apis/service";
import {
  refreshListOfChannelBoosts,
  selectListOfChannelBoosts,
  setListOfChannelBoosts,
} from "../../../redux/slice/channel_boosts/channel_boosts-slice";
const data = [
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "Baeble",
    is_boost_enabled: true,
    address: "0xA69...ADB8",
    key: "0xA69...ADB8",
    boost: "43",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "24 Hour Free Movies",
    is_boost_enabled: false,
    address: "0xC69...D345",
    key: "0xC69...D345",
    boost: "0",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "Grappling",
    is_boost_enabled: true,
    address: "0xEB2...A1B8",
    key: "0xEB2...A1B8",
    boost: "12",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "AlliedEsports",
    is_boost_enabled: true,
    address: "0x2BE...5D40",
    key: "0x2BE...5D40",
    boost: "65",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "WPT",
    is_boost_enabled: false,
    address: "0xAE2...F2D2",
    key: "0xAE2...F2D2",
    boost: "0",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "SkiTV",
    is_boost_enabled: true,
    address: "0xCD3...1A3E",
    key: "0xCD3...1A3E",
    boost: "27",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "PokerNight",
    is_boost_enabled: true,
    address: "0x90B...9492",
    key: "0x90B...9492",
    boost: "82",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "euronews",
    is_boost_enabled: false,
    address: "0x34D...86B9",
    key: "0x34D...86B9",
    boost: "0",
  },
  // Add more random records here...
];
const ChannelsTable = ({ handleOpenEditBoostlModal, ...props }) => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const list_refresher = useSelector(refreshListOfChannelBoosts);
  const list_of_channel_boosts = useSelector(selectListOfChannelBoosts);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const dispatch = useDispatch();
  const handleCopyAddress = (address) => {
    message.success(`Address ${address} copied to clipboard`);
  };

  const handleEditDomain = async(record) => {
    console.log("Edit domain:", record);

  };

  const handleSwitchChanged = async(enable, record) => {
    console.log("Switch Changed:", record);

    try {
      let response = await apiPOST("channel/SetBoostEnable", {
        wallet_address: record.address,
        boost_status: enable
      });
      await refreshData()
    } catch (e) {
      console.log(e)
    }
  };

  const refreshData = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    setLoading(true);
    try {
      let response = await apiPOST("channel/GetChannelBoostDetails", {
        parent: current_connected_wallet,
      });
      if (response.rows.length > 0) {
        setItems([]);
        dispatch(setListOfChannelBoosts(response.rows));
      } else {
        setItems([]);
        dispatch(setListOfChannelBoosts([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiPOST("channel/GetChannelBoostDetails", {
          parent: current_connected_wallet,
        });
        if (response.rows.length > 0) {
          setItems([]);
          dispatch(setListOfChannelBoosts(response.rows));
        } else {
          setItems([]);
          dispatch(setListOfChannelBoosts([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [list_refresher]);

  useEffect(() => {
    if (list_of_channel_boosts.length > 0) {
      let temp = [];

      list_of_channel_boosts.forEach((element) => {
        temp.push({
          key: element.walletaddress,
          channel: element.name,
          icon: element.profileimageurl,
          is_boost_enabled: element.isboostenabled,
          address: element.walletaddress,
          boost: element.boostmultiple,
        });
      });
      setItems(temp);
    }else{
      setItems([]);
    }
  }, [list_of_channel_boosts]);


  const columns = [
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      render: (text, record) => (
        <div>
          <img src={record.icon} width={"5%"} />
          <span style={{marginLeft:"2%"}}>{record.channel}</span>
        </div>
      ),
    },
    {
      title: "Is boost enabled",
      dataIndex: "is_boost_enabled",
      key: "is_boost_enabled",
      render: (text, record) => (
        <div>
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            defaultChecked={record.is_boost_enabled}
            onChange={(status) => handleSwitchChanged(status, record)}
          />
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "boost",
      dataIndex: "boost",
      key: "boost",
      render: (text, record) => (
        <div>
          {text}
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleOpenEditBoostlModal(record)}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div style={{ marginTop: "1%" }}>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        pagination={pagination}
        columns={columns}
        dataSource={items}
        onChange={handleTableChange}
        loading={loading}
        rowKey="walletaddress"
      />
    </div>
  );
};

export default ChannelsTable;
