import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';

const GenerateAPIKeyModal = ({ isOpen, onClose, onCreate }) => {
  const [sdkTitle, setSdkTitle] = useState('');

  const handleOk = () => {
    if (sdkTitle) {
      onCreate(sdkTitle);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title="New API Key"
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
    >
      <Input
        placeholder="SDK Title"
        value={sdkTitle}
        onChange={(e) => setSdkTitle(e.target.value)}
      />
    </Modal>
  );
};

export default GenerateAPIKeyModal;
