import { combineReducers } from "redux";
import authReducer from "./slice/auth/auth-slice";
import uiReducer from "./slice/ui/ui-slice";
import api_keysReducer from "./slice/api_keys/api_keys-slice";
import dashboardReducer from "./slice/dashboard/dashboard-slice";
import channel_walletsReducer from "./slice/channel_wallets/channel_wallets-slice";
import channel_detailsReducer from "./slice/channel_details/channel_details-slice";
import channel_boostsReducer from "./slice/channel_boosts/channel_boosts-slice";

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  api_keys: api_keysReducer,
  dashboard: dashboardReducer,
  channel_wallets: channel_walletsReducer,
  channel_details: channel_detailsReducer,
  channel_boosts: channel_boostsReducer
});

export default rootReducer;
