import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Radio,
  Row,
  Dropdown,
  Button,
  Space,
  Form,
  Tooltip,
  message,
  Divider,
  Progress,
} from "antd";
import "../../styles/dashboard.css";
import WalletsTable from "./Table/Leatherboard";
import ChannelGames from "./Table/ChannelGames";
import ChannelTSV from "./Table/ChannelTSV";
import ChannelViewerTSV from "./Table/ChannelViewerTSV";
import { apiPOSTFile, apiPOST } from "../../apis/service";
import TSVDayGraph from "./Graphs/TSVDayGraph";
import TSVMonthGraph from "./Graphs/TSVMonthGraph";
import TSVWeekGraph from "./Graphs/TSVWeekGraph";
import countryList from 'react-select-country-list'
import country from 'countries-list';
// import CountUp, { useCountUp } from 'react-countup';
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMarketChange,
  selectMarketPrice,
  selectPlayersNumber,
  selectW2EPlayersNumber,
  selectReferralPoolBalance,
  selectStakingPoolBalance,
  selectWalletBalance,
  setMarketChange,
  setMarketPrice,
  setPlayersNumber,
  setW2EPlayersNumber,
  setReferralPoolBalance,
  setStakingPoolBalance,
  setWalletBalance,
  selectWalletsNumber,
  setWalletsNumber,
  setAppInstalled,
  selectAppInstalled,
  selectTotalGames,
  setTotalGames,
  selectTotalViewers,
  setTotalViewers,
  selectTotalViews,
  setTotalViews,
  setTSVDay,
  setActivatedWallets,
  selectActivatedWallets,
  setChannelViewers,
  selectChannelViewers,
} from "../../redux/slice/dashboard/dashboard-slice";
import { apiGET } from "../../apis/service";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { FaGamepad } from "@react-icons/all-files/fa/FaGamepad";
import { FaWallet } from "@react-icons/all-files/fa/FaWallet";
import { FaUsers } from "@react-icons/all-files/fa/FaUsers";
import { FaCoins } from "@react-icons/all-files/fa/FaCoins";
import { FaSortAmountUp } from "@react-icons/all-files/fa/FaSortAmountUp";
import { FaSwimmingPool } from "@react-icons/all-files/fa/FaSwimmingPool";

import { BiTimer } from "@react-icons/all-files/bi/BiTimer";
import { BsGraphUp } from "@react-icons/all-files/bs/BsGraphUp";

import { GiTabletopPlayers } from "@react-icons/all-files/gi/GiTabletopPlayers";
import { GiTrophy } from "@react-icons/all-files/gi/GiTrophy";

import { AiOutlineMonitor } from "@react-icons/all-files/ai/AiOutlineMonitor";
import { AiOutlineAndroid } from "@react-icons/all-files/ai/AiOutlineAndroid";
import { AiFillApple } from "@react-icons/all-files/ai/AiFillApple";

import { selectIsSupperUser } from "../../redux/slice/auth/auth-slice";
import {
  refreshList,
  refreshListOfAPIKeys,
  selectListOfAPIKeys,
  setListOfAPIKeys,
} from "../../redux/slice/api_keys/api_keys-slice";
import { setChannelGames } from "../../redux/slice/dashboard/dashboard-slice";
import { setTopPlayers } from "../../redux/slice/dashboard/dashboard-slice";

import {
  refreshListOfChannelDetails,
  selectListOfChannelDetails,
  setListOfChannelDetails,
} from "../../redux/slice/channel_details/channel_details-slice";

import ic_total_viewers from "../../assets/images/viewers.png";
import ic_time_spent_viewing from "../../assets/images/time_spent_viewing.png";
import ic_avg_time_spent_viewing from "../../assets/images/time_average_spent_viewingpng.png";
import ic_gaimified_players from "../../assets/images/games_played.png";
import ic_viewers from "../../assets/images/viewers.png";
import ic_activated_wallets from "../../assets/images/activated_wallets.png";
import ic_played_games from "../../assets/images/gamers.png";

import styles from "../../styles/dashboard.module.scss";
import { MdLeaderboard } from "react-icons/md";
import { AiFillAndroid, AiOutlineApple,  } from "react-icons/ai";

const Dashboard = () => {
  const options = useMemo(() => countryList().getData(), [])
  let number_of_players_old = 0;
  const [selectedGraph, SetPlacement] = useState("tsv_day_graph");
  const [lastUpdate, setLastUpdate] = useState(new Date().toLocaleTimeString());
  const staking_pool_balance = useSelector(selectStakingPoolBalance);
  const referral_pool_balance = useSelector(selectReferralPoolBalance);
  const wallet_balance = useSelector(selectWalletBalance);
  const market_change = useSelector(selectMarketChange);
  const market_price = useSelector(selectMarketPrice);
  const is_supper_user = useSelector(selectIsSupperUser);
  const number_of_players = useSelector(selectPlayersNumber);
  const number_of_viewers = useSelector(selectW2EPlayersNumber);
  const activated_wallets_number = useSelector(selectWalletsNumber);
  const app_installed = useSelector(selectAppInstalled);
  const total_games = useSelector(selectTotalGames);
  const total_viewers = useSelector(selectTotalViewers);
  const total_views = useSelector(selectTotalViews);
  const activated_wallets = useSelector(selectActivatedWallets);
  const dispatch = useDispatch();
  const [channels, setChannels] = useState([]);
  const list_of_api_keys = useSelector(selectListOfAPIKeys);
  const list_refresher = useSelector(refreshListOfAPIKeys);
  const list_of_channel_details = useSelector(selectListOfChannelDetails);
  const list_details_refresher = useSelector(refreshListOfChannelDetails);
  const [loading, setLoading] = useState(false);
  const [currentSelectedSdk, setCurrentSelectedSdk] = useState(
    list_of_api_keys[0]
  );
  const [currentSelectedKey, setCurrentSelectedKey] = useState({});
  const [currentSelectedDay, setCurrentSelectedDay] = useState(1);
  const [items, setItems] = useState(list_of_api_keys);
  const [currentSelectedChannel, setCurrentSelectedChannel] = useState(
    list_of_channel_details[0]
  );
  const [currentSelectedTsvChannel, setCurrentSelectedTsvChannel] = useState(
    list_of_channel_details[0]
  );

  const [copied, setCopied] = useState(false);
  const [currentSelectedFilterDay, setCurrentSelectedFilterDay] =
    useState("Today");
    const [currentSelectedFilterCountry, setCurrentSelectedFilterCountry] =
    useState({label: "All Countries", value: "All"});
  const [currentTimeStamp, setCurrentTimeStamp] = useState(24);

  const [days, setDays] = useState([
    {
      label: "Today",
      key: "24",
    },
    {
      label: "Yesterday",
      key: "48",
    },
    {
      label: "Last week",
      key: "168",
    },
    {
      label: "Last month",
      key: "720",
    },
    {
      label: "All Time",
      key: "All",
    },
  ]);

  useEffect(() => {
    options.unshift({label: "All Countries", value: "All"})

    return ()=>{ 
      options.shift()
    }
  },[])


  const changeGraph = (e) => {
    SetPlacement(e.target.value);
    if (e.target.value == "tsv_day_graph") {
      setCurrentSelectedDay(1);
    } else if (e.target.value == "tsv_week_graph") {
      setCurrentSelectedDay(7);
    } else if (e.target.value == "tsv_month_graph") {
      setCurrentSelectedDay(30);
    } else if (e.target.value == "tsv_all_graph") {
      setCurrentSelectedDay("All");
    }
  };
  // get api keys list from server

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiPOST("getSdksByWalletAddress", {
          walletAddress: current_connected_wallet,
        });
        if (response.data.length > 0) {
          dispatch(setListOfAPIKeys(response.data));
        } else {
          dispatch(setListOfAPIKeys([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [list_refresher]);
  // fill items from api list
  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = is_supper_user?[
        {label: "All Platforms",
         sdk_api_key : "All"}
        // {
        //   label: "Dingo TV",
        //   sdk_api_key: "3bf76d424eeb0a1dcbdef11da9d148d8",
        // },
        // {
        //   label: "Gaimify TV",
        //   sdk_api_key: "29b2b7af51a9f7143a907d7c0366e3e9",
        // },
      ]
      :[]
      list_of_api_keys.forEach((element) => {
        let tmp = {
          label: element.sdk_title,
          sdk_api_key: element.sdk_api_key,
          logo_url: element.logo_url,
        };
        temp.push(tmp);
      });
      setItems(temp);
      // setCurrentSelectedSdk(temp[0]);
      // setCurrentSelectedKey(temp[0]);
    }
  }, [list_of_api_keys]);
  // fill items from api list
  useEffect(() => {
    if (list_of_channel_details.length > 0) {
      let temp = [
        {
          label: `${currentSelectedKey.label}`,
          key: currentSelectedKey.sdk_api_key,
        },
      ];
      list_of_channel_details.forEach((element) => {
        let tmp = {
          label: element.channel_name,
          key: element.channel_id,
        };
        temp.push(tmp);
      });
      setChannels(temp);
      setCurrentSelectedChannel(temp[0]);
      setCurrentSelectedTsvChannel(temp[0]);
    }
  }, [list_of_channel_details]);

  useEffect(() => {
    // setCurrentSelectedKey(list_of_api_keys[0])
    if (!currentSelectedKey) {
      return;
    }

    apiGET(`tsv/getTsv/1`)
      .then((json) => setTSVDay(json.result))
      .catch((error) => {
        console.log("fetch data failed", error);
      });

    //Referral pool balance
    fetch(
      `https://eat.edgevideo.com/eat_balance/0x61F4994139f691dc37e469A40f81Fd67F77E2cD9`
    )
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data.balance);
        dispatch(setReferralPoolBalance(data.balance));
      });

    //Staking pool balance
    fetch(
      `https://eat.edgevideo.com/eat_balance/0x53ea7896579dea3cC223Eb679ec69f5Df1416B0B`
    )
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data.balance);
        dispatch(setStakingPoolBalance(data.balance));
      });

    //market price
    fetch(`https://eat.edgevideo.com/get_price`)
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        dispatch(setMarketPrice(data.price));
        dispatch(setMarketChange(data.change24h));
        //TODO:update it to call after some time
      });

    //wallet balance
    const fetchWalletBalance = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      fetch(`https://eat.edgevideo.com/eat_balance/${current_connected_wallet}`)
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log("balance", data.balance);
          dispatch(setWalletBalance(data.balance));
        });
    };
    fetchWalletBalance();

    //fetching recent players data
    apiGET(
      `gaimify/GetRecentPlayersNumber/${currentSelectedKey.sdk_api_key}/${currentSelectedDay}`
    )
      .then((response) => {
        console.log(response);
        dispatch(setPlayersNumber(response[0].count));
        setLastUpdate(new Date().toLocaleTimeString());
      })
      .catch((error) => {
        console.log(error);
      });
    // let playersNumberFetcher = setInterval(() => {
    //   apiGET(`gaimify/GetRecentPlayersNumber/${currentSelectedKey.sdk_api_key}/${currentSelectedDay}`)
    //     .then((response) => {
    //       console.log(response);
    //       dispatch(setPlayersNumber(response[0].count));
    //       setLastUpdate(new Date().toLocaleTimeString());
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }, 10000);

    //wallets number
    fetch(
      "https://api.covalenthq.com/v1/matic-mainnet/tokens/0x7C58D971A5dAbd46BC85e81fDAE87b511431452E/token_holders_v2/?key=cqt_rQY8fDVVPQfpq68937888Rq6Bv4T"
    )
      .then(async (response) => {
        let res = await response.json();
        console.log("Total Holders:", res);
        dispatch(setWalletsNumber(res.data.pagination.total_count - 6));
        setLastUpdate(new Date().toLocaleTimeString());
      })
      .catch((error) => {
        console.log(error);
      });

    apiGET(
      `gaimify/GetRecentViewersNumber/${currentSelectedKey.sdk_api_key}/${currentSelectedDay}`
    )
      .then((response) => {
        console.log(response);
        dispatch(setW2EPlayersNumber(response[0].count));
        setLastUpdate(new Date().toLocaleTimeString());
      })
      .catch((error) => {
        console.log(error);
      });
    // let viewerNumberFetcher = setInterval(() => {
    //   apiGET(`gaimify/GetRecentViewersNumber/${currentSelectedKey.sdk_api_key}/${currentSelectedDay}`)
    //     .then((response) => {
    //       console.log(response);
    //       dispatch(setW2EPlayersNumber(response[0].count));
    //       setLastUpdate(new Date().toLocaleTimeString());
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }, 10000);

    apiGET(
      `gaimify/GetTotalGamesNumberBySDK/${currentSelectedKey.sdk_api_key}/${currentSelectedDay}`
    )
      .then((response) => {
        console.log(response);
        dispatch(setTotalGames(response[0].total_game_count));
        setLastUpdate(new Date().toLocaleTimeString());
      })
      .catch((error) => {
        console.log(error);
      });

    apiGET(`tsv/getTotalViewersCount`)
      .then((response) => {
        console.log(response);
        dispatch(setTotalViewers(response.result[0].viewer_count));
        setLastUpdate(new Date().toLocaleTimeString());
      })
      .catch((error) => {
        console.log(error);
      });

      apiGET(`tsv/getTotalActivatedWallets`)
      .then((response) => {
        console.log(response);
        dispatch(setActivatedWallets(response.result[0].activated_wallets));
        setLastUpdate(new Date().toLocaleTimeString());
      })
      .catch((error) => {
        console.log(error);
      });

    apiGET(`tsv/getTotalViewsCount`)
      .then((response) => {
        console.log(response);
        dispatch(setTotalViews(response.result[0].total_time_spent));
        setLastUpdate(new Date().toLocaleTimeString());
      })
      .catch((error) => {
        console.log(error);
      });

    // let totalGamesFetcher = setInterval(() => {
    //   apiGET(
    //     `gaimify/GetTotalGamesNumberBySDK/${currentSelectedKey.sdk_api_key}/${currentSelectedDay}`
    //   )
    //     .then((response) => {
    //       console.log(response);
    //       dispatch(setTotalGames(response[0].total_games_played));
    //       setLastUpdate(new Date().toLocaleTimeString());
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }, 10000);

    apiGET(
      "gaimify/getAppInstallationNumberBySDK/" + currentSelectedKey.sdk_api_key
    )
      .then((response) => {
        console.log(response);
        dispatch(setAppInstalled(response[0]));
        setLastUpdate(new Date().toLocaleTimeString());
      })
      .catch((error) => {
        console.log(error);
      });

    fetchChannelGamesData(currentSelectedKey.sdk_api_key);
    fetchTopPlayerData(currentSelectedKey.sdk_api_key);
    fetchChannelData(currentSelectedKey.sdk_api_key);
    return () => {
      // clearInterval(playersNumberFetcher);
      // clearInterval(viewerNumberFetcher);
      // clearInterval(installNumberFetcher);
      // clearInterval(totalGamesFetcher);
    };
  }, [currentSelectedKey, currentSelectedDay]);

  const fetchChannelGamesData = async (key) => {
    setLoading(true);
    try {
      let response = await apiGET(
        `gaimify/GetRecentGamesNumberByChannel/${key}/${currentSelectedDay}`,
        {}
      );
      if (response.length > 0) {
        dispatch(setChannelGames(response));
      } else {
        dispatch(setChannelGames([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const fetchTopPlayerData = async (key) => {
    setLoading(true);
    try {
      let response = await apiGET(
        `gaimify/GetTopPlayersBySDK/${key}/${currentSelectedDay}`,
        {}
      );
      if (response.length > 0) {
        dispatch(setTopPlayers(response));
      } else {
        dispatch(setTopPlayers([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const fetchTopPlayerChannel = async (key) => {
    setLoading(true);
    try {
      let response = await apiGET(
        `gaimify/GetTopPlayersByChannel/${key}/${currentSelectedDay}`,
        {}
      );
      if (response.length > 0) {
        dispatch(setTopPlayers(response));
      } else {
        dispatch(setTopPlayers([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const fetchChannelData = async (key) => {
    setLoading(true);
    try {
      let response = await apiPOST("channel/GetChannelDetailsByApiKey", {
        apiKey: key,
      });
      if (response.length > 0) {
        dispatch(setListOfChannelDetails(response));
      } else {
        dispatch(setListOfChannelDetails([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const fetchAppInstallationCount = async (key) => {
    setLoading(true);
    try {
      let response = await apiGET(
        "gaimify/getAppInstallationNumberBySDK/" + key
      );
      if (response.length > 0) {
        dispatch(setAppInstalled(response[0]));
      } else {
        // dispatch(setAppInstalled(0));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };
  const fetchPlayerCount = async (key) => {
    setLoading(true);
    try {
      let response = await apiGET(
        `gaimify/GetRecentPlayersNumber/${key}/${currentSelectedDay}`
      );
      if (response.length > 0) {
        dispatch(setPlayersNumber(response[0].count));
      } else {
        // dispatch(setAppInstalled(0));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const fetchViewsCount = async (key) => {
    setLoading(true);
    try {
      apiGET(`gaimify/GetRecentViewersNumber/${key}/${currentSelectedDay}`)
        .then((response) => {
          console.log(response);
          dispatch(setW2EPlayersNumber(response[0].count));
          setLastUpdate(new Date().toLocaleTimeString());
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };
  const handleKeyClick = async (item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedKey(item);
    // fetchChannelGamesData(item.sdk_api_key);
    // fetchTopPlayerData(item.sdk_api_key);
    // fetchTotalGamesData(item.sdk_api_key);
    // fetchChannelData(item.sdk_api_key);
    // fetchAppInstallationCount(item.sdk_api_key);
    // fetchPlayerCount(item.sdk_api_key);
    // fetchViewsCount(item.sdk_api_key);
  };
  const handleChannelClick = async (item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedChannel(item);
    setCurrentSelectedTsvChannel(item);
    // await fetchChannelGamesData(item.key);
    if (item.label.search("All") >= 0 && item.label.search("Channels") >= 0) {
      fetchTopPlayerData(item.key);
    } else {
      await fetchTopPlayerChannel(item.key);
    }
  };

  const handleDaysClick = async (item) => {
    // Handle dropdown visibility change
    console.log("Dropdown handleDaysClick: ", item);
    setCurrentSelectedFilterDay(item.label);
    setCurrentTimeStamp(item.key);
  };

  const handleCountryClick = async (item) => {
    // Handle dropdown visibility change
    console.log("Dropdown handleCountryClick: ", item);
    setCurrentSelectedFilterCountry(item)
  };

  const handleGenerateLeaderBoard = () => {
    if (currentSelectedTsvChannel && currentSelectedTsvChannel.key) {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const phone = currentSelectedFilterCountry.value!="All"?
      country.countries[currentSelectedFilterCountry.value].phone
      :currentSelectedFilterCountry.value
      const url = `${baseUrl}/leaderboard?channel_id=${currentSelectedTsvChannel.key}&time_period=${currentTimeStamp}&team=${phone}`;
      window.open(url, "_blank");
    }
  };

  const handleGenerateEmbeddedCode = () => {
    if (currentSelectedTsvChannel && currentSelectedTsvChannel.key) {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const phone = currentSelectedFilterCountry.value!="All"?
      country.countries[currentSelectedFilterCountry.value].phone
      :currentSelectedFilterCountry.value
      const url = `${baseUrl}/leaderboard?channel_id=${currentSelectedTsvChannel.key}&time_period=${currentTimeStamp}&team=${phone}`;
      // Define the width and height of the iframe
      const iframeWidth = "900px";
      const iframeHeight = "750px";

      // Generate the HTML code for the iframe
      const embedCode = `<iframe src="${url}" width="${iframeWidth}" height="${iframeHeight}" frameborder="0"></iframe>`;

      // Copy the embed code to the clipboard
      navigator.clipboard.writeText(embedCode).then(() => {
        message.success("Leaderboard Embedded code is copied!");
        message.success("Leaderboard Embedded code is copied!");
      });
    }
  };

  return (
    <div className={styles.container}>
      {/* Header */}
      <div className={styles.header}>
        <div className={styles.left}>Dashboard</div>
        <div className={styles.right}>
          <div className={styles.row0}>
            <div className={styles.filter_header}>
              <div className={styles.days_filter}>
                <div className="graph_switch_container step2">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    Choose duration
                  </span>

                  <div>
                    <Radio.Group value={selectedGraph} onChange={changeGraph}>
                      <Radio.Button value="tsv_day_graph">Daily</Radio.Button>
                      <Radio.Button value="tsv_week_graph">Weekly</Radio.Button>
                      <Radio.Button value="tsv_month_graph">
                        Monthly
                      </Radio.Button>
                      <Radio.Button value="tsv_all_graph">
                        All Time
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <Form.Item className={styles.plateform_selector}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                  Select Platform
                </span>
                <Dropdown
                  type="primary"
                  size="3rem"
                  loading={loading}
                  menu={{
                    items:
                      items.length > 0
                        ? items.map((it) => ({
                            ...it,
                            onClick: () => handleKeyClick(it),
                          }))
                        : [], // Provide an empty array if there are no items
                  }}
                >
                  <Button>
                    <Space>
                      {items.length > 0
                        ? currentSelectedKey.label || "Select SDK"
                        : "No SDKs available"}

                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>

      {/* Top statistics */}
      
      <div className={styles.row1}>
      {
        is_supper_user?
           <div className={styles.col1}>
          <div className={styles.left}>
            {/* <img src={ic_total_viewers} className={styles.image} color="blue" /> */}
            <FaUsers className={styles.image} color="blue" />
          </div>
          <div className={styles.right}>
            <div className={styles.heading}>Total Viewers</div>
            <div className={styles.number}>
              {" "}
              <CountUp
                start={0}
                end={total_viewers}
                duration={2}
                separator=","
                decimals={0}
                decimal="."
                prefix=""
                suffix=" "
                onEnd={() => console.log("Ended! 👏")}
                onStart={() => console.log("Started! 💨")}
              ></CountUp>
            </div>
          </div>
        </div>
        :""
      }
      {
        is_supper_user?
        <div className={styles.col2}>
          <div className={styles.left}>
            {/* <img src={ic_time_spent_viewing} className={styles.image} color="blue" /> */}
            <BiTimer className={styles.image} color="blue" />
          </div>
          <div className={styles.right}>
            <div className={styles.heading}>Time Spent Viewing</div>
            <div className={styles.content}>
              {" "}
              <div className={styles.number1}>
                <CountUp
                  start={0}
                  end={total_views}
                  duration={2}
                  separator=","
                  decimals={0}
                  decimal="."
                  prefix=""
                  suffix=" "
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
                <span className={styles.sub_txt}>Total Hrs & on Avg</span>
              </div>
              <div className={styles.number2}>
                <CountUp
                  start={0}
                  end={parseFloat(total_views) / parseFloat(total_viewers)}
                  duration={2}
                  separator=" "
                  decimals={0}
                  decimal="."
                  prefix=""
                  suffix=" "
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
                <span className={styles.sub_txt}>Hrs</span>
              </div>
            </div>
          </div>
        </div>
        :""
 }  
 {
        is_supper_user?
        <div className={styles.col3}>
          <div className={styles.left}>
            {/* <img src={ic_gaimified_players} className={styles.image} color="blue" /> */}
            <GiTabletopPlayers className={styles.image} color="blue" />
          </div>

          <div className={styles.right}>
            <div className={styles.heading}>Gaimified Players</div>
            <div className={styles.number}>
              {" "}
              <CountUp
                start={0}
                end={number_of_players}
                duration={2}
                separator=" "
                decimals={0}
                decimal="."
                prefix=""
                suffix=" "
                onEnd={() => console.log("Ended! 👏")}
                onStart={() => console.log("Started! 💨")}
              ></CountUp>
            </div>
          </div>
        </div>:""
}
      </div>
      <div className={styles.row2}>
      {
        is_supper_user?
        <div className={styles.col1}>
          <div className={styles.left}>
            {/* <img src={ic_activated_wallets} className={styles.image} color="blue" /> */}
            <FaWallet className={styles.image} color="blue" />
          </div>
          <div className={styles.right}>
            <div className={styles.heading}>EAT Holders</div>
            <div className={styles.number}>
              {" "}
              <CountUp
                start={0}
                end={activated_wallets_number}
                duration={2}
                separator=","
                decimals={0}
                decimal="."
                prefix=""
                suffix=" "
                onEnd={() => console.log("Ended! 👏")}
                onStart={() => console.log("Started! 💨")}
              ></CountUp>
            </div>
          </div>
        </div>
        :<div className={styles.col1}>
        <div className={styles.left}>
          {/* <img src={ic_gaimified_players} className={styles.image} color="blue" /> */}
          <GiTabletopPlayers className={styles.image} color="blue" />
        </div>

        <div className={styles.right}>
          <div className={styles.heading}>Gaimified Players</div>
          <div className={styles.number}>
            {" "}
            <CountUp
              start={0}
              end={number_of_players}
              duration={2}
              separator=" "
              decimals={0}
              decimal="."
              prefix=""
              suffix=" "
              onEnd={() => console.log("Ended! 👏")}
              onStart={() => console.log("Started! 💨")}
            ></CountUp>
          </div>
        </div>
      </div>

}
        <div className={styles.col2}>
          <div className={styles.left}>
            {/* <img src={ic_played_games} className={styles.image} color="blue" /> */}
            <FaGamepad className={styles.image} color="blue" />
          </div>
          <div className={styles.right}>
            <div className={styles.heading}>Total Games Played</div>
            <div className={styles.content}>
              {" "}
              <div className={styles.number1}>
                <CountUp
                  start={0}
                  end={total_games}
                  duration={2}
                  separator=","
                  decimals={0}
                  decimal="."
                  prefix=""
                  suffix=" "
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
                <span className={styles.sub_txt}>Games</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col3}>
          <div className={styles.left}>
            {/* <img src={ic_viewers} className={styles.image} color="blue" /> */}
            <AiOutlineMonitor className={styles.image} color="blue" />
          </div>
          <div className={styles.right}>
            <div className={styles.heading}>Watch2Earn Viewers</div>
            <div className={styles.number}>
              {" "}
              <CountUp
                start={0}
                end={number_of_viewers}
                duration={2}
                separator=" "
                decimals={0}
                decimal="."
                prefix=""
                suffix=" "
                onEnd={() => console.log("Ended! 👏")}
                onStart={() => console.log("Started! 💨")}
              ></CountUp>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.row4}>
        <div className={styles.col1}>
          <div className={styles.left}>
            {/* <img src={ic_activated_wallets} className={styles.image} color="blue" /> */}
            <FaCoins className={styles.image} color="blue" />
          </div>
          <div className={styles.right}>
            <div className={styles.heading}>Wallet balance</div>
            <div className={styles.number}>
              {" "}
              <CountUp
                start={0}
                end={wallet_balance}
                duration={5}
                separator=" "
                decimals={0}
                decimal="."
                prefix=""
                suffix=" EAT"
                onEnd={() => console.log("Ended! 👏")}
                onStart={() => console.log("Started! 💨")}
              ></CountUp>
            </div>
          </div>
        </div>
        <div className={styles.col2}>
          <div className={styles.left}>
            {/* <img src={ic_played_games} className={styles.image} color="blue" /> */}
            <FaSwimmingPool className={styles.image} color="blue" />
          </div>
          <div className={styles.right}>
            <div className={styles.heading}>Pool Balance</div>
            <div className={styles.content}>
              {" "}
              <div className={styles.number1}>
                <CountUp
                  start={0}
                  end={referral_pool_balance}
                  duration={5}
                  separator=","
                  decimals={0}
                  decimal="."
                  prefix=""
                  suffix=""
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
                <span className={styles.sub_txt}> Referral</span>
              </div>
              <div className={styles.number2}>
                <CountUp
                  start={0}
                  end={staking_pool_balance}
                  duration={5}
                  separator=","
                  decimals={0}
                  decimal="."
                  prefix=""
                  suffix=""
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
                <span className={styles.sub_txt}> Staking</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col3}>
          <div className={styles.left}>
            {/* <img src={ic_viewers} className={styles.image} color="blue" /> */}
            <FaSortAmountUp className={styles.image} color="blue" />
          </div>
          <div className={styles.right}>
            <div className={styles.heading}>Market price</div>
            <div className={styles.number}>
              {" "}
              <span style={{ fontSize: "20px" }}>
                <CountUp
                  start={0}
                  end={market_price}
                  duration={5}
                  separator=" "
                  decimals={3}
                  decimal="."
                  prefix=""
                  suffix=" $"
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
              </span>
              <span
                style={{
                  fontSize: "15px",
                  marginLeft: "5%",
                  color: market_change >= 0 ? "green" : "red",
                }}
              >
                <CountUp
                  start={0}
                  end={market_change}
                  duration={5}
                  separator=" "
                  decimals={2}
                  decimal="."
                  prefix=""
                  suffix=" %"
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
              </span>
              <span style={{ fontSize: "25px", marginLeft: "2%" }}>
                {market_change >= 0 ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.row4}>
      {
        is_supper_user?
        <div className={styles.col1}>
          <div className={styles.left}>
            {/* <img src={ic_activated_wallets} className={styles.image} color="blue" /> */}
            <FaCoins className={styles.image} color="blue" />
          </div>
          <div className={styles.right}>
            <div className={styles.heading}>Activated Wallets</div>
            <div className={styles.number}>
              {" "}
              <CountUp
                start={0}
                end={activated_wallets}
                duration={5}
                separator=" "
                decimals={0}
                decimal="."
                prefix=""
                onEnd={() => console.log("Ended! 👏")}
                onStart={() => console.log("Started! 💨")}
              ></CountUp>
            </div>
          </div>
        </div>:""
}
      </div>

      {/* Tables */}
      <div className={styles.row3}>
        <div className={styles.col1}>
          <div className={styles.tsv_channel_card_container}>
            <div className={styles.heading}>
              <div className={styles.icon}>
                <BsGraphUp className={styles.image} color="blue" />
              </div>
              <div className={styles.text}>TSV (Channel)</div>
            </div>
            <div className={styles.table_container}>
              {currentSelectedKey && currentSelectedDay ? (
                <ChannelTSV
                  apiKey={currentSelectedKey.sdk_api_key}
                  days={currentSelectedDay}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={styles.tsv_viewer_card_container}>
            <div className={styles.heading}>
              <div className={styles.icon}>
                <BsGraphUp className={styles.image} color="blue" />
              </div>
              <div className={styles.text}>TSV (Viewers)</div>
              <div className={styles.channel_selector}>
                <Form.Item>
                  <div style={{ fontWeight: "bold" }}>Select Channel</div>
                  <Dropdown
                    icon={<DownOutlined />}
                    loading={loading}
                    menu={{
                      items:
                        channels.length > 0
                          ? channels.map((it) => ({
                              ...it,
                              onClick: () => handleChannelClick(it),
                            }))
                          : [], // Provide an empty array if there are no items
                    }}
                  >
                    <Button style={{ width: "100%" }}>
                      <Space>
                        {channels.length > 0
                          ? currentSelectedTsvChannel.label ||
                            "No channel found"
                          : "No channels available"}

                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Form.Item>
              </div>
            </div>
            <div className={styles.table_container}>
              {currentSelectedTsvChannel && currentSelectedDay ? (
                <ChannelViewerTSV
                  channel_id={currentSelectedTsvChannel.key}
                  days={currentSelectedDay}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className={styles.col2}>
          {/* Top 10 players */}
          <div className={styles.top_10_players}>
            <div className={styles.heading}>
              <div className={styles.card_heading}>
                <div className={styles.icon}>
                  <GiTrophy className={styles.image} color="blue" />
                </div>
                <div className={styles.text}>Top 10 Players</div>
              </div>
              <div className={styles.drop_down_container}>
                <Form.Item>
                  <div style={{ fontWeight: "bold" }}>Select Channel</div>
                  <Dropdown
                    // type="primary"
                    icon={<DownOutlined />}
                    size="3rem"
                    loading={loading}
                    menu={{
                      items:
                        channels.length > 0
                          ? channels.map((it) => ({
                              ...it,
                              onClick: () => handleChannelClick(it),
                            }))
                          : [], // Provide an empty array if there are no items
                    }}
                  >
                    <Button style={{ width: "100%" }}>
                      <Space>
                        {channels.length > 0
                          ? currentSelectedChannel.label || "No channel found"
                          : "No channels available"}

                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Form.Item>
              </div>
            </div>
            <div className={styles.table_container}>
              {currentSelectedKey ? (
                <WalletsTable apiKey={currentSelectedKey.sdk_api_key} />
              ) : null}
            </div>
          </div>
          {/* Games played  */}
          <div className={styles.games_played}>
            <div className={styles.heading}>
              <div className={styles.card_heading}>
                <div className={styles.icon}>
                  <FaGamepad className={styles.image} color="blue" />
                </div>
                <div className={styles.text}>Games Played</div>
              </div>
            </div>
            <div className={styles.table_container}>
              {currentSelectedKey ? (
                <ChannelGames apiKey={currentSelectedKey.sdk_api_key} />
              ) : null}
            </div>
          </div>
          {/* Leader board */}
          <div className={styles.leader_board}>
            <div className={styles.heading}>
              <div className={styles.card_heading}>
                <div className={styles.icon}>
                  <MdLeaderboard className={styles.image} color="blue" />
                </div>
                <div className={styles.text}>Leaderboard</div>
              </div>
            </div>
            <div className={styles.options_container}>
              <div className={styles.drop_downs_container}>
                <Form.Item>
                  <div style={{ fontWeight: "bold" }}>Select Channel</div>
                  <Dropdown
                    icon={<DownOutlined />}
                    size="3rem"
                    loading={loading}
                    menu={{
                      items:
                        channels.length > 0
                          ? channels.map((it) => ({
                              ...it,
                              onClick: () => handleChannelClick(it),
                            }))
                          : [], // Provide an empty array if there are no items
                    }}
                  >
                    <Button style={{ width: "250px" }}>
                      <Space>
                        {channels.length > 0
                          ? currentSelectedTsvChannel.label ||
                            "No channel found"
                          : "No channels available"}

                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Form.Item>

                <Form.Item style={{ marginLeft: "3px" }}>
                  <div style={{ fontWeight: "bold" }}>Select Days</div>
                  <Dropdown
                    icon={<DownOutlined />}
                    size="3rem"
                    loading={loading}
                    menu={{
                      items:
                        days.length > 0
                          ? days.map((it) => ({
                              ...it,
                              onClick: () => handleDaysClick(it),
                            }))
                          : [], // Provide an empty array if there are no items
                    }}
                  >
                    <Button style={{ width: "200px" }}>
                      <Space>
                        {currentSelectedFilterDay}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Form.Item>
          
              </div>
              <div className={styles.controls}>
                 
              <Form.Item>
                  <div style={{ fontWeight: "bold" }}>Select Team</div>
                  <Dropdown
                    icon={<DownOutlined />}
                    size="3rem"
                    loading={loading}
                    menu={{
                      items:
                        options.length > 0
                          ? options.map((it) => ({
                              ...it,
                              onClick: () => handleCountryClick(it),
                            }))
                          : [], // Provide an empty array if there are no items
                    }}
                  >
                    <Button style={{ width: "200px" }}>
                      <Space>
                        {currentSelectedFilterCountry.value + " Team"}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Form.Item>
                <Form.Item>
                  <Button onClick={handleGenerateLeaderBoard}>
                    <Space>Create Leaderboard</Space>
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button danger onClick={handleGenerateEmbeddedCode}>
                    <Space>Get Embedded Code</Space>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
          {currentSelectedKey.label == "dingo-sdk" ? (
            <div className={styles.app_installs}>
              <div className={styles.heading}>
                <div className={styles.card_heading}>
                  <div className={styles.icon}>
                    <FaGamepad className={styles.image} color="blue" />
                  </div>
                  <div className={styles.text}>App installs</div>
                </div>
              </div>
              <div className={styles.app_list_container}>
                <div className={styles.app_details}>
                  <AiFillAndroid className={styles.image} color="green" />
                  <div className={styles.text}>
                    {app_installed.android_count}
                  </div>
                </div>
                <div className={styles.app_details}>
                  <AiOutlineApple className={styles.image} color="silver" />

                  <div className={styles.text}>{app_installed.apple_count}</div>
                </div>

                <div className={styles.app_details}>
                  <img src="https://img.icons8.com/color/96/fire-tv.png" className={styles.image} color="silver" />

                  <div className={styles.text}>{app_installed.firetv_count}</div>
                </div>

              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
