// api_keysSlice.js
import { createSlice } from "@reduxjs/toolkit";

const channel_walletsSlice = createSlice({
  name: "channel_wallets",
  initialState: {
    list_of_channel_wallets: [],
    refresh_list: false,
  },
  reducers: {
    setListOfChannelWallets: (state, action) => {
      state.list_of_channel_wallets = action.payload;
    },
    setAPIKeyInListOfChannelWallets: (state, action) => {
      state.list_of_channel_wallets.push(action.payload);
    },
    refreshList: (state, action) => {
      state.refresh_list = !state.refresh_list;
    },
    removeAPIKeyFromListOfChannelWallets: (state, action) => {
      const channelWallets = action.payload;
      console.log("removing", channelWallets);
      state.list_of_channel_wallets = state.list_of_channel_wallets.filter((item,index) => {
        console.log("item.channel_wallet",item.channel_wallet==channelWallets[index])
        if (!channelWallets.includes(item.channel_wallet+"")) {
          return item;
        }
      });
      state.refresh_list=!state.refresh_list
    },
  },
});

export const {
  setListOfChannelWallets,
  setAPIKeyInListOfChannelWallets,
  refreshList,
  removeAPIKeyFromListOfChannelWallets,
} = channel_walletsSlice.actions;

export const selectListOfChannelWallets = (state) => state.channel_wallets.list_of_channel_wallets;
export const refreshListOfChannelWallets = (state) => state.channel_wallets.refresh_list;

export default channel_walletsSlice.reducer;
