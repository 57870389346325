// api_keysSlice.js
import { createSlice } from "@reduxjs/toolkit";

const api_keysSlice = createSlice({
  name: "api_keys",
  initialState: {
    list_of_api_keys: [],
    list_of_permit_api_keys: [],
    list_of_permit_api_users: [],
    refresh_list: false,
  },
  reducers: {
    setListOfAPIKeys: (state, action) => {
      state.list_of_api_keys = action.payload;
    },
    setListOfPermitAPIKeys: (state, action) => {
      state.list_of_permit_api_keys = action.payload;
    },
    setListOfPermitAPIUsers: (state, action) => {
      state.list_of_permit_api_users = action.payload;
    },
    setAPIKeyInListOfAPIKeys: (state, action) => {
      state.list_of_api_keys.push(action.payload);
    },
    refreshList: (state, action) => {
      state.refresh_list = !state.refresh_list;
    },
    removeAPIKeyFromListOfAPIKeys: (state, action) => {
      const sdkApiKeys = action.payload;
      console.log("removing", sdkApiKeys);
      state.refresh_list=!state.refresh_list
      state.list_of_api_keys = state.list_of_api_keys.filter((item,index) => {
        console.log("sdkApiKeys[index]",sdkApiKeys[index])
        console.log("item.sdk_api_key",item.sdk_api_key)
        if (item.sdk_api_key!=sdkApiKeys[index]) {
          return item;
        }
      });
      console.log("state.list_of_api_keys",state.list_of_api_keys)
      
    },
    removeAPIKeyFromListOfPermitAPIKeys: (state, action) => {
      const sdkApiKeys = action.payload;
      console.log("removing", sdkApiKeys);
      state.refresh_list=!state.refresh_list
      state.list_of_permit_api_keys = state.list_of_permit_api_keys.filter((item,index) => {
        console.log("sdkApiKeys[index]",sdkApiKeys[index])
        console.log("item.sdk_api_key",item.sdk_api_key)
        if (item.sdk_api_key!=sdkApiKeys[index]) {
          return item;
        }
      });
      console.log("state.list_of_permit_api_keys",state.list_of_permit_api_keys)
      
    },
  },
});

export const {
  setListOfAPIKeys,
  setListOfPermitAPIKeys,
  setListOfPermitAPIUsers,
  setAPIKeyInListOfAPIKeys,
  refreshList,
  removeAPIKeyFromListOfAPIKeys,
  removeAPIKeyFromListOfPermitAPIKeys,
} = api_keysSlice.actions;

export const selectListOfAPIKeys = (state) => state.api_keys.list_of_api_keys;
export const selectListOfPermitAPIKeys = (state) => state.api_keys.list_of_permit_api_keys;
export const refreshListOfAPIKeys = (state) => state.api_keys.refresh_list;
export const refreshListOfPermitAPIKeys = (state) => state.api_keys.refresh_list;
export const refreshListOfPermitAPIUsers = (state) => state.api_keys.refresh_list;
export const selectListOfPermitAPIUsers = (state) => state.api_keys.list_of_permit_api_users;

export default api_keysSlice.reducer;
