
import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "antd";
import "../../styles/api-keys.css";
import { ApiFilled, EditOutlined, UserOutlined } from "@ant-design/icons";



import { useDispatch, useSelector } from "react-redux";
import { AndroidOutlined, UserAddOutlined } from "@ant-design/icons";
import { Tabs } from "antd";

import '../../styles/ticker-editor.css'
import ListOfAPIKeys from "./ListOfAPIKeys";
import SuperUser from "./SuperUsers";
import ListOfUsers from "./ListOfUsers";
import { selectIsSupperUser } from "../../redux/slice/auth/auth-slice";
import { useNavigate } from "react-router-dom";
const tabs=[
    {
        tabTitle:"API Keys",
        tabIcon:ApiFilled,
        tabContent:<ListOfAPIKeys/>
    },
    {
        tabTitle:"Super Users",
        tabIcon:UserAddOutlined,
        tabContent:<SuperUser/>
    },
    {
      tabTitle:"All Users",
      tabIcon:UserAddOutlined,
      tabContent:<ListOfUsers/>
  }
]

const Users = () => {
  const navigate = useNavigate();
  const is_supper_user = useSelector(selectIsSupperUser);
  useEffect(() => {
    console.log("is super user: ",is_supper_user )
    if (!is_supper_user){
       return navigate("/studio");
    }
    
  }, []);

  return (
    <div style={{height:"100%"}}>
      <Row justify="center" align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={4} lg={2}>
          <UserOutlined style={{ fontSize: "3.5rem", color: "#08c" }} />
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} className="api-key-title">
          Users
        </Col>
        <Col xs={24} sm={24} md={12} lg={16} className="api-key-register-btn">
          {/* <Button type="primary" loading={loading}>
            Drop-down for list of existing sdks
          </Button> */}
        </Col>
      </Row>
      <div className="tabs-container">
        <Tabs
          style={{height:"100%",padding:"1rem"}}
          defaultActiveKey="1"
          tabPosition="top"
          items={tabs.map((tab, i) => {
            const id = String(i + 1);
            return {
              label: (
                <span style={{fontSize:"1rem"}}>
                  <tab.tabIcon />
                  {tab.tabTitle}
                </span>
              ),
              key: id,
              children: <div>{tab.tabContent}</div>,
            };
          })}
        />
      </div>
    </div>
  );
};

export default Users;
