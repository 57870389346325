import React, { useState, useEffect } from "react";
import { Button, Col, Row, Tabs } from "antd";
import "../../styles/api-keys.css";
import { ApiFilled, KeyOutlined } from "@ant-design/icons";
import ListOfAPIKeys from "./ListOfAPIKeys";
import ListOfPermittedAPIKeys from "./ListOfPermittedAPIKeys";
import SDKManage from "./SDKManage";

const tabs=[
  {
      tabTitle:"My SDKs",
      tabIcon:ApiFilled,
      tabContent:<ListOfAPIKeys/>
  },
  {
      tabTitle:"Permitted SDK",
      tabIcon:ApiFilled,
      tabContent:<ListOfPermittedAPIKeys/>
  },
  {
    tabTitle:"Manage SDK Permissions",
    tabIcon:ApiFilled,
    tabContent:<SDKManage/>
}
]
const APIKeys = () => {

  return (
    <div style={{height:"100%"}}>
      <Row justify="center" align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={4} lg={2}>
          <KeyOutlined style={{ fontSize: "3.5rem", color: "#08c" }} />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} className="api-key-title">
          API Keys
        </Col>
        <Col xs={24} sm={24} md={12} lg={18} className="api-key-register-btn">
          
        </Col>
      </Row>
      <div className="tabs-container">
        <Tabs
          style={{height:"100%",padding:"1rem"}}
          defaultActiveKey="1"
          tabPosition="top"
          items={tabs.map((tab, i) => {
            const id = String(i + 1);
            return {
              label: (
                <span style={{fontSize:"1rem"}}>
                  <tab.tabIcon />
                  {tab.tabTitle}
                </span>
              ),
              key: id,
              children: <div>{tab.tabContent}</div>,
            };
          })}
        />
      </div>
    </div>
  );
};

export default APIKeys;
