import { Button, Col, Modal, Row, Tabs, message } from "antd";
import "../../styles/second-screen.css";
import ChannelsTable from "./Table/ChannelsTable";
import TopRewardsTable from "./Table/TopRewardsTable";
import { TabletOutlined, WalletOutlined } from "@ant-design/icons";
import { Form, Input, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { apiPOST } from "../../apis/service";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
refreshList, setListOfChannelBoosts
} from "../../redux/slice/channel_boosts/channel_boosts-slice";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import { selectIsSupperUser } from "../../redux/slice/auth/auth-slice";
const SecondScreen = () => {

  const is_supper_user = useSelector(selectIsSupperUser);
  const [channelBoost, setchannelBoost] = useState("");
  const [rewardPoints, setRewardPoints] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [selectedChannel,setSelectedChannel]=useState({})
  const [isEditBoostModalModalOpen, setEditBoostlModalOpen] = useState(false);
  const [isRewardBtnLoading, setIsRewardBtnLoading] =
    useState(false);
  const [isRewardModalOpen, setRewardModalOpen] = useState(false);
  const [playerWallet, setPlayerWallet] = useState(false);
  const handleOpenEditBoostlModal = (record) => {
    console.log("record", record);
    setSelectedChannel(record)
    setchannelBoost(record.boost)
    setEditBoostlModalOpen(true);
  };


  const handleOpenRewardModal = (record) => {
    console.log(record)
    setPlayerWallet(record.wallet)
    setRewardModalOpen(true);
  };
  const tabs=[
    {
        tabTitle:"Boost Points",
        tabIcon:WalletOutlined,
        tabContent:<ChannelsTable handleOpenEditBoostlModal={handleOpenEditBoostlModal}/>
    },
    ...(is_supper_user
      ? [{
          tabTitle: "Reward Points",
          tabIcon: WalletOutlined,
          tabContent: <TopRewardsTable handleOpenRewardModal={handleOpenRewardModal} />
      }]
      : [])
  ]
  const dispatch = useDispatch();
  const handleEditBoostlOk = async() => {
    // Logic to handle creating the channel
    console.log(selectedChannel)
    try {
      let response = await apiPOST("channel/SetBoostMultiple", {
        wallet_address: selectedChannel.address,
        boost_multiple: channelBoost
      });
      message.success("Boost Multiple value set successfully!");
      message.success("Boost Multiple value set successfully!");
      await fetchData()
    } catch (e) {
      console.log(e)
    }

    setEditBoostlModalOpen(false);
  };

  const handleRewardModelOk = async() => {
    // Logic to handle creating the channel
    console.log(playerWallet)
    setIsRewardBtnLoading(true)
    try {
      let current_connected_wallet = await getCurrentWalletAddress();
      let response = await apiPOST("addRewardPointToLeader", {
        from: current_connected_wallet,
        to: playerWallet,
        points: parseFloat(rewardPoints)
      });
      message.success("Reward Points given successfully!");
      message.success("Reward Points given successfully!");
    } catch (e) {
      console.log(e)
    }
    setIsRewardBtnLoading(false)
    setRewardModalOpen(false);
  };

  const handleRewardModalCancel = () => {
    setRewardModalOpen(false);
  };
  
  const fetchData = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    try {
      let response = await apiPOST("channel/GetChannelBoostDetails", {
        parent: current_connected_wallet,
      });
      if (response.rows.length > 0) {
        dispatch(setListOfChannelBoosts(response.rows));
      } else {
        dispatch(setListOfChannelBoosts([]));
      }
    } catch (e) {
      //display msg that error in loading sdks
    }
  };

  const handleGetConnectButtonCode = () => {
    message.success("Code copied to clip board");
    message.success("Code copied to clip board");
  };
  const handleEditBoostlCancel = () => {
    setEditBoostlModalOpen(false);
  };

  const handleChannelBoostChange = (e) => {
    setchannelBoost(e.target.value);
  };

  const handlePlayerNameChange = (e) => {
    setPlayerName(e.target.value);
  };
  const handleRewardPointsChange = (e) => {
    setRewardPoints(e.target.value);
  };

  return (
    <div className="second_screen_container">
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={4} lg={2}>
          <TabletOutlined style={{ fontSize: "3.5rem", color: "#08c" }} />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} className="second-screen-title">
          Game Points
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={14}
          style={{ textAlign: "right" }}
        >
          {/* {is_supper_user
          ?
          <Button
            type="primary"
            loading={isRewardBtnLoading}
            style={{ marginRight: "2%" }}
            onClick={handleOpenRewardModal}
          >
            Give Reward Points
          </Button>
          :null
          } */}
          
        </Col>
      </Row>
      <div className="tabs-container">
        <Tabs
          style={{height:"100%",padding:"1rem"}}
          defaultActiveKey="1"
          tabPosition="top"
          items={tabs.map((tab, i) => {
            const id = String(i + 1);
            return {
              label: (
                <span style={{fontSize:"1rem"}}>
                  <tab.tabIcon />
                  {tab.tabTitle}
                </span>
              ),
              key: id,
              children: <div>{tab.tabContent}</div>,
            };
          })}
        />
      </div>
      <Modal
        title="Edit boost"
        visible={isEditBoostModalModalOpen}
        onOk={handleEditBoostlOk}
        onCancel={handleEditBoostlCancel}
      >
        <div>
          <h3>Channel: {selectedChannel.channel}</h3>
          <p>Wallet Address: {selectedChannel.address}</p>
        </div>
        <Form>
          <Form.Item label="Boost">
            <Input
              placeholder="Enter boost value"
              value={channelBoost}
              onChange={handleChannelBoostChange}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Pay Reward Points"
        visible={isRewardModalOpen}
        onOk={handleRewardModelOk}
        loading={isRewardBtnLoading}
        onCancel={handleRewardModalCancel}
      >
        <Form>
        <div>
          <h3>Wallet Address: {playerWallet}</h3>
        </div>
          <Form.Item label="Reward Points">
            <Input
              placeholder="Enter Reward Points"
              value={rewardPoints}
              onChange={handleRewardPointsChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SecondScreen;