// dashboardSlice.js
import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    referral_pool_balance: 0,
    staking_pool_balance: 0,
    wallet_balance: 0,
    market_price: 0,
    market_change: 0,
    players_number:0,
    wallets_number:0,
    w2e_players_number:0,
    app_installed:0,
    total_games:0,
    total_viewers:0,
    total_views:0,
    channel_viewers:[],
    top_players: [],
    top_leader_players: [],
    channel_games: [],
    tsv_day: [],
    tsv_week: [],
    tsv_month: [],
    activated_wallet: 0,
    refresh_list: false,
    refresh_tsv: false
  },
  reducers: {
    refreshList: (state, action) => {
      state.refresh_list = !state.refresh_list;
    },
    refreshTSV: (state, action) => {
      state.refresh_list = !state.refresh_tsv;
    },
    setReferralPoolBalance: (state, action) => {
      state.referral_pool_balance = action.payload;
    },
    setStakingPoolBalance: (state, action) => {
      state.staking_pool_balance = action.payload;
    },
    setWalletBalance: (state, action) => {
      state.wallet_balance = action.payload;
    },
    setMarketPrice: (state, action) => {
      state.market_price = action.payload;
    },

    setMarketChange: (state, action) => {
      state.market_change = action.payload;
    },
    setPlayersNumber: (state, action) => {
      state.players_number = action.payload;
    },
    setW2EPlayersNumber: (state, action) => {
      state.w2e_players_number = action.payload;
    },
    setWalletsNumber: (state, action) => {
      state.wallets_number = action.payload;
    },
    setTopPlayers: (state, action) => {
      state.top_players = action.payload;
    },
    setTopLeaderPlayers: (state, action) => {
      state.top_leader_players = action.payload;
    },
    setAppInstalled: (state, action) => {
      state.app_installed = action.payload;
    },
    setTotalGames: (state, action) => {
      state.total_games = action.payload;
    },
    setTotalViewers: (state, action) => {
      state.total_viewers = action.payload;
    },
    setTotalViews: (state, action) => {
      state.total_views = action.payload;
    },
    setActivatedWallets: (state, action) => {
      state.activated_wallet = action.payload;
    },
    setChannelGames: (state, action) => {
      state.channel_games = action.payload;
    },
    setTSVDay: (state, action) => {
      state.tsv_day = action.payload;
    },
    setTSVWeek: (state, action) => {
      state.tsv_week = action.payload;
    },
    setTSVMonth: (state, action) => {
      state.tsv_month = action.payload;
    },
    setChannelViewers: (state, action) => {
      state.channel_viewers = action.payload;
    },
  },
});

export const {
  setReferralPoolBalance,
  setStakingPoolBalance,
  setWalletBalance,
  setMarketChange,
  setMarketPrice,
  setPlayersNumber,
  setW2EPlayersNumber,
  setWalletsNumber,
  setTopPlayers,
  setTopLeaderPlayers,
  refreshList,
  setAppInstalled,
  setTotalGames,
  setTotalViewers,
  setTotalViews,
  setActivatedWallets,
  setChannelGames,
  setTSVDay,
  setTSVWeek,
  setTSVMonth,
  setChannelViewers
} = dashboardSlice.actions;

export const selectReferralPoolBalance = (state) =>
  state.dashboard.referral_pool_balance;
export const selectStakingPoolBalance = (state) =>
  state.dashboard.staking_pool_balance;
export const selectWalletBalance = (state) => state.dashboard.wallet_balance;
export const selectMarketPrice = (state) => state.dashboard.market_price;
export const selectMarketChange = (state) => state.dashboard.market_change;
export const selectPlayersNumber = (state) => state.dashboard.players_number;
export const selectW2EPlayersNumber = (state) => state.dashboard.w2e_players_number;
export const selectWalletsNumber = (state) => state.dashboard.wallets_number;
export const selectTopPlayers = (state) => state.dashboard.top_players;
export const selectTopLeaderPlayers = (state) => state.dashboard.top_leader_players;
export const refreshTopPlayers = (state) => state.dashboard.refreshList;
export const refreshChannelGames = (state) => state.dashboard.refreshList;
export const selectAppInstalled = (state) => state.dashboard.app_installed;
export const selectTotalGames = (state) => state.dashboard.total_games;
export const selectTotalViewers = (state) => state.dashboard.total_viewers;
export const selectTotalViews = (state) => state.dashboard.total_views;
export const selectChannelGames = (state) => state.dashboard.channel_games;
export const selectTSVDay = (state) => state.dashboard.tsv_day;
export const selectTSVWeek = (state) => state.dashboard.tsv_week;
export const selectTSVMonth = (state) => state.dashboard.tsv_month;
export const refreshTSV = (state) => state.dashboard.refreshTSV;
export const selectChannelViewers = (state) => state.dashboard.channel_viewers;
export const selectActivatedWallets = (state) => state.dashboard.activated_wallet;


export default dashboardSlice.reducer;
