// api_keysSlice.js
import { createSlice } from "@reduxjs/toolkit";

const channel_boostsSlice = createSlice({
  name: "channel_boosts",
  initialState: {
    list_of_channel_boosts: [],
    list_of_top_players: [],
    refresh_list: false,
  },
  reducers: {
    setListOfChannelBoosts: (state, action) => {
      state.list_of_channel_boosts = action.payload;
    },
    setListOfTopPlayers: (state, action) => {
      state.list_of_top_players = action.payload;
    },
    setAPIKeyInListOfChannelBoosts: (state, action) => {
      state.list_of_channel_boosts.push(action.payload);
    },
    refreshList: (state, action) => {
      state.refresh_list = !state.refresh_list;
    },
    removeAPIKeyFromListOfChannelBoosts: (state, action) => {
      const channelBoosts = action.payload;
      console.log("removing", channelBoosts);
      state.list_of_channel_boosts = state.list_of_channel_boosts.filter((item,index) => {
        console.log("item.channel_wallet",item.channel_wallet==channelBoosts[index])
        if (!channelBoosts.includes(item.channel_wallet+"")) {
          return item;
        }
      });
      state.refresh_list=!state.refresh_list
    },
  },
});

export const {
  setListOfChannelBoosts,
  setAPIKeyInListOfChannelBoosts,
  setListOfTopPlayers,
  refreshList,
  removeAPIKeyFromListOfChannelBoosts,
} = channel_boostsSlice.actions;

export const selectListOfChannelBoosts = (state) => state.channel_boosts.list_of_channel_boosts;
export const selectListOfTopPlayers = (state) => state.channel_boosts.list_of_top_players;
export const refreshListOfChannelBoosts = (state) => state.channel_boosts.refresh_list;

export default channel_boostsSlice.reducer;