import React, { useState } from "react";
import { Button, Col, Divider, Radio, Row, Steps, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const GettingStarted = () => {
  const [current, setCurrent] = useState(0);

  const [tabPosition, setTabPosition] = useState("android");
  const onChange = (value) => {
    console.log("onChange:", value);
    setCurrent(value);
  };
  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    message.success("Code copied to clipboard");
  };

  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };
  return (
    <>
      <h1 style={{ fontSize: "2rem" }}>Getting Started</h1>
      <p>
        Our SDK seamlessly integrates with streaming video apps, unlocking the
        potential for increased viewer engagement, personalized content, and
        additional revenue streams through gamification and Web3 integration.
        With our Second Screen via QR Code feature, viewers can earn points and
        use them to access gamified content and pay-per-view premium content.
        This creates a more engaging and incentivize experience for viewers and
        offers unique and interactive TV entertainment. Our SDK also provides
        improved customer retention and loyalty through personalized content and
        rewards. Additionally, we offer an SDK for Connected TV to further
        enhance the viewing experience.
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "2%",
        }}
      >
        <Radio.Group value={tabPosition} onChange={changeTabPosition}>
          <Radio.Button value="android">Android</Radio.Button>
          <Radio.Button value="ios">iOS</Radio.Button>
        </Radio.Group>
      </div>
      {tabPosition == "android" && (
        <div style={{ height: "100%" }}>
          <Row
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
              height: "100%",
            }}
          >
            <Col sm={4} md={4} lg={4}>
              <Steps
                direction="vertical"
                current={current}
                onChange={onChange}
                items={[
                  {
                    title: "Step 1",
                    description: "Getting sdk",
                  },
                  {
                    title: "Step 2",
                    description: "Getting API key",
                  },
                  {
                    title: "Step 3",
                    description: "Initializing and setting up sdk",
                  },
                  {
                    title: "Step 4",
                    description: "Wallet forwarding",
                  },
                  {
                    title: "Read",
                    description: "Related methods for detailed results",
                  },
                ]}
              />
            </Col>
            <Col sm={18} md={18} lg={18} style={{ height: "100%" }}>
              {" "}
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "100%",
                  paddingBottom: "15%",
                }}
              >
                {current == 0 && (
                  <div style={{ paddingLeft: "2%" }}>
                    <h2>Getting Sdk</h2>
                    <h3 style={{ marginLeft: "2%" }}>
                      For getting sdk you have to simply follow the following
                      steps
                    </h3>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Step 1 : [Adding maven repository ]{" "}
                    </h3>
                    <h4 style={{ marginLeft: "4%" }}>
                      Add it in your root build.gradle at the end of
                      repositories
                    </h4>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
                  allprojects {
                    repositories {
                        ...
                        maven { url 'https://jitpack.io' }
                    }
                  }`)
                          }
                        />
                      </div>
                      <pre>{`
               allprojects {
                    repositories {
                        ...
                        maven { url 'https://jitpack.io' }
                    }
                  }`}</pre>
                    </div>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Step 2 : [Adding dependency ]{" "}
                    </h3>
                    <h4 style={{ marginLeft: "4%" }}>Add the dependency</h4>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
                  dependencies {
                    implementation 'com.github.zeeshan-edgevideo:EdgeUISdk:2.1.5'
                  }`)
                          }
                        />
                      </div>
                      <pre>{`
                dependencies {
                    implementation 'com.github.zeeshan-edgevideo:EdgeUISdk:2.1.5'
                  }`}</pre>
                    </div>
                  </div>
                )}
                {current == 1 && (
                  <div style={{ height: "100%" }}>
                    <div style={{ paddingLeft: "2%" }}>
                      <h2>Getting API key</h2>
                      <h3 style={{ marginLeft: "2%" }}>
                        For getting sdk you have to simply follow the following
                        steps
                      </h3>

                      <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                        Step 1 : [Visit edge studio page]{" "}
                      </h3>
                      <h4 style={{ marginLeft: "4%" }}>
                        Visit https://edge-studio.netlify.app/ and then navigate
                        to studio page and then got to API Key section.
                      </h4>
                      <div style={{ marginLeft: "4%", textAlign: "center" }}>
                        <img
                          src={
                            "https://edge-studio-bucket.s3.amazonaws.com/api_keys_section.png"
                          }
                          alt="image displaying api keys section of studio"
                          width={"60%"}
                          height={"10%"}
                        />
                      </div>

                      <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                        Step 2 : [Generate API Key]{" "}
                      </h3>
                      <h4 style={{ marginLeft: "4%" }}>
                        Click "Generate Key" button and enter sdk title and hit
                        creates button. After successful creation of API key you
                        will see that in list below.
                      </h4>
                      <div style={{ marginLeft: "4%", textAlign: "center" }}>
                        <img
                          src={
                            "https://edge-studio-bucket.s3.amazonaws.com/generating_api_key.png"
                          }
                          alt="image displaying generating api keys"
                          width={"60%"}
                          height={"10%"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {current == 2 && (
                  <div>
                    <div style={{ height: "100%" }}>
                      <div style={{ paddingLeft: "2%" }}>
                        <h2>Initializing sdk</h2>
                        <h3 style={{ marginLeft: "2%" }}>
                          For initializing sdk you have to first get api key and
                          then simply follow the following steps
                        </h3>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 1 : [Initialize the SDK]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          Use the following code snippet for initializing the
                          sdk
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `EdgeSdk edgeSdk = new EdgeSdk(this,"API_KEY","APP_VERSION","DEVICE_INFO");`
                                )
                              }
                            />
                          </div>
                          <pre>{`EdgeSdk edgeSdk = new EdgeSdk(this,"API_KEY","APP_VERSION","DEVICE_INFO");`}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 2 : [Setting default values]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          To make things in actions there are few default values
                          which you must set while setting up sdk
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `
edgeSdk.getLocalStorageManager().storeBooleanValue(true, com.edgesdk.Utils.Constants.IS_TICKER_ALLOWED_TO_HIDE);
edgeSdk.getLocalStorageManager().storeBooleanValue(false,com.edgesdk.Utils.Constants.IS_OPT_OUT_W2E_ENABLED);
edgeSdk.getLocalStorageManager().storeBooleanValue(true,com.edgesdk.Utils.Constants.IS_VIEWER_WALLET_ADDRESS_FORWARDED);
edgeSdk.getLocalStorageManager().storeStringValue("WALLET_ADDRESS",com.edgesdk.Utils.Constants.WALLET_ADDRESS);
                                  `
                                )
                              }
                            />
                          </div>
                          <pre>{`
edgeSdk.getLocalStorageManager().storeBooleanValue(true, com.edgesdk.Utils.Constants.IS_TICKER_ALLOWED_TO_HIDE);
edgeSdk.getLocalStorageManager().storeBooleanValue(false,com.edgesdk.Utils.Constants.IS_OPT_OUT_W2E_ENABLED);
edgeSdk.getLocalStorageManager().storeBooleanValue(true,com.edgesdk.Utils.Constants.IS_VIEWER_WALLET_ADDRESS_FORWARDED);
edgeSdk.getLocalStorageManager().storeStringValue("WALLET_ADDRESS",com.edgesdk.Utils.Constants.WALLET_ADDRESS);
                                  `}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 3 : [Starting sdk operations]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          You need to call the start() method using edgeSdk
                          object this will call all necessary methods on backend
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(` edgeSdk.start();`)
                              }
                            />
                          </div>
                          <pre>{` edgeSdk.start();`}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 4 : [Starting staking]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          Usually you need to call when you start watching a
                          video because at that time you will be also setting
                          base rate to 600-2400
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(` edgeSdk.startStaking();`)
                              }
                            />
                          </div>
                          <pre>{` edgeSdk.startStaking();`}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 5 : [Ticker]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          Importing and initialize SDK ticker
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `
Ticker ticker = new Ticker(this,edgeSdk);
ticker.setBackpressed(false);
ticker.setPlaying(true);

                                  `
                                )
                              }
                            />
                          </div>
                          <pre>{`
Ticker ticker = new Ticker(this,edgeSdk);
ticker.setBackpressed(false);
ticker.setPlaying(true);
                          `}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 6 : [Setting up Ticker over video layout]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          We first find the main layout of the activity using
                          the findViewById method. Then, we add the ticker view
                          to this layout using the addView method. This will
                          display the ticker over the video layout, as specified
                          in Step 5
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `
LinearLayout layout = findViewById(R.id.main_layout);
layout.addView(ticker);
                                  `
                                )
                              }
                            />
                          </div>
                          <pre>{`
LinearLayout layout = findViewById(R.id.main_layout);
layout.addView(ticker);
                          `}</pre>
                        </div>

                        {/* switching the gamification layout or ticker layout */}

                        <h4 style={{ marginLeft: "4%" }}>
                          Since we have two type of ticker now, one is for
                          gaimification and other is for watch2earn. So to
                          switch between them we use following methods
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `
ticker.switchUIForGamification();
ticker.switchUIForW2E();
ticker.switchUIForDefault();

                                  `
                                )
                              }
                            />
                          </div>
                          <pre>{`
ticker.switchUIForGamification();
ticker.switchUIForW2E();
ticker.switchUIForDefault();
                          `}</pre>
                        </div>

                        {/* setting up w2e settings screen */}
                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 7 : [Gaimified Tv Settings]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          Importing and initialize Gaimified Tv settings
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `
W2ESettings   w2ESettings = new W2ESettings(this,edgeSdk);

                                  `
                                )
                              }
                            />
                          </div>
                          <pre>{`
W2ESettings   w2ESettings = new W2ESettings(this,edgeSdk);
                          `}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 8 : [Setting up Gaimified Tv settings screen on
                          layout]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          We first find the main layout of the activity using
                          the findViewById method. Then, we add the ticker view
                          to this layout using the addView method. This will
                          display the Gaimified tv settings screen over the
                          fragment layout.
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `
LinearLayout layout = findViewById(R.id.main_layout);
layout.addView(w2ESettings);
edgeSdk?.setTW2ESettings(w2ESettings)
                                  `
                                )
                              }
                            />
                          </div>
                          <pre>{`
LinearLayout layout = findViewById(R.id.main_layout);
layout.addView(w2ESettings);
edgeSdk?.setTW2ESettings(w2ESettings)
                          `}</pre>
                        </div>

                        {/* method to call in gaimification settings screen */}

                        <h4 style={{ marginLeft: "4%" }}>
                          Since gaimification settings screen have some threads
                          running on, so to handle that call following methods
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `
w2ESettings?.onPause(); [in onPause() method]
w2ESettings?.onResume(); [in onResume() method]
                                  `
                                )
                              }
                            />
                          </div>
                          <pre>{`
w2ESettings?.onPause(); [in onPause() method]
w2ESettings?.onResume(); [in onResume() method]
                          `}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 9 : [Calling few methods in activity cycle
                          method]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          Calling a few methods in the activity lifecycle method
                          onResume(). Specifically, the code ticker.onResume()
                          needs to be called in onResume(). This method is used
                          to resume the ticker animation and ensure that it is
                          running properly when the activity is resumed from a
                          paused or stopped state. It's important to call this
                          method in onResume() to ensure that the ticker
                          animation remains synchronized with the rest of the
                          activity. Similarly, other methods may also need to be
                          called in other activity lifecycle methods to ensure
                          proper functionality of the SDK ticker.
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(`ticker.onResume();`)
                              }
                            />
                          </div>
                          <pre>{`ticker.onResume();`}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 10 : [Updating base rate]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          we are updating the base rate to start earning EAT. To
                          do this, we need to call the updateBaseRateOnServer
                          method from the W2EarnManager object of the edgeSdk.
                          We pass the new base rate value as a parameter to this
                          method. In this example, we are setting the base rate
                          to 600. This will update the base rate on the server,
                          and we will start earning EAT according to the new
                          rate.
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `edgeSdk.getW2EarnManager().updateBaseRateOnServer(600);`
                                )
                              }
                            />
                          </div>
                          <pre>{`edgeSdk.getW2EarnManager().updateBaseRateOnServer(600);`}</pre>
                        </div>

                        {/* updating channel id */}

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 11 : [Updating channel id on socket server]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          Within player activity we need to send the current
                          channel id using following method
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `edgeSdk.getLiveGamificationManager().sendChannelUUIDToSocketServer("CHANNEL_ID");`
                                )
                              }
                            />
                          </div>
                          <pre>{`edgeSdk.getLiveGamificationManager().sendChannelUUIDToSocketServer("CHANNEL_ID");`}</pre>
                        </div>

                        {/* getting list of channels */}

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 12 : [Getting list of channels]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          Since sdk is fetching the list of channels associated
                          to provided API_KEY, you can access that by following
                          methods. You will be getting JSON-Node object, which
                          you can traverse to get details of channels ands find
                          out the channel if current channel by matching its
                          name.
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `val channelData: JsonNode? = currentEdgeSdk.localStorageManager?.getJSONValue(Constants.CHANNEL_DATA )`
                                )
                              }
                            />
                          </div>
                          <pre>{`val channelData: JsonNode? = currentEdgeSdk.localStorageManager?.getJSONValue(Constants.CHANNEL_DATA)`}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {current == 3 && (
                  <div style={{ paddingLeft: "2%" }}>
                    <h2>Wallet forwarding</h2>
                    <h3 style={{ marginLeft: "2%" }}>
                      For switching and forwarding wallet you need following
                      code snippets and need to follow steps
                    </h3>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Forwarding temporary wallet address with actual wallet
                      address
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
                            val walletForwardingManager: WalletForwardingManager =
                            edgeSdk.forwardTemporaryWalletToRealWallet(code)
    `)
                          }
                        />
                      </div>
                      <pre>{`
val walletForwardingManager: WalletForwardingManager =
edgeSdk.forwardTemporaryWalletToRealWallet(code)
    `}</pre>
                    </div>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      switching current wallet address with new wallet address
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
val walletSwitchingManager: WalletSwitchingManager = edgeSdk.switchWallet(code)
    `)
                          }
                        />
                      </div>
                      <pre>{`
val walletSwitchingManager: WalletSwitchingManager = edgeSdk.switchWallet(code)
    `}</pre>
                    </div>
                  </div>
                )}
                {current == 4 && (
                  <div style={{ paddingLeft: "2%" }}>
                    <h2>Related methods to get more detailed results</h2>
                    <h3 style={{ marginLeft: "2%" }}>
                      For getting more detailed results you can use following
                      methods provided by sdk
                    </h3>
                    <h2>Result Fetching Methods</h2>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Staking value fetching methods{" "}
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
    HomeActivity.edgeSdk.getStakingValueFetchingManager().getStkResults().[METHOD]
    getResumingStakingIn()
    getStakingPercentage()
    getEstimatedApyPercentage()
    `)
                          }
                        />
                      </div>
                      <pre>{`
    HomeActivity.edgeSdk.getStakingValueFetchingManager().getStkResults().[METHOD]
    getResumingStakingIn()
    getStakingPercentage()
    getEstimatedApyPercentage()
    `}</pre>
                    </div>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Watch2earn result fetching methods{" "}
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
    HomeActivity.edgeSdk.getW2EarnManager().getResults().[METHOD]
    getEstimateEatsPerHour()
    getBalance()
    getEstimatedEarnedEatsInUSD()
    getEstimateEatsPerHour()
    `)
                          }
                        />
                      </div>
                      <pre>{`
    HomeActivity.edgeSdk.getW2EarnManager().getResults().[METHOD]
    getEstimateEatsPerHour()
    getBalance()
    getEstimatedEarnedEatsInUSD()
    getEstimateEatsPerHour()
    `}</pre>
                    </div>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Market price fetching methods{" "}
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
    HomeActivity.edgeSdk.getMarketPriceManager().[METHOD]
    getChange()
    getPrice()
    `)
                          }
                        />
                      </div>
                      <pre>{`
    HomeActivity.edgeSdk.getMarketPriceManager().[METHOD]
    getChange()
    getPrice()
    `}</pre>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}

      {tabPosition == "ios" && (
        <div style={{ height: "100%" }}>
          <Row
            style={{
              overflowY: "hidden",
              overflowX: "hidden",
              height: "100%",
            }}
          >
            <Col sm={4} md={4} lg={4}>
              <Steps
                direction="vertical"
                current={current}
                onChange={onChange}
                items={[
                  {
                    title: "Step 1",
                    description: "Getting sdk",
                  },
                  {
                    title: "Step 2",
                    description: "Getting API key",
                  },
                  {
                    title: "Step 3",
                    description: "Initializing and setting up sdk",
                  },
                  {
                    title: "Step 4",
                    description: "Wallet forwarding",
                  },
                  {
                    title: "Read",
                    description: "Related methods for detailed results",
                  },
                ]}
              />
            </Col>
            <Col sm={18} md={18} lg={18} style={{ height: "100%" }}>
              {" "}
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "100%",
                  paddingBottom: "15%",
                }}
              >
                {current == 0 && (
                  <div style={{ paddingLeft: "2%" }}>
                    <h2>Getting Sdk</h2>
                    <h3 style={{ marginLeft: "2%" }}>
                      For getting sdk you have to simply follow the following
                      steps
                    </h3>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Step 1 : [Download the framework]{" "}
                    </h3>
                    <h4 style={{ marginLeft: "4%" }}>
                      Go to the GitHub repository and
                      https://github.com/EdgeCTO/watch2earn-applesdk.framework
                      file
                    </h4>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Step 2 : [Add the framework to your Xcode project]{" "}
                    </h3>
                    <div style={{ marginLeft: "4%", marginTop: "2%" }}>
                      <ul>
                        <li>
                          Drag and drop the watch2earn-applesdk.framework file
                          into your Xcode project.
                        </li>
                        <li>
                          In the "General" tab of your project's settings,
                          scroll down to "Frameworks, Libraries, and Embedded
                          Content".
                        </li>
                        <li>
                          Click the "+" button and select the
                          watch2earn-applesdk.framework file.
                        </li>
                      </ul>
                    </div>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Step 3 : [Link the framework with your project]{" "}
                    </h3>
                    <h4 style={{ marginLeft: "4%" }}>
                      In the "Build Phases" tab of your project's settings, add
                      the .framework file to the "Link Binary with Libraries"
                      section.
                    </h4>
                  </div>
                )}

                {current == 1 && (
                  <div style={{ height: "100%" }}>
                    <div style={{ paddingLeft: "2%" }}>
                      <h2>Getting API key</h2>
                      <h3 style={{ marginLeft: "2%" }}>
                        For getting sdk you have to simply follow the following
                        steps
                      </h3>

                      <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                        Step 1 : [Visit edge studio page]{" "}
                      </h3>
                      <h4 style={{ marginLeft: "4%" }}>
                        Visit https://edge-studio.netlify.app/ and then navigate
                        to studio page and then got to API Key section.
                      </h4>
                      <div style={{ marginLeft: "4%", textAlign: "center" }}>
                        <img
                          src={
                            "https://edge-studio-bucket.s3.amazonaws.com/api_keys_section.png"
                          }
                          alt="image displaying api keys section of studio"
                          width={"60%"}
                          height={"10%"}
                        />
                      </div>

                      <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                        Step 2 : [Generate API Key]{" "}
                      </h3>
                      <h4 style={{ marginLeft: "4%" }}>
                        Click "Generate Key" button and enter sdk title and hit
                        creates button. After successful creation of API key you
                        will see that in list below.
                      </h4>
                      <div style={{ marginLeft: "4%", textAlign: "center" }}>
                        <img
                          src={
                            "https://edge-studio-bucket.s3.amazonaws.com/generating_api_key.png"
                          }
                          alt="image displaying generating api keys"
                          width={"60%"}
                          height={"10%"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {current == 2 && (
                  <div>
                    <div style={{ height: "100%" }}>
                      <div style={{ paddingLeft: "2%" }}>
                        <h2>Initializing sdk</h2>
                        <h3 style={{ marginLeft: "2%" }}>
                          For initializing sdk you have to first get api key and
                          then simply follow the following steps
                        </h3>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 1 : [Importing the Framework]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          To use the watch2earn_applesdk framework in your
                          project, you need to import it at the top of your main
                          View Controller Swift file using the following code:
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(` Import watch2earn_applesdk`)
                              }
                            />
                          </div>
                          <pre>{` Import watch2earn_applesdk`}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 2 : [Initializing the Framework]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          To initialize the Framework, call the basic functions
                          of the SDK into the app main class and initialize it
                          with your app logo link and wallets JSON file link
                          into the W2EManager class. It will establish all the
                          connections with watch2earn servers. Use the following
                          code to initialize the W2EManager:
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(
                                  `
let logo = URL(string: "Link to your logo")!
let wallets = URL(string: "Link to the your get wallets api")!
self.w2eManager = W2EManager(logoUrl:logo, jsonUrl: wallets );
                                  `
                                )
                              }
                            />
                          </div>
                          <pre>{`
let logo = URL(string: "Link to your logo")!
let wallets = URL(string: "Link to the your get wallets api")!
self.w2eManager = W2EManager(logoUrl:logo, jsonUrl: wallets );
                                  `}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 3 : [Calling playerViewControls]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          To show tickers and update its stats, call the
                          playerViewControl method into your
                          AVPlayerViewController and pass it as a parameter to
                          this method from the w2eSdk object in the W2EManager.
                          Use the following code to call the playerViewControl
                          method
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(`
self.avPlayerController.player = self.avPlayer
W2EManager.w2eSdk.playerViewControl(avPlayerController: self.avPlayerController)
self.addSubview(self.avPlayerController.view)
                                `)
                              }
                            />
                          </div>
                          <pre>{`
self.avPlayerController.player = self.avPlayer
W2EManager.w2eSdk.playerViewControl(avPlayerController: self.avPlayerController)
self.addSubview(self.avPlayerController.view)
                          `}</pre>
                        </div>

                        <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                          Step 4 : [Calling W2E Setting Screen View]{" "}
                        </h3>
                        <h4 style={{ marginLeft: "4%" }}>
                          To get the W2E settings screen UIViewController, use
                          the getSettingScreen() function. Use the following
                          code to call the getSettingScreen() function:
                        </h4>
                        <div
                          style={{
                            background: "#f5f5f5",
                            padding: "10px",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() =>
                                copyToClipboard(`
let viewController = W2EManager.w2eSdk.getSettingScreen()
view.insertSubview(viewController.view, at: 0)
self.addChild(viewController) 
                                `)
                              }
                            />
                          </div>
                          <pre>{`
let viewController = W2EManager.w2eSdk.getSettingScreen()
view.insertSubview(viewController.view, at: 0)
self.addChild(viewController) 
                          `}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {current == 3 && (
                  <div style={{ paddingLeft: "2%" }}>
                    <h2>Wallet forwarding</h2>
                    <h3 style={{ marginLeft: "2%" }}>
                      For switching and forwarding wallet you need following
                      code snippets and need to follow steps
                    </h3>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Forwarding temporary wallet address with actual wallet
                      address
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
                            val walletForwardingManager: WalletForwardingManager =
                            edgeSdk.forwardTemporaryWalletToRealWallet(code)
    `)
                          }
                        />
                      </div>
                      <pre>{`
val walletForwardingManager: WalletForwardingManager =
edgeSdk.forwardTemporaryWalletToRealWallet(code)
    `}</pre>
                    </div>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      switching current wallet address with new wallet address
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
val walletSwitchingManager: WalletSwitchingManager = edgeSdk.switchWallet(code)
    `)
                          }
                        />
                      </div>
                      <pre>{`
val walletSwitchingManager: WalletSwitchingManager = edgeSdk.switchWallet(code)
    `}</pre>
                    </div>
                  </div>
                )}
                {current == 4 && (
                  <div style={{ paddingLeft: "2%" }}>
                    <h2>Related methods to get more detailed results</h2>
                    <h3 style={{ marginLeft: "2%" }}>
                      For getting more detailed results you can use following
                      methods provided by sdk
                    </h3>
                    <h2>Result Fetching Methods</h2>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Staking value fetching methods{" "}
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
    HomeActivity.edgeSdk.getStakingValueFetchingManager().getStkResults().[METHOD]
    getResumingStakingIn()
    getStakingPercentage()
    getEstimatedApyPercentage()
    `)
                          }
                        />
                      </div>
                      <pre>{`
    HomeActivity.edgeSdk.getStakingValueFetchingManager().getStkResults().[METHOD]
    getResumingStakingIn()
    getStakingPercentage()
    getEstimatedApyPercentage()
    `}</pre>
                    </div>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Watch2earn result fetching methods{" "}
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
    HomeActivity.edgeSdk.getW2EarnManager().getResults().[METHOD]
    getEstimateEatsPerHour()
    getBalance()
    getEstimatedEarnedEatsInUSD()
    getEstimateEatsPerHour()
    `)
                          }
                        />
                      </div>
                      <pre>{`
    HomeActivity.edgeSdk.getW2EarnManager().getResults().[METHOD]
    getEstimateEatsPerHour()
    getBalance()
    getEstimatedEarnedEatsInUSD()
    getEstimateEatsPerHour()
    `}</pre>
                    </div>

                    <h3 style={{ marginLeft: "3%", marginTop: "2%" }}>
                      Market price fetching methods{" "}
                    </h3>
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "10px",
                        marginLeft: "4%",
                        marginRight: "4%",
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() =>
                            copyToClipboard(`
    HomeActivity.edgeSdk.getMarketPriceManager().[METHOD]
    getChange()
    getPrice()
    `)
                          }
                        />
                      </div>
                      <pre>{`
    HomeActivity.edgeSdk.getMarketPriceManager().[METHOD]
    getChange()
    getPrice()
    `}</pre>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default GettingStarted;
