import { DownloadOutlined, EditOutlined, EllipsisOutlined, ReadOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
const { Meta } = Card;
const Plateform = ({record,setCurrentOpenedSection,setCurrentSelectedRecord,...props}) => (
  <Card
    style={{
      width: 250,
      cursor:"pointer"
    }}
    hoverable={true}
    onClick={()=>{
      setCurrentSelectedRecord(record)
      setCurrentOpenedSection("details")}}
    
    actions={[
      <ReadOutlined key="setting" />,
      <DownloadOutlined key="edit" />,
   
    ]}
  >
    <Meta
      avatar={ <div style={{textAlign:"center",fontSize:"3rem"}}>{record.icon}</div>}
      title={record.title}
      // description={record.desc}
    />
  </Card>
);
export default Plateform;