import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Dropdown, Form, Input, Space, Row, Col, message } from "antd";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress, getWalletAddressChecksum } from "../../utils/Metamask";
import { apiDELETE, apiPOST } from "../../apis/service";
import {
  refreshList,
  refreshListOfPermitAPIUsers,
  selectListOfPermitAPIUsers,
  setListOfPermitAPIUsers,
  refreshListOfAPIKeys,
  selectListOfAPIKeys,
  setListOfAPIKeys,
} from "../../redux/slice/api_keys/api_keys-slice";


// const data = [];
// for (let i = 0; i < 2; i++) {
//   data.push({
//     key: i,
//     name: `Edward King ${i}`,
//     age: 32,
//     address: `London, Park Lane no. ${i}`,
//   });
// }

const SDKManage = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [items, setItems] = useState([]);
  const list_of_api_keys = useSelector(selectListOfAPIKeys);
  const [currentSelectedKey, setCurrentSelectedKey] = useState(
    list_of_api_keys[0]
  );
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const [data, setData] = useState([]);

  const [isPermissionModalOpen, setPermissionModalOpen] = useState(false);
  const [isPermissionBtnLoading, setIsPermissionBtnLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  const dispatch = useDispatch();

  const list_of_api_users = useSelector(selectListOfPermitAPIUsers);
  const list_refresher = useSelector(refreshListOfPermitAPIUsers);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiPOST("getSdksByOwnerWalletAddress", {
          walletAddress: current_connected_wallet,
        });
        if (response.data.length > 0) {
          setItems([]);
          dispatch(setListOfAPIKeys(response.data));
        } else {
          setItems([]);
          dispatch(setListOfAPIKeys([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [list_refresher]);

  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = [];
      list_of_api_keys.forEach((element) => {
        let tmp = {
          label: element.sdk_title,
          key: element.sdk_api_key,
          logo_url: element.logo_url,
        };
        temp.push(tmp);
      });
      setItems(temp);
      setCurrentSelectedKey(temp[0]);
      refreshData()
    }
  }, [list_of_api_keys]);

  const fetchDataByKey = async (item) => {
    setLoading(true);
    try {
      let response = await apiPOST("getPermittedUserBySDK", {
        apiKey: item.key,
      });
      if (response.data.length > 0) {
        //setItems([]);
        dispatch(setListOfPermitAPIUsers(response.data));
      } else {
        dispatch(setListOfPermitAPIUsers([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  useEffect(() => {
    if (list_of_api_users.length > 0) {
      let temp = [];
      list_of_api_users.forEach((element) => {
        console.log(element);
        let api_key = {
          address: element.owner_wallet,
          key: element.owner_wallet
        };
        temp.push(api_key);
      });
      setData(temp);
    }else{
      setData([])
    }
  }, [list_of_api_users]);

  const refreshData = async () => {
    setLoading(true);
    try {
      let response = await apiPOST("getPermittedUserBySDK", {
        apiKey: currentSelectedKey.key,
      });
      if (response.data.length > 0) {
        setData([]);
        dispatch(setListOfPermitAPIUsers(response.data));
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const handleKeyClick = async (item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedKey(item);
    await fetchDataByKey(item);
  };

  const handleDeleteSDK = async(wallet) => {
    console.log("Deleted", wallet, currentSelectedKey.key)
    Modal.confirm({ 
      title: "Are you sure you want to delete this", 
      onOk: async() => { 
        try {
          let response = await apiPOST("DeleteAllowedUserOfAPIKeys", {
            apiKey: currentSelectedKey.key,
            walletAddress: wallet
          });
          
          await refreshData()
        } catch (e) {
          console.log(e)
        }
      }, 
      });
  };

  const handleOpenPermissionModal = () => {
    setPermissionModalOpen(true);
  };

  const handleCreateChannelOk = async () => {
    console.log("new permission added");
    if (walletAddress) {
      // Channel name and image are provided
      try {
        setIsPermissionBtnLoading(true);
        setPermissionModalOpen(false);
        let wallet = await getWalletAddressChecksum(walletAddress)
        apiPOST("AddSDKAllowedUser", {"walletAddress": wallet,"apiKey": currentSelectedKey.key })
          .then(async (response) => {
            console.log(response);
            message.info(response.message)
            message.info(response.message)
            await refreshData();
            setIsPermissionBtnLoading(false);
            
          })
          .catch((error) => {
            console.log(error);
            setIsPermissionBtnLoading(false);
          });
      } catch (error) {
        // navigate("/login");
        // message.error(error);
      }
  
      // Add your channel creation logic here
    } else {
      // message.error("Please provide user name and wallet address");
    }
  };
  
  const handleCreateChannelCancel = async () => {
    setPermissionModalOpen(false)
  };
  const handleWalletChange = (e) => {
    setWalletAddress(e.target.value);
  };

const columns = [
  {
    title: "User Address",
    dataIndex: "address",
  },
  {
    title: "Action",
    dataIndex: "address",
    key: "address",
    render: (text) => (
      <div>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteSDK(text)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];
  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          textAlign: "right",
        }}
      >
        <div>
        <Row justify="space-between" align="right">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          style={{ textAlign: "left" }}
        >
          <Button
            type="primary"
            loading={isPermissionBtnLoading}
            style={{ marginRight: "2%" }}
            onClick={handleOpenPermissionModal}
          >
            Allow User
          </Button>
          </Col>
          <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          className="sdks-dropdown"
          style={{ textAlign: "right" }}
        >
        
        <div className="sdks-dropdown">
        <Form.Item label={<span style={{fontWeight: 'bold'}}>Select SDK</span>}>
           <Dropdown
              type="primary"
              size="3rem"
              loading={false}
              menu={{
                items:
                  items.length > 0
                    ? items.map((it) => ({
                        ...it,
                        onClick: () => handleKeyClick(it),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                  <Space>
                    {items.length > 0
                      ? currentSelectedKey.label || "No sdk found"
                      : "No SDKs available"}
                    <DownOutlined />
                  </Space>
                </Button>
            </Dropdown>
            </Form.Item>
          </div>
        </Col>
        </Row>
       
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={pagination} // Add pagination prop and set pageSize
        onChange={handleTableChange} // Handle table change events
        scroll={{ x: "max-content" }} // Enable horizontal scrolling
        loading={loading}
        rowKey="owner_wallet"
      />

<Modal
        title="Allow New User"
        visible={isPermissionModalOpen}
        onOk={handleCreateChannelOk}
        onCancel={handleCreateChannelCancel}
      >
        <Form>
          <Form.Item label="Select SDK">
          <div className="sdks-dropdown">
           <Dropdown
              type="primary"
              size="3rem"
              loading={false}
              menu={{
                items:
                  items.length > 0
                    ? items.map((it) => ({
                        ...it,
                        onClick: () => handleKeyClick(it),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                  <Space>
                    {items.length > 0
                      ? currentSelectedKey.label || "No sdk found"
                      : "No SDKs available"}
                    <DownOutlined />
                  </Space>
                </Button>
            </Dropdown>
          </div>
          </Form.Item>
          <Form.Item label="Wallet Address">
            <Input
              placeholder="Enter Wallet Address"
              value={walletAddress}
              onChange={handleWalletChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SDKManage;
