import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Modal, Input, Space } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import { apiDELETE, apiPOST } from "../../apis/service";
import {
  refreshList,
  refreshListOfAPIKeys,
  removeAPIKeyFromListOfAPIKeys,
  selectListOfAPIKeys,
  setListOfAPIKeys,
} from "../../redux/slice/api_keys/api_keys-slice";

// const data = [];
// for (let i = 0; i < 2; i++) {
//   data.push({
//     key: i,
//     name: `Edward King ${i}`,
//     age: 32,
//     address: `London, Park Lane no. ${i}`,
//   });
// }

const ListOfAPIKeys = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const dispatch = useDispatch();

  const list_of_api_keys = useSelector(selectListOfAPIKeys);
  const list_refresher = useSelector(refreshListOfAPIKeys);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys)[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const hasSelected = selectedRowKeys.length > 0;

  const refreshData = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    setLoading(true);
    try {
      // get list of all api keys 
      let response = await apiPOST("getSdksBySuperUserWalletAddress", {walletAddress:current_connected_wallet});
      if (response.data.length > 0) {
        setData([]);
        console.log("response.data-super",response.data)
        dispatch(setListOfAPIKeys(response.data));
      } else {
      }
  
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };
  
  const handleDeleteSDK = async(apiKey) => {
    console.log("Deleted", apiKey)
    Modal.confirm({ 
      title: "Are you sure you want to delete this", 
      onOk: async() => { 
        try {
          let response = await apiPOST("DeleteStudioSDKBySuperUser", {
            apiKey: apiKey
          });
          
          await refreshData()
        } catch (e) {
          console.log(e)
        }
      }, 
      });
  };


const columns = [
  {
    title: "SDK Title",
    dataIndex: "sdk_title",
    ...getColumnSearchProps('sdk_title'),
  },
  {
    title: "Owner",
    dataIndex: "owner_wallet_address",
    ...getColumnSearchProps('owner_wallet_address'),
  },
  {
    title: "API Key",
    dataIndex: "sdk_api_key",
    ...getColumnSearchProps('sdk_api_key'),
  },
  {
    title: "Created",
    dataIndex: "created_at",
  },
  {
    title: "Last Used",
    dataIndex: "last_used_at",
  },
  {
    title: "Action",
    dataIndex: "sdk_api_key",
    key: "address",
    render: (text) => (
      <div>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteSDK(text)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];

  

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        // get list of all api keys 
        let response = await apiPOST("getSdksBySuperUserWalletAddress", {walletAddress:current_connected_wallet});
        if (response.data.length > 0) {
          setData([]);
          console.log("response.data-super",response.data)
          dispatch(setListOfAPIKeys(response.data));
        } else {
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [list_refresher]);

  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = [];
      list_of_api_keys.forEach((element) => {
        console.log(element);
        let api_key = {
          key: element.sdk_api_key,
          sdk_api_key: element.sdk_api_key,
          sdk_title: element.sdk_title,
          created_at: element.created_at,
          owner_wallet_address:element.owner_wallet_address,
          last_used_at:
            element.last_used_at == null ? "Never used" : element.last_used_at,
        };
        temp.push(api_key);
      });
      setData(temp);
    }else{
      setData([])
    }
  }, [list_of_api_keys,list_refresher]);
  

  // const handleDeleteAPIKeys = async () => {
  //   let current_connected_wallet = await getCurrentWalletAddress();
  //   setDeleteBtnLoading(true);
  //   apiDELETE("deleteAuthKeysByOwnerWalletAddress", {
  //     walletAddress: current_connected_wallet,
  //     authKeys: selectedRowKeys,
  //   })
  //     .then((response) => {
  //       console.log(response);
  //       dispatch(removeAPIKeyFromListOfAPIKeys(selectedRowKeys));
  //       setSelectedRowKeys([]);
  //       setDeleteBtnLoading(false);
  //       dispatch(refreshList());
  //     })
  //     .catch((error) => {
  //       setDeleteBtnLoading(false);
  //       //TODO: Display error message
  //     });
  // };
  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          textAlign: "right",
        }}
      >
        {/* <div>
          {hasSelected ? (
            <Button
              type="primary"
              loading={deleteBtnLoading}
              onClick={handleDeleteAPIKeys}
              danger
            >
              Delete Selected [ {selectedRowKeys.length} ]
            </Button>
          ) : (
            ""
          )}
        </div> */}
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={pagination} // Add pagination prop and set pageSize
        onChange={handleTableChange} // Handle table change events
        scroll={{ x: "max-content" }} // Enable horizontal scrolling
        loading={loading}
        rowKey="sdk_api_key"
      />
    </div>
  );
};

export default ListOfAPIKeys;
