import { BackwardOutlined } from "@ant-design/icons"

const Details=({setCurrentOpenedSection,currentSelectedRecord,...props})=>{
    return <div>
        <div className="details-back-heading" onClick={()=>{
            setCurrentOpenedSection("list")
        }}><BackwardOutlined/><span style={{marginLeft:"0.5rem"}}>Back</span></div>
        <div className="details-heading">{currentSelectedRecord.title}</div>
        <div className="details-desc">{currentSelectedRecord.desc}</div>
        <div className="details-supported-platforms"><b>Supported platforms : </b> {currentSelectedRecord.supported_platforms}</div>
        <div className="details-supported-os"><b>Supported os versions</b> {currentSelectedRecord.supported_os_versions}</div>
        
    </div>
}

export default Details