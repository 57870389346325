import { Col, Row } from "antd";
import "../../styles/sdks.css";
import {
  ApiOutlined,
  DownloadOutlined,
  GithubFilled,
  ReadOutlined,
} from "@ant-design/icons";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { AiFillApple ,AiFillAndroid} from "react-icons/ai";
import { Avatar, Card } from "antd";
const { Meta } = Card;
const SDKs = () => {
  return (
    <div className="sdks_heading">
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={4} lg={2}>
          <ApiOutlined style={{ fontSize: "3.5rem", color: "#08c" }} />
        </Col>
        <Col xs={24} sm={24} md={8} lg={10} className="sdks-title">
          SDKs
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          style={{ textAlign: "right" }}
        ></Col>
      </Row>
      <div className="page_desc">
        we offer SDK versions for Android and iOS. Our Android SDK provides
        seamless integration with Android devices, leveraging native features
        for enhanced user experiences. The iOS SDK is tailored for iOS app
        development, offering a range of features and APIs for high-quality
        applications. Both SDKs come with comprehensive documentation, sample
        codes, and dedicated support. Streamline your development workflow and
        unlock possibilities for your mobile apps with our versatile SDKs.
      </div>
      <Row
        justify="center"
        align="middle"
        gutter={[16, 16]}
        style={{ padding: "5%" }}
      >
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Card
            hoverable={true}
            style={{
              width: 300,
            }}
           
            actions={[
              // <ReadOutlined key="documentation" />,
              // <DownloadOutlined key="download" />,
              <GithubFilled key="ellipsis"  onClick={()=>{window.open("https://github.com/zeeshan-edgevideo/EdgeUISdk","_blank")}}/>,
            ]}
          >
            <Meta
              avatar={
                <AiFillAndroid size={"3rem"}/>
              }
              title="Android"
              //   description="This is the description"
            />
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Card
              hoverable={true}
            style={{
              width: 300,
            }}
            
            actions={[
              // <ReadOutlined key="documentation" />,
              // <DownloadOutlined key="download" />,
              <GithubFilled key="ellipsis" onClick={()=>{window.open("https://github.com/EdgeCTO/watch2earn-applesdk.framework","_blank")}}/>,
            ]}
          >
            <Meta
              avatar={
                <AiFillApple size={"3rem"}/>
              }
              title="iOS"
              //   description="This is the description"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SDKs;
