import React, { useState, useEffect } from "react";
import { Button, Table, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import { apiDELETE, apiPOST } from "../../apis/service";
import {
  refreshList,
  refreshListOfPermitAPIKeys,
  removeAPIKeyFromListOfPermotAPIKeys,
  selectListOfPermitAPIKeys,
  setListOfPermitAPIKeys,
} from "../../redux/slice/api_keys/api_keys-slice";


// const data = [];
// for (let i = 0; i < 2; i++) {
//   data.push({
//     key: i,
//     name: `Edward King ${i}`,
//     age: 32,
//     address: `London, Park Lane no. ${i}`,
//   });
// }

const ListOfPermittedAPIKeys = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const list_of_api_keys = useSelector(selectListOfPermitAPIKeys);
  const list_refresher = useSelector(refreshListOfPermitAPIKeys);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiPOST("getPermittedSdksByWalletAddress", {
          walletAddress: current_connected_wallet,
        });
        if (response.data.length > 0) {
          setData([]);
          dispatch(setListOfPermitAPIKeys(response.data));
        } else {
          setData([]);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = [];
      list_of_api_keys.forEach((element) => {
        console.log(element);
        let api_key = {
          key: element.sdk_api_key,
          sdk_api_key: element.sdk_api_key,
          sdk_title: element.sdk_title,
          created_at: element.created_at,
          last_used_at:
            element.last_used_at == null ? "Never used" : element.last_used_at,
        };
        temp.push(api_key);
      });
      setData(temp);
    }else{
      setData([])
    }
  }, [list_of_api_keys,list_refresher]);

  // const handleDeleteAPIKeys = async () => {
  //   let current_connected_wallet = await getCurrentWalletAddress();
  //   setDeleteBtnLoading(true);

  //   apiDELETE("deletePermitKeysByOwnerWalletAddress", {
  //     walletAddress: current_connected_wallet,
  //     authKeys: selectedRowKeys,
  //   })
  //     .then((response) => {
  //       console.log(response);
  //       dispatch(removeAPIKeyFromListOfPermotAPIKeys(selectedRowKeys));
  //       setSelectedRowKeys([]);
  //       setDeleteBtnLoading(false);
  //       dispatch(refreshList());
  //     })
  //     .catch((error) => {
  //       setDeleteBtnLoading(false);
  //       //TODO: Display error message
  //     });
  // };

  const refreshData = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    setLoading(true);
    try {
      let response = await apiPOST("getPermittedSdksByWalletAddress", {
        walletAddress: current_connected_wallet,
      });
      if (response.data.length > 0) {
        setData([]);
        dispatch(setListOfPermitAPIKeys(response.data));
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };


  // const handleDeleteSDK = async(apiKey) => {
  //   console.log("Deleted", apiKey)
  //   Modal.confirm({ 
  //     title: "Are you sure you want to delete this", 
  //     onOk: async() => { 
  //       try {
  //         let response = await apiPOST("channel/DeleteStudioSDK", {
  //           apiKey: apiKey
  //         });
          
  //         await refreshData()
  //       } catch (e) {
  //         console.log(e)
  //       }
  //     }, 
  //     });
  // };


const columns = [
  {
    title: "SDK Title",
    dataIndex: "sdk_title",
  },
  {
    title: "API Key",
    dataIndex: "sdk_api_key",
  },
  {
    title: "Created",
    dataIndex: "created_at",
  },
  {
    title: "Last Used",
    dataIndex: "last_used_at",
  },
  // {
  //   title: "Action",
  //   dataIndex: "sdk_api_key",
  //   key: "address",
  //   render: (text) => (
  //     <div>
  //       <Button
  //         type="link"
  //         icon={<DeleteOutlined />}
  //         onClick={() => handleDeleteSDK(text)}
  //       >
  //         Delete
  //       </Button>
  //     </div>
  //   ),
  // },
];
  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          textAlign: "right",
        }}
      >
        <div>

        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={pagination} // Add pagination prop and set pageSize
        onChange={handleTableChange} // Handle table change events
        scroll={{ x: "max-content" }} // Enable horizontal scrolling
        loading={loading}
        rowKey="sdk_api_key"
      />
    </div>
  );
};

export default ListOfPermittedAPIKeys;
