import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Modal, Form, Input, Space } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import { apiDELETE, apiGET, apiPOST, apiPOSTFormData } from "../../apis/service";
import {
  setSupperUserList,
  selectSupperUserList,
  refreshList,
  refreshSupperUserList,
  selectAllUserList,
  setAllUserList
} from "../../redux/slice/auth/auth-slice";

const ListOfUsers = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const [isCreateChannelModalOpen, setCreateChannelModalOpen] = useState(false);
  const [isCreateChannelBtnLoading, setIsCreateChannelBtnLoading] =
    useState(false);
  const [data, setData] = useState([]);
  const [userName, setUserName] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const dispatch = useDispatch();

  const list_of_all_user = useSelector(selectAllUserList);
  const list_refresher = useSelector(refreshSupperUserList);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys)[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handleWalletChange = (e) => {
    setWalletAddress(e.target.value);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const refreshData = async () => {
    setLoading(true);
    try {
      // get list of all api keys 
      let response = await apiGET("getAllUserList");
      if (response.length > 0) {
        setData([]);
        console.log("response.data-super",response)
        dispatch(setAllUserList(response));
      } else {
      }
  
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };
  
  const handleDeleteSDK = async(walletAddress) => {
    console.log("Deleted", walletAddress)
    Modal.confirm({ 
      title: "Are you sure you want to delete this", 
      onOk: async() => { 
        try {
          let response = await apiPOST("DeleteStudioUser", {
            walletAddress: walletAddress
          });
          
          await refreshData()
        } catch (e) {
          console.log(e)
        }
      }, 
      });
  };
  const handleOpenCreateChannelModal = () => {
    setCreateChannelModalOpen(true);
  };

const columns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: (text, record, index) => (
      <div>
        {index+1}
      </div>
    ),
  },
  {
    title: "Wallet Address",
    dataIndex: "walletAddress",
    key: "walletAddress",
    ...getColumnSearchProps('walletAddress'),
    render: (text, record, index) => (
      <div>
        {text}
      </div>
    ),
  },
  
  {
    title: "IP Address",
    dataIndex: "ipAddress",
    key: "ipAddress",
    ...getColumnSearchProps('ipAddress'),
    render: (text, record) => (
      <div>
       {text.split(':')[3]}
      </div>
    ),
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text, record) => (
      <div>
       {text}
      </div>
    ),
  },
  {
    title: "Action",
    dataIndex: "walletAddress",
    key: "walletAddress",
    render: (text) => (
      <div>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteSDK(text)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];

const handleCreateChannelOk = async () => {
  console.log("new super user adding..", userName);
  if (userName && walletAddress) {
    // Channel name and image are provided
    try {
      let formData = new FormData();
      formData.append("walletAddress", walletAddress);
      formData.append("userName", userName);
      setIsCreateChannelBtnLoading(true);
      setCreateChannelModalOpen(false);
      apiPOST("AddNewStudioSuperUser", {"walletAddress": walletAddress,"userName": userName })
        .then(async (response) => {
          console.log(response);
          await refreshData();
          setIsCreateChannelBtnLoading(false);
          
        })
        .catch((error) => {
          console.log(error);
          setIsCreateChannelBtnLoading(false);
        });
    } catch (error) {
      // navigate("/login");
      // message.error(error);
    }

    // Add your channel creation logic here
  } else {
    // message.error("Please provide user name and wallet address");
  }
};

const handleCreateChannelCancel = async () => {
  setCreateChannelModalOpen(false)
};
  

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        // get list of all api keys 
        let response = await apiGET("getAllUserList");
        if (response.length > 0) {
          setData([]);
          console.log("response.data-super",response)
          dispatch(setAllUserList(response));
        } else {
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [list_refresher]);

  useEffect(() => {
    if (list_of_all_user.length > 0) {
      let temp = [];
      list_of_all_user.forEach((element) => {
        console.log(element);
        let users = {
          walletAddress: element.wallet_address,
          ipAddress: element.ip_address,
          createdAt: element.last_update_time,
        };
        temp.push(users);
      });
      setData(temp);
    }else{
      setData([])
    }
  }, [list_of_all_user,list_refresher]);
  

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          textAlign: "right",
        }}
      >
        {/* <div>
          {hasSelected ? (
            <Button
              type="primary"
              loading={deleteBtnLoading}
              onClick={handleDeleteAPIKeys}
              danger
            >
              Delete Selected [ {selectedRowKeys.length} ]
            </Button>
          ) : (
            ""
          )}
        </div> */}
      </div>

          {/* <Button
            type="primary"
            loading={isCreateChannelBtnLoading}
            style={{ marginRight: "2%" }}
            onClick={handleOpenCreateChannelModal}
          >
            Add Super User
          </Button> */}
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={pagination} // Add pagination prop and set pageSize
        onChange={handleTableChange} // Handle table change events
        scroll={{ x: "max-content" }} // Enable horizontal scrolling
        loading={loading}
        rowKey="id"
      />

<Modal
        title="Add New Super User"
        visible={isCreateChannelModalOpen}
        onOk={handleCreateChannelOk}
        onCancel={handleCreateChannelCancel}
      >
        <Form>
          <Form.Item label="User Name">
            <Input
              placeholder="Enter name"
              value={userName}
              onChange={handleUserNameChange}
            />
          </Form.Item>
          <Form.Item label="Wallet Address">
            <Input
              placeholder="Enter Wallet Address"
              value={walletAddress}
              onChange={handleWalletChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>

    
  );
};

export default ListOfUsers;
