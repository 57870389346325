import Web3 from "web3";
import { apiPOST, apiPOSTTokenLess } from "../apis/service";

export const connectToMetaMask = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // Request user permission to connect to MetaMask
      await window.ethereum.enable();
      // Retrieve the current accounts
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (accounts.length > 0) {
        const currentAccount = accounts[0];
        console.log("Connected to MetaMask");
        console.log("Current account address:", currentAccount);
        // You can store the current account address in a state variable or use it as needed in your application
        resolve(currentAccount); // Resolve with the current account address
      } else {
        console.log("No accounts found");
        reject(new Error("No accounts found"));
      }
    } catch (error) {
      console.log("Error connecting to MetaMask:", error);
      reject(error);
    }
  });
};
export const isWalletConnected = async () => {
  try {
    // Check if the ethereum object is available
    if (window.ethereum) {
      // Request accounts to check if the wallet is connected
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (accounts.length > 0) {
        // Wallet is connected
        return true;
      } else {
        // Wallet is not connected
        return false;
      }
    } else {
      // Ethereum object not found, wallet is not connected
      return false;
    }
  } catch (error) {
    console.log("Error checking wallet connection:", error);
    return false;
  }
};
export const getCurrentWalletAddress = async () => {
  try {
    // Check if the ethereum object is available
    if (window.ethereum) {
      // Request accounts to retrieve the current wallet address
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (accounts.length > 0) {
        // Convert the address to checksum format
        const web3 = new Web3();
        const checksumAddress = web3.utils.toChecksumAddress(accounts[0]);

        // Return the checksum-formatted address
        return checksumAddress;
      } else {
        // No accounts found, return null
        return null;
      }
    } else {
      // Ethereum object not found, return null
      return null;
    }
  } catch (error) {
    console.log("Error retrieving wallet address:", error);
    return null;
  }
};

export const getWalletAddressChecksum = async (wallet) => {
  try {
    // Check if the ethereum object is available
    if (window.ethereum) {

      if (wallet.length > 0) {
        // Convert the address to checksum format
        const web3 = new Web3();
        const checksumAddress = web3.utils.toChecksumAddress(wallet);

        // Return the checksum-formatted address
        return checksumAddress;
      } else {
        // No accounts found, return null
        return null;
      }
    } else {
      // Ethereum object not found, return null
      return null;
    }
  } catch (error) {
    console.log("Error retrieving wallet address:", error);
    return null;
  }
};

export const createSignatureWithMetaMask = (message) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);

        await window.ethereum.enable();

        const accounts = await web3.eth.getAccounts();
        const senderWalletAddress = accounts[0];

        const signatureCreated = await web3.eth.personal.sign(
          message,
          senderWalletAddress
        );
        apiPOSTTokenLess("verifySignature", {
          signatureCreated,
          message,
          senderWalletAddress,
        })
          .then(async (response) => {
            if (response.success) {
              let user = {token:response.authToken}
              localStorage.setItem("user",JSON.stringify(user))
              console.log("Signature sent successfully:", response);
              resolve(response);
            } else {
              console.log("Failed to send the signature to the backend");
              reject(new Error("Failed to send the signature to the backend"));
            }
          })
          .catch((error) => {
            console.log("Failed to send the signature to the backend", error);
            reject(new Error("Failed to send the signature to the backend"));
          });
        // const response = await fetch("your-backend-url", {
        //   method: "POST",
        //   body: JSON.stringify({ signature, message }),
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });
      } else {
        console.log("MetaMask not detected");
        reject(new Error("MetaMask not detected"));
      }
    } catch (error) {
      console.log("Error occurred:", error);
      reject(error);
    }
  });
};
