let BASE_URL = process.env.REACT_APP_BACKEND_URL;
const apiPOST = async (api, body) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
    },
    body: JSON.stringify(body),
  });
  return await res.json();
};
const apiPOSTTokenLess = async (api, body) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return await res.json();
};
const apiPOSTFormData = async (api, body) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
    },
    body: JSON.stringify(body),
  });
  return await res.json();
};
const apiPOSTFile = async (api, formData) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    method: "POST",
    headers: {
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
    },
    body: formData,
  });
  return await res.json();
};
const apiGET = async (api) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
    },
  });
  return await res.json();
};

const apiDELETE = async (api, body) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
    },
    body: JSON.stringify(body),
  });

  return await res.json();
};

export {
  apiPOST,
  apiGET,
  apiDELETE,
  apiPOSTTokenLess,
  apiPOSTFormData,
  apiPOSTFile,
};
