import React, { useEffect } from "react";
import { Menu, Avatar } from "antd";
import { UserOutlined, CodeOutlined, LogoutOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectCurrentConnectedWallet } from "../../../redux/slice/auth/auth-slice";
import { useNavigate } from "react-router-dom";

const RightMenu = ({ mode }) => {
  const current_connected_wallet = useSelector(selectCurrentConnectedWallet);
  const navigation = useNavigate();
  useEffect(()=>{
    if(current_connected_wallet==null){
      	navigation("/login")
    }
  },[current_connected_wallet])
  return (
    <Menu style={{backgroundColor:"#001529",color:"white"}} mode={mode}>
      
      <Menu.Item key="log-out">
        {/* <LogoutOutlined /> */}
         <span className="menu-item-text">
        {/* Logout */}
          </span>
      </Menu.Item>
      <Menu.Item key="account" style={{fontWeight:"bold"}}>
        <CodeOutlined /> <span className="menu-item-text">
          {current_connected_wallet == null
            ? "wallet not connected"
            : current_connected_wallet}
        </span>
      </Menu.Item>
    </Menu>
  );
};

export default RightMenu;
