import { Divider, Modal, Radio, Switch, Table } from "antd";
import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../../utils/Metamask";
import { apiGET, apiPOST } from "../../../apis/service";
import {
  selectTopPlayers,
  setTopPlayers,
  refreshTopPlayers
} from "../../../redux/slice/dashboard/dashboard-slice";
const data = [
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "Baeble",
    is_boost_enabled: true,
    address: "0xA69...ADB8",
    key: "0xA69...ADB8",
    boost: "43",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "24 Hour Free Movies",
    is_boost_enabled: false,
    address: "0xC69...D345",
    key: "0xC69...D345",
    boost: "0",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "Grappling",
    is_boost_enabled: true,
    address: "0xEB2...A1B8",
    key: "0xEB2...A1B8",
    boost: "12",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "AlliedEsports",
    is_boost_enabled: true,
    address: "0x2BE...5D40",
    key: "0x2BE...5D40",
    boost: "65",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "WPT",
    is_boost_enabled: false,
    address: "0xAE2...F2D2",
    key: "0xAE2...F2D2",
    boost: "0",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "SkiTV",
    is_boost_enabled: true,
    address: "0xCD3...1A3E",
    key: "0xCD3...1A3E",
    boost: "27",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "PokerNight",
    is_boost_enabled: true,
    address: "0x90B...9492",
    key: "0x90B...9492",
    boost: "82",
  },
  {
    icon: "https://edge-studio-bucket.s3.amazonaws.com/91c28cdc-a525-4dfa-88f2-a9b6c3f86cf0-eat_icon.png",
    channel: "euronews",
    is_boost_enabled: false,
    address: "0x34D...86B9",
    key: "0x34D...86B9",
    boost: "0",
  },
  // Add more random records here...
];
const WalletsTable = ({ apiKey }) => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const list_refresher = useSelector(refreshTopPlayers);
  const list_of_top_players = useSelector(selectTopPlayers);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const dispatch = useDispatch();
  const handleCopyAddress = (address) => {
    message.success(`Address ${address} copied to clipboard`);
  };

  const handleEditDomain = async (record) => {
    console.log("Edit domain:", record);

  };

  const refreshData = async () => {
    setLoading(true);
    try {
      let response = await apiGET("gaimify/GetTopPlayersBySDK/" + apiKey, {
      });
      if (response.length > 0) {
        setItems([]);
        dispatch(setTopPlayers(response));
      } else {
        setItems([]);
        dispatch(setTopPlayers([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  // useEffect(() => {
  //   //fetch the list api keys
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       let response = await apiGET("gaimify/GetTopPlayersBySDK/"+apiKey, {
  //       });
  //       if (response.length > 0) {
  //         // setItems([]);
  //         dispatch(setTopPlayers(response));
  //       } else {
  //         // setItems([]);
  //         dispatch(setTopPlayers([]));
  //       }
  //       setLoading(false);
  //     } catch (e) {
  //       setLoading(false);
  //       //display msg that error in loading sdks
  //     }
  //   };
  //   fetchData();
  //   let topPlayerFetcher = setInterval(() => {
  //       fetchData();
  //     }, 60000);

  //     return() => {
  //       clearInterval(topPlayerFetcher)
  //     }
  // }, [apiKey]);

  useEffect(() => {
    if (list_of_top_players.length > 0) {
      let temp = [];

      list_of_top_players.forEach((element) => {
        temp.push({
          wallet: element.player_wallet,
          win_count: element.win_count,
          lose_count: element.lose_count,
        });
      });
      setItems(temp);
    } else {
      setItems([]);
    }
  }, [list_of_top_players]);

  const trimAddress = (address) => {
    if (!address || address.length < 11) {
      return address;
    }
    const start = address.substring(0, 5);
    const end = address.substring(address.length - 4);
    return `${start}...${end}`;
  }

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    handleCopyAddress(address);
  };
  const columns = [
    {
      title: "Rank",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (
        <div>
          <span>{index + 1}</span>
        </div>
      ),
    },
    // {
    //   title: "Player Address",
    //   dataIndex: "wallet",
    //   key: "wallet",
    //   render: (text, record) => (
    //     <div>
    //       <span>{text}</span>
    //     </div>
    //   ),
    // },
    {
      title: "Player Address",
      dataIndex: "wallet",
      key: "wallet",
      width: 250, // Set a fixed width if needed
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{trimAddress(text)}</span>
          <Button
            icon={<CopyOutlined />}
            size="small"
            onClick={() => handleCopy(text)}
            style={{ marginLeft: '10px' }}
          />
        </div>
      ),
    },
    {
      title: "Win Games",
      dataIndex: "win_count",
      key: "win_count",
    },
    {
      title: "Lose Games",
      dataIndex: "lose_count",
      key: "lose_count",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Table
      tableLayout="fixed"
      style={{ maxWidth: '100%' }} // Add this line
      rowSelection={{
        type: selectionType,
        ...rowSelection,
      }}
      pagination={pagination}
      columns={columns}
      dataSource={items}
      onChange={handleTableChange}
      loading={loading}
    />
  );
};

export default WalletsTable;
