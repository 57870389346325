import React, { useState, useRef } from "react";
import { Modal, Input, Button } from "antd";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { useEffect } from "react";

const QRCodeDisplay = ({ currentRecord, isOpen, onClose, onCreate }) => {
  const qrCodeRef = useRef(null);
  const handleOk = () => {
    const qrCodeElement = document.getElementById("qr-code");
    html2canvas(qrCodeElement).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = "qrcode.png";
      link.click();
    });
  };
  useEffect(() => {
    console.log(qrCodeRef);
  }, [qrCodeRef]);
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title="Generated QR code"
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save as Image"
      cancelText="Close"
   
    >
      <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"2%"}}>

      {currentRecord != null && (
        <QRCode id="qr-code" value={`https://gaimify.edgevideo.com:8080/qrRemote/?channelId=${currentRecord.channel_id}`}  style={{ width: "200px", height: "200px" }}/>
      )}
      </div>
    </Modal>
  );
};

export default QRCodeDisplay;
