import { useLocation } from "react-router-dom";
import styles from "../styles/leader_board.module.scss";
import ic_leader_board_default_icon from "../assets/images/leader_boar_logo.png";
import ic_star from "../assets/images/number_1_start.png";
import ic_new_edge_logo from "../assets/images/new_edge_logo.png";
import ic_profile from "../assets/images/profile.jpg";

import {
  selectTopLeaderPlayers,
  setTopLeaderPlayers,
} from "../redux/slice/dashboard/dashboard-slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { apiGET } from "../apis/service";
import { LoadingOutlined } from "@ant-design/icons";
const Leaderboard = () => {
  const location = useLocation();
  const list_top_leaders = useSelector(selectTopLeaderPlayers);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [filterTitle, setFilterTitle] = useState("Today");
  const [logo, setLogo] = useState([]);
  const [date, setDate] = useState([]);
  const [isDropDownOpen, setIsDropdownOpen] = useState(false);
  // Parse the query parameters
  const searchParams = new URLSearchParams(location.search);
  const channel_id = searchParams.get("channel_id");
  const time_period = searchParams.get("time_period");
  const team = searchParams.get("team");
  const [hrs, setHrs] = useState(time_period);

  const dispatch = useDispatch();

  function formatDate(date) {
    const options = { weekday: "long", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options).toUpperCase();
  }

  useEffect(() => {
    //fetch the list api keys
    const today = new Date();
    setDate(formatDate(today));
    setLoading(true);
    const fetchData = async () => {
      // setLoading(true);
      try {
        const country = team == "All" ? team : `+${team}`;
        let response = await apiGET(
          `gaimify/GetLeaderBoardByCountry/${country}/${channel_id}/${hrs}`,
          {}
        );
        if (response.length > 0) {
          // setItems([]);
          dispatch(setTopLeaderPlayers(response));
        } else {
          // setItems([]);
          dispatch(setTopLeaderPlayers([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };
    apiGET(`channel/loadChannelLogo/${channel_id}`)
      .then((response) => {
        setLogo(response.data[0].logo_url);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchData();
    let topPlayerFetcher = setInterval(() => {
      fetchData();
    }, 5 * 60000);

    return () => {
      clearInterval(topPlayerFetcher);
    };
  }, [channel_id, hrs]);

  useEffect(() => {
    if (list_top_leaders.length > 0) {
      let temp = [];

      list_top_leaders.forEach((element) => {
        temp.push({
          name: element.screen_name,
          points: element.points,
          flag: element.flag,
          icon: element.icon
        });
      });
      setItems(temp);
    } else {
      setItems([]);
    }
  }, [list_top_leaders]);

  const handleListItemClick = (selectedItem, title) => {
    setIsDropdownOpen(false);
    console.log(selectedItem);
    setHrs(selectedItem);
    setFilterTitle(title);
  };

  return (
    <div className={styles.container}>

      <div className={styles.board_container}>

        <div className={styles.board_heading}>
          <div className={styles.col1}>
            <img src={ic_new_edge_logo} className={styles.image} />
          </div>
          <div className={styles.col2}>
            <div className={styles.title_leader_board}>Leaderboard</div>
          </div>
        </div>

        <div className={styles.list_container}>
          {!loading ? (
            items.length > 0 ? (
              items.map((item, index) => (
                <div className={styles.list_item}>
                  <div className={styles.col1}>
                    {index == 0 ? (
                      <img src={ic_star} className={styles.image} />
                    ) : (
                      <div className={styles.text}>{index + 1}</div>
                    )}
                  </div>
                  <div className={styles.col5}>
                    <img src={item.icon} className={styles.image} />
                  </div>
                  <div className={styles.col2}>
                    <div className={styles.text}>{item.name}</div>
                  </div>
                  <div className={styles.col3}>
                    <div
                      className={styles.image}
                      dangerouslySetInnerHTML={{ __html: item.flag }}
                    ></div>
                  </div>
                  <div className={styles.col4}>
                    <div className={styles.text}>{item.points} PTS</div>
                  </div>
                </div>
              ))
            ) : (
              "Data Not Founded."
            )
          ) : (
            <LoadingOutlined />
          )}
        </div>

      </div>

      {/* <div className={styles.bottom_options_container}>
            <div className={styles.option1}>
            Edge
            </div>
            <div className={styles.option2}>
            Edge
            </div>
            <div className={styles.option3}>
            Edge
            </div>
        </div> */}
    </div>
  );
};

export default Leaderboard;
