import { Col, Row, Button, Dropdown, Modal, Form, Upload, Input,message, Tabs } from 'antd';
import '../../styles/edge-ai.css'
import { AimOutlined } from '@ant-design/icons';
import ListOfChannels from './ListOfChannels';

import { ProfileOutlined, RightSquareOutlined } from "@ant-design/icons";

import ChannelPrompts from './ChannelPrompts';

const tabs=[
  {
      tabTitle:"Channel Details",
      tabIcon:ProfileOutlined,
      tabContent:<ListOfChannels/>
  },
  // {
  //     tabTitle:"Prompts",
  //     tabIcon:RightSquareOutlined,
  //     tabContent:<ChannelPrompts/>
  // }
]

const EdgeAi=()=>{


    return <div className="edge_ai_container">
    <Row justify="space-between" align="middle" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={4} lg={2}>
        <AimOutlined style={{ fontSize: "3.5rem", color: "#08c" }} />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} className="edge-ai-title">
        QR & Second Screen
      </Col>
      <Col xs={24} sm={24} md={12} lg={14} className="sdks-dropdown" style={{ textAlign: "right" } }>

        </Col>
    </Row>

    <div className="tabs-container">
        <Tabs
          style={{height:"100%",padding:"1rem"}}
          defaultActiveKey="1"
          tabPosition="top"
          items={tabs.map((tab, i) => {
            const id = String(i + 1);
            return {
              label: (
                <span style={{fontSize:"1rem"}}>
                  <tab.tabIcon />
                  {tab.tabTitle}
                </span>
              ),
              key: id,
              children: <div>{tab.tabContent}</div>,
            };
          })}
        />
      </div>
    </div>
}

export default EdgeAi;