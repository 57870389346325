import React, { useState } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';

const ContactUs = () => {
  

  return (
    <div>
      <h1>Contact Us</h1>
      <Form >
        <Row>
          <Col span={24}>
            <Form.Item label="Name">
              <Input name="name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email">
              <Input name="email" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Message">
          <Input.TextArea name="message" />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Button type="primary" htmlType="submit">Submit</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ContactUs;
