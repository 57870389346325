
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/studio.css";
import { selectCurrentConnectedWallet } from "../redux/slice/auth/auth-slice";
import {
  ApiOutlined,
  DashboardOutlined,
  DollarOutlined,
  EditOutlined,
  KeyOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SketchOutlined,
  TabletOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";

import Navbar from "../components/general/Navbar";
import { selectCurrentTab, setCurrentTab } from "../redux/slice/ui/ui-slice";
import { useNavigate } from "react-router-dom";
import { getCurrentWalletAddress } from "../utils/Metamask";
import { apiPOST } from "../apis/service";
import ic_eat_icon from "../assets/images/eat_icon.png";
import APIKeys from "../components/APIKeys/APIKeys";
import TickerEditor from "../components/TickerEditor/TickerEditor";
import Dashboard from "../components/Dashboard/Dashboard";
import ChannelWallets from "../components/ChannelWallets/ChannelWallets";
import SecondScreen from "../components/SecondScreen/SecondScreen";
import SupportedPlateforms from "../components/SupportedPlateforms/SupportedPlateforms";
import SDKs from "../components/SDKs/SDKs";
import GettingStarted from "../components/Documentation/GettingStarted";
const { Header, Sider, Content } = Layout;

const Documentation = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const current_tab = "getting_started";
  const dispatch = useDispatch();

  const handleSwitchTab = (tab_name) => {
    dispatch(setCurrentTab(tab_name.key));
  };

  useEffect(() => {
    const authenticateToken = async () => {
      // checking if auth token
      let token = JSON.parse(localStorage.getItem("user"));
      if (token == null) {
        navigate("/login");
      } else {
        // verify if it is valid still
        let current_connected_wallet = await getCurrentWalletAddress();
        try {
          const response = await apiPOST("verifyAuthToken", {
            walletAddress: current_connected_wallet,
          });
          if (!response.success) {
            navigate("/login");
          }
        } catch (error) {
          navigate("/login");
        }
      }
    };

    authenticateToken();

    // No dependencies provided to run the effect only once on component mount
    // If you have dependencies, make sure to include them in the dependency array
  }, []);

  return (
    <div>
      <Navbar />
      <div style={{ paddingLeft:"5%",paddingRight:"10%",marginTop:"2%" }}>
       <GettingStarted/>
      </div>
    </div>
  );
};

export default Documentation;

// {/* <Sider trigger={null} collapsible collapsed={collapsed}>
// <div className="demo-logo-vertical" />
// {/* <div style={{display:"flex",justifyContent:"center",padding:"1rem"}}> 
//   <img src={ic_eat_icon} width={"50%"} />
// </div> */}
// <Menu
//   theme="dark"
//   mode="inline"
//   defaultSelectedKeys={["dashboard"]}
//   onClick={handleSwitchTab}
//   items={[
//     {
//       key: "getting_started",
//       icon: <DashboardOutlined />,
//       label: "Getting Start",
//     },
//     {
//       key: "watch2earn",
//       icon: <DashboardOutlined />,
//       label: "Watch2Earn",
//     },
//     {
//       key: "gamification",
//       icon: <DashboardOutlined />,
//       label: "Gamification",
//     },
//     {
//       key: "boosting",
//       icon: <DashboardOutlined />,
//       label: "Boosting",
//     },
//     {
//       key: "wallet_forwarding",
//       icon: <DashboardOutlined />,
//       label: "Wallet Forwarding",
//     },
//     {
//       key: "sdk_less_integration",
//       icon: <DashboardOutlined />,
//       label: "SDK-less integration",
//     },
//     {
//       key: "prompt_editing",
//       icon: <DashboardOutlined />,
//       label: "Prompt Editing",
//     }
//   ]}
// />
// </Sider>
// <Layout>
// <Header
//   style={{
//     padding: 0,
//     background: colorBgContainer,
//   }}
// >
//   <Button
//     type="text"
//     icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
//     onClick={() => setCollapsed(!collapsed)}
//     style={{
//       fontSize: "16px",
//       width: 64,
//       height: 64,
//     }}
//   />
// </Header>
// <Content
//   style={{
//     margin: "24px 16px",
//     padding: 24,
//     minHeight: 280,
//     background: colorBgContainer,
//   }}
// >
//   {current_tab == "getting_started" && (
//     <div
//       style={{
//         height: "100%",
//         overflowY: "auto",
//         overflowX: "hidden",
//       }}
//       className="dashboard-container"
//     >
//       <GettingStarted/>
//     </div>
//   )}
 
// </Content>
// </Layout> */}