import React, { useEffect, useRef, useState } from "react";
import { Modal, Input, Button, Form, message, Dropdown, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DownOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { apiDELETE, apiPOST } from "../../apis/service";
import { useDispatch, useSelector } from "react-redux";
import {
  setListOfChannelDetails, setListOfChannelPrompts,
} from "../../redux/slice/channel_details/channel_details-slice";
import { getCurrentWalletAddress } from "../../utils/Metamask";
// import ReactHlsPlayer from "react-hls-player";
import Hls from "hls.js";

const EditChannelPrompts = ({ currentRecord, isOpen, onClose, onCreate }) => {
  const previewBtnRef = useRef();
  const [promptText, setPromptText] = useState(`Internally: First analyze the content of this imperfect speech-to-text transcript from <<<Title>>>.  Next generate several possible 4-option polls based on it which obey the following criteria:
  1. The poll is silly, funny, whacky and engaging without being corny
  2. The incorrect options are not misleading
  3. The poll is relatively easy
  4. The poll is sfw - nothing related to sex, no curse words, no potty humor
  5. Neither the poll question nor answers are meta - they don't mention that they're based on a transcript
  6. Don't reference 'the speaker' in the question or options
  
  Finally select the best poll you generated, improve it to further obey the criteria, and call createPoll.
  
  <<<Transcript>>>`);
  const [promptType, setpromptType] = useState("");
  const [isUpdateBtnLoading,setIsUpdateBtnLoading]=useState(false)
  const [channelID, setChannelID] = useState("");
  const [promptID, setPromptID] = useState("");
  const [isPromptChanged, setIsPromptChanged] = useState(false);
  const playerRef = useRef(null);
  const [types, setTypes] = useState([{label:"Content Quiz"},{label:"Ad Quiz"},{label:"Content Wager"}]);
  const [currentSelectedType, setCurrentSelectedType] = useState(types[0]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentRecord) {
      setCurrentSelectedType({label :currentRecord.prompt_type});
      
      if(currentRecord.prompt_text.length>0)
      setPromptText(currentRecord.prompt_text);

      setChannelID(currentRecord.channel_id)
      setPromptID(currentRecord.prompt_id)
      setIsPromptChanged(false);
    }
  }, [currentRecord]);

  const handleOk = async() => {

    console.log("channel Details Updated")
    try {
      let hasTitlePlaceholder = promptText.includes("<<<Title>>>");
    let hasTranscriptPlaceholder = promptText.includes("<<<Transcript>>>");
    if(!hasTitlePlaceholder,!hasTranscriptPlaceholder){
      message.error("Prompt must have the <<<Title>>>  and <<<Transcript>>> tags")
      message.error("Prompt must have the <<<Title>>>  and <<<Transcript>>> tags")
      return
    }
      setIsUpdateBtnLoading(true)
      const response = await apiPOST("channel/UpdateChannelPrompts", {
        prompt_text: promptText,
        prompt_type: currentSelectedType.label,
        channel_id: channelID,
        promptID: promptID 
      });
      await fetchData()
      setIsUpdateBtnLoading(false)
      message.success(`Updated record successfully`);
      message.success(`Updated record successfully`);
      if (!response.success) {
        //navigate("/login");
      }
    } catch (error) {
     // navigate("/login");
    }

    onCreate({
      promptText,
      promptType,
      channelID,
      promptID,
    });
  };

  const handleItemClick = (type) => {
    setCurrentSelectedType(type);
  };

  const fetchData = async () => {
    let current_connected_wallet = await getCurrentWalletAddress()
    try {
     
      let response = await apiPOST("channel/GetChannelPromptsByParent", {
        parent: current_connected_wallet,
      });
     
      if (response.length > 0) {
        console.log("Channel Details", response )
        dispatch(setListOfChannelPrompts(response));
      } else {
        dispatch(setListOfChannelPrompts([]));
      }
    } catch (e) {
      console.log(e)
    }
  };

  const handleCancel = () => {
    onClose();
  };

  // const handlePreviewChanges = () => {
  //   setIsChangedPreviewed(true);
    
  //   if(Hls.isSupported())
	// 	{
  //     console.log("playing",hlsStream)
	// 	  let	hls = new Hls();
	// 		hls.loadSource(hlsStream);
	// 		let vid = document.getElementById("preview_changes");
	// 		hls.attachMedia(vid);
	// 		previewBtnRef.current.innerText = "Play";
	// 	}
  // };

  return (
    <Modal
      title="Edit channel prompt"
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Update"
      cancelText="Cancel"
      width={'90%'}
    >
      <Form.Item label="Prompt Type">
      <div>
           <Dropdown
              type="primary"
              size="3rem"
              icon={<DownOutlined />}
              loading={false}
              menu={{
                items:
                types.length > 0
                    ? types.map((item) => ({
                        ...item,
                        onClick: () => handleItemClick(item),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                <Space>
              {types.length > 0
                ? currentSelectedType.label || "No type found"
                : "No type available"}

                <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </div>
      </Form.Item>
      <Form.Item label="Prompt Text">
        <TextArea
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
          autoSize={{minRows: 19}}
          showCount={true}
          allowClear={true}
        />
      </Form.Item>
    </Modal>
  );
};

export default EditChannelPrompts;
