// uiSlice.js
import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    current_tab:"dashboard",
    current_page:"studio"
  },
  reducers: {
    setCurrentTab: (state,action) => {
      state.current_tab=action.payload
    },
    setCurrentPage: (state,action) => {
        state.current_page=action.payload
      },
  },
});

export const {
   setCurrentPage,
   setCurrentTab
} = uiSlice.actions;

export const selectCurrentPage = (state) => state.ui.current_page;
export const selectCurrentTab = (state) => state.ui.current_tab;

export default uiSlice.reducer;
