
import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "antd";
import "../../styles/api-keys.css";
import { EditOutlined, FileImageOutlined, UploadOutlined } from "@ant-design/icons";



import { useDispatch } from "react-redux";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import LogoEditor from "./LogoEditor";
import MessageEditor from "./MessageEditor";
import ChannelLogoEditor from "./ChannelLogoEditor";
import '../../styles/ticker-editor.css'
const tabs=[
    {
        tabTitle:"Edit SDK Logo",
        tabIcon:FileImageOutlined,
        tabContent:<LogoEditor/>
    },
    {
        tabTitle:"Edit Channel Logo",
        tabIcon:UploadOutlined,
        tabContent:<ChannelLogoEditor/>
    }
]

const TickerEditor = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <div style={{height:"100%"}}>
      <Row justify="center" align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={4} lg={2}>
          <EditOutlined style={{ fontSize: "3.5rem", color: "#08c" }} />
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} className="api-key-title">
          Ticker Editor
        </Col>
        <Col xs={24} sm={24} md={12} lg={16} className="api-key-register-btn">
          {/* <Button type="primary" loading={loading}>
            Drop-down for list of existing sdks
          </Button> */}
        </Col>
      </Row>
      <div className="tabs-container">
        <Tabs
          style={{height:"100%",padding:"1rem"}}
          defaultActiveKey="1"
          tabPosition="top"
          items={tabs.map((tab, i) => {
            const id = String(i + 1);
            return {
              label: (
                <span style={{fontSize:"1rem"}}>
                  <tab.tabIcon />
                  {tab.tabTitle}
                </span>
              ),
              key: id,
              children: <div>{tab.tabContent}</div>,
            };
          })}
        />
      </div>
    </div>
  );
};

export default TickerEditor;
