import React from "react";
import { Button, Col, Divider, Dropdown, Form, Row, Space, Table, Upload, message } from "antd";
import ic_eat_icon from "../../assets/images/eat_icon.png";
import "../../styles/logo-editor.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import { apiPOST, apiPOSTFile, apiPOSTFormData } from "../../apis/service";
import {
  refreshList,
  refreshListOfAPIKeys,
  selectListOfAPIKeys,
  setListOfAPIKeys,
} from "../../redux/slice/api_keys/api_keys-slice";
import {
  updateAPIKeyFromListOfChannelDetails,
  refreshListOfChannelDetails,
  selectListOfChannelDetails,
  setListOfChannelDetails, } from "../../redux/slice/channel_details/channel_details-slice";
import { DownOutlined, EditOutlined, UploadOutlined } from "@ant-design/icons";
// const items = [
//   {
//     label: "Submit and continue",
//     key: "1",
//   },
// ];
const ChannelLogoEditor = () => {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState({});
  const [uploadImageBtnLoading,setupLoadImageBtnLoading]=useState(false)
  const [items, setItems] = useState([]);
  const [data, setData] = useState([]);
  const fileInputRef = useRef(null);
  const list_refresher = useSelector(refreshListOfAPIKeys);
  const list_of_api_keys = useSelector(selectListOfAPIKeys);
  const [currentSelectedSdk, setCurrentSelectedSdk] = useState(
    list_of_api_keys[0]
  );
  const list_of_details_refresher = useSelector(refreshListOfChannelDetails);
  const list_of_channel_details = useSelector(selectListOfChannelDetails);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = [];
      list_of_api_keys.forEach((element) => {
        temp.push({
          label: element.sdk_title,
          key: element.sdk_api_key,
          logo_url: element.logo_url,
        });
      });
      setItems(temp);
      setCurrentSelectedSdk(temp[0]);
      refreshData()
    }
  }, [list_of_api_keys]);
  const dispatch = useDispatch();
  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiPOST("getSdksByWalletAddress", {
          walletAddress: current_connected_wallet,
        });
        if (response.data.length > 0) {
          setItems([]);
          dispatch(setListOfAPIKeys(response.data));
        } else {
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [list_refresher]);

  const refreshData = async (apiKey) => {
    setLoading(true);
    let sdk = apiKey?apiKey:currentSelectedSdk.key
    try {
      let response = await apiPOST("channel/GetChannelDetailsByApiKey", {
        apiKey: sdk,
      });
      console.log("response-GetChannelDetailsByParent", response);
      if (response.length > 0) {
        setData([]);
        dispatch(setListOfChannelDetails(response));
      } else {
        setData([]);
        dispatch(setListOfChannelDetails([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  useEffect(() => {
    setLoading(true)
    if (list_of_channel_details.length > 0) {
      let temp = [];
      list_of_channel_details.forEach((element) => {
        temp.push({
          channel_id: element.channel_id,
          channel_name: element.channel_name,
          logo_url: element.logo_url,
          key: element.channel_id
        });
        setLoading(false)
      });
      setData(temp);
    } else {
      setData([]);
      setLoading(false)
    }
  }, [list_of_channel_details]);

  const handleItemClick = async(item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedSdk(item);
    await refreshData(item.key)
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const onFileChange = (info, channelId) =>{
    setUploading((prevLoading) => ({
      ...prevLoading,
      [channelId]: true, // Set loading to true for this row
    }));
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      message.success(`${info.file.name} file uploaded successfully`);
      setUploading((prevLoading) => ({
        ...prevLoading,
        [channelId]: false, // Set loading to true for this row
      }));
      refreshData()
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
      message.error(`${info.file.name} file upload failed.`);
      setUploading((prevLoading) => ({
        ...prevLoading,
        [channelId]: false, // Set loading to true for this row
      }));
    }
  }

  const columns = [
    {
      title: "Channel Name",
      dataIndex: "channel_name",
      key: "channel_name"
    },
    {
      title: "Channel ID",
      dataIndex: "channel_id",
      key: "channel_id"
    },
    {
      title: "Channel Logo",
      dataIndex: "logo_url",
      key: "logo_url",
      width: 200,
      render: (text,record) => (
        <div style={{ display: "flex", alignItems: "center", width:"200px" }}>
        <img src={text} alt="Logo" width={"40%"} style={{ marginRight: "8px" }} />
        <div>
          <Upload maxCount={1} 
                  name="file" 
                  showUploadList={uploading[record.channel_id]} 
                  openFileDialogOnClick={true} 
                  action={`${process.env.REACT_APP_BACKEND_URL}/channel/uploadChannelLogo/${record.channel_id}`} 
                  onChange={(info)=>onFileChange(info, record.channel_id)}
                  progress={{
                    strokeColor: {
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    },
                    strokeWidth: 4,
                    format: (percent) => `${parseFloat(percent.toFixed(2))}%`, // Custom progress format
                  }}
                  >
          <Button loading={uploading[record.channel_id]} type="link" icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </div>
      </div>
      ),
    },
  ];

  return (
    <div className="logo-editor-container">
      <Row justify="center" align="middle" gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={16}
          lg={16}
          className="logo-editor-sdks-dropdown"
        >
          <div>
          <Form.Item label={<span style={{fontWeight: 'bold'}}>Select SDK</span>}>
           <Dropdown
              type="primary"
              size="3rem"
              icon={<DownOutlined />}
              loading={false}
              menu={{
                items:
                  items.length > 0
                    ? items.map((item) => ({
                        ...item,
                        onClick: () => handleItemClick(item),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                <Space>
              {items.length > 0
                ? currentSelectedSdk.label || "No sdk found"
                : "No SDKs available"}

                <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Divider style={{ borderWidth: "2px" }} />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24}>
        <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={pagination} // Add pagination prop and set pageSize
        onChange={handleTableChange} // Handle table change events
        scroll={{ x: "max-content" }} // Enable horizontal scrolling
        loading={loading}
        rowKey="channel_id"
      />
        </Col>
      </Row>
    </div>
  );
};

export default ChannelLogoEditor;
