import { Divider, Modal, Radio, Table } from "antd";
import { Button, message } from "antd";
import { CopyOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { getCurrentWalletAddress } from "../../../utils/Metamask";
import { useDispatch, useSelector } from "react-redux";
import { useState , useEffect } from "react";
import { apiPOST } from "../../../apis/service";
import Web3 from "web3";
import {
  refreshList,
  refreshListOfChannelWallets,
  selectListOfChannelWallets,
  setListOfChannelWallets,
} from "../../../redux/slice/channel_wallets/channel_wallets-slice";


const ChannelsTable = ({apiKey}) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [gasPrice, setGasPrice] = useState(null);
  const list_refresher = useSelector(refreshListOfChannelWallets);
  const list_of_channel_wallets = useSelector(selectListOfChannelWallets);

  const [selectionType, setSelectionType] = useState("checkbox");
  
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });

  const dispatch = useDispatch();

  let web3 = new Web3()
  const polygonGasStation = async() => {
    let response = await fetch("https://gasstation.polygon.technology/v2/");
    let estimatedGas = await response.json()
    let gas_price = parseFloat(Math.random() * (estimatedGas.standard.maxFee - estimatedGas.safeLow.maxFee + 1.0000) + estimatedGas.safeLow.maxFee).toFixed(9)
    return gas_price;
}

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      setLoading(true);
      try {
        let response = await apiPOST("channel/GetChannelWalletsByKey", {
          apiKey: apiKey,
        });
        if (response.rows.length > 0) {
          setItems([]);
          dispatch(setListOfChannelWallets(response.rows));
        } else {
          setItems([]);
          dispatch(setListOfChannelWallets([]))
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };
    fetchData();

    fetch("https://gasstation.polygon.technology/v2/").then(async(response)=>{
    let estimatedGas = await response.json()
    let gas_price = parseFloat(Math.random() * (estimatedGas.standard.maxFee - estimatedGas.safeLow.maxFee + 1.0000) + estimatedGas.safeLow.maxFee).toFixed(9)
    let wei = web3.utils.toWei(gas_price,"gwei")
    let eth = web3.utils.fromWei(wei,"ether")
    setGasPrice(parseFloat(eth)*21000);
    }).catch((err)=>{
    console.log(err)
    })

    let gasPriceFetcher = setInterval(async() => {
      let response = await fetch("https://gasstation.polygon.technology/v2/");
    let estimatedGas = await response.json()
    let gas_price = parseFloat(Math.random() * (estimatedGas.standard.maxFee - estimatedGas.safeLow.maxFee + 1.0000) + estimatedGas.safeLow.maxFee).toFixed(9)
    let wei = web3.utils.toWei(gas_price,"gwei")
    let eth = web3.utils.fromWei(wei,"ether")
    setGasPrice(parseFloat(eth)*21000);

    }, 5000);

    return () => {
      clearInterval(gasPriceFetcher)
    }

  }, [apiKey]);

  const refreshData = async () => {
    setLoading(true);
    try {
      let response = await apiPOST("channel/GetChannelWalletsByKey", {
        apiKey: apiKey,
      });
      if (response.rows.length > 0) {
        setItems([]);
        dispatch(setListOfChannelWallets(response.rows));
      } else {
        setItems([]);
        dispatch(setListOfChannelWallets([]))
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };


  const fetchBalanceData =async(address)=>{
    setLoading(true)
    let response = await fetch(
      `https://eat.edgevideo.com/eat_balance/${address}`
    )
    let data = await response.json()
    setLoading(false)
    return data.balance
  }

  useEffect(() => {
    let temp = [];
    if (list_of_channel_wallets.length > 0) {
      
      list_of_channel_wallets.forEach((element) => {
        temp.push({
          key: element.channel_wallet,
          name: element.name,
          domains: "*",
          address: element.channel_wallet,
          balance: null,
          estAvailableTx: null
        });
    });
    setItems(temp)
  }else{
    setItems([])
  }

  const fetchData = async () => {
    setLoading(false);
    const updatedData = await Promise.all(
      temp.map(async (item) => {
        const balance = await fetchBalanceData(item.address);
         return {
          ...item,
          balance,
        };
      })
    );

    setItems(updatedData);
    setLoading(false);
  };

  fetchData()

  }, [list_of_channel_wallets]);

  const ethWalletTrimShorten = (wallet = "") => {
    return `${wallet.substring(0,5)}...${wallet.substring(38,42)}`
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleCopyAddress = async(address) => {
    try{
    await navigator.clipboard.writeText(address)
    message.success(`Address ${address} copied to clipboard`);
    message.success(`Address ${address} copied to clipboard`);
    }catch(err){
      message.warning(`Address ${address} not copied to clipboard`);
      message.warning(`Address ${address} not copied to clipboard`);
    }
  };

  const handleDeleteAddress = async(address) => {
    console.log("Deleted", address)
    Modal.confirm({ 
      title: "Are you sure you want to delete this", 
      onOk: async() => { 
        try {
          let response = await apiPOST("channel/DeleteChannelWallet", {
            channelWallet: address
          });
          
          await refreshData()
        } catch (e) {
          console.log(e)
        }
      }, 
      });
  };

  const handleEditDomain = (record) => {
    // Logic to handle editing the domain
    // You can implement your own edit functionality here
    // For demonstration purposes, we'll simply log the record to the console
    console.log("Edit domain:", record);
  };
 
  const columns = [
    {
      title: "Wallet Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Domains",
      dataIndex: "domains",
      key: "domains",
      render: (text, record) => (
        <div>
          {text}
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditDomain(record)}
          >
            Edit
          </Button>
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text, record) => (
        <div>
          {text}
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={() => handleCopyAddress(record.address)}
          >
            Copy
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteAddress(record.address)}
          >
            Delete
          </Button>
        </div>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => (
        <div>
          {record.balance !== null ? (
            record.balance
          ) : (
            <span><LoadingOutlined /></span>
          )}
        </div>
      ),
    },
    {
      title: "Est. Available Tx",
      dataIndex: "estAvailableTx",
      key: "estAvailableTx",
      render: (text, record) => (
        <div>
          {gasPrice !== null ? (
              (parseFloat(record.balance)/ gasPrice).toFixed(0)
          ):(
           <span><LoadingOutlined /></span>
          )}
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div style={{ marginTop: "1%" }}>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        pagination={pagination}
        columns={columns}
        dataSource={items}
        onChange={handleTableChange}
        loading={loading}
        rowKey="channel_wallet"
      />
    
    </div>
  );
};

export default ChannelsTable;
