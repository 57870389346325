import { Divider, Modal, Radio, Switch, Table } from "antd";
import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../../utils/Metamask";
import { apiGET, apiPOST } from "../../../apis/service";
import {
  selectTSVDay,
  setTSVDay,
  refreshTSV
} from "../../../redux/slice/dashboard/dashboard-slice";

const ChannelTSV = ({ apiKey,days }) => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const list_refresher = useSelector(refreshTSV);
  const list_channel_tsv = useSelector(selectTSVDay);
  const [pagination, setPagination] = useState({
    pageSize: 20, // Set the desired number of rows per page
  });
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const dispatch = useDispatch();
  const handleCopyAddress = (address) => {
    message.success(`Address ${address} copied to clipboard`);
  };

  const handleEditDomain = async(record) => {
    console.log("Edit domain:", record);

  };

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      setLoading(true);
      try {
        let response = await apiGET(`tsv/getChannelTsvByApiKey/${apiKey}/${days}`, {
        });
        if (response.result.length > 0) {
          setItems([]);
          dispatch(setTSVDay(response.result));
        } else {
          setItems([]);
          dispatch(setTSVDay([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };
    fetchData();
    // let topGamesFetcher = setInterval(() => {
    //     fetchData();
    //   }, 60000);

    //   return() => {
    //     clearInterval(topGamesFetcher)
    //   }
  }, [apiKey,days]);

  useEffect(() => {
    if (list_channel_tsv.length > 0) {
      let temp = [];

      list_channel_tsv.forEach((element) => {
        temp.push({
          channel_name: element.channel_name,
          // channel_id: element.channel_id,
          total_time_spent: element.total_time_spent,
          is_gamified: element.is_gamified,
        });
      });
      setItems(temp);
    }else{
      setItems([]);
    }
  }, [list_channel_tsv]);


  const columns = [
    {
        title: "#",
        dataIndex: "index",
        key: "index",
        render: (text, record, index) => (
          <div>
            <span>{index+1}</span>
          </div>
        ),
      },
    {
      title: "Channel Name",
      dataIndex: "channel_name",
      key: "channel_name",
      render: (text, record) => (
        <div>
          <span>{text}</span>
        </div>
      ),
    },
    // {
    //   title: "Channel ID",
    //   dataIndex: "channel_id",
    //   key: "channel_id",
    // },
    {
      title: "Is Gaimified",
      dataIndex: "is_gamified",
      key: "is_gamified",
      filters: [
        {
          text: 'True',
          value: 'true',
        },
        {
          text: 'False',
          value: 'false',
        },
      ],
      onFilter: (value, record) => record.is_gamified+"" == value,
      filterSearch: true,
       width: '20%',
      render: (text, record) => (
        <div>
          <span>{text+""}</span>
        </div>
      ),
    },
    {
        title: "Time Spent Viewing(Hours)",
        dataIndex: "total_time_spent",
        key: "total_time_spent",
        sorter: (a, b) => a.total_time_spent - b.total_time_spent,
        render: (text, record) => (
          <div>
            <span>{text+" h"}</span>
          </div>
        ),
      },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div style={{ marginTop: "1%" }}>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        pagination={pagination}
        columns={columns}
        dataSource={items}
        onChange={handleTableChange}
        loading={loading}
      />
    </div>
  );
};

export default ChannelTSV;
