import React, { useEffect, useRef, useState } from "react";
import { Modal, Input, Button, Form, message, Dropdown, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DownOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { apiDELETE, apiPOST } from "../../apis/service";
import { useDispatch, useSelector } from "react-redux";
import {
  setListOfChannelDetails,
} from "../../redux/slice/channel_details/channel_details-slice";
import { getCurrentWalletAddress } from "../../utils/Metamask";
// import ReactHlsPlayer from "react-hls-player";
import Hls from "hls.js";

const EditChannelData = ({currentRecord, channels, isOpen, onClose, onCreate }) => {
  const previewBtnRef = useRef();
  const [name, setName] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [isUpdateBtnLoading,setIsUpdateBtnLoading]=useState(false)
  const [hlsStream, setHlsStream] = useState("");
  const [prompt, setPrompt] = useState("");
  const [channelID, setChannelID] = useState("");
  const [isPromptChanged, setIsPromptChanged] = useState(false);
  const [isChangedPreviewed, setIsChangedPreviewed] = useState(false);
  const playerRef = useRef(null);
  const [currentSelectedWallet, setCurrentSelectedWallet] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    if (currentRecord) {
      setWalletAddress(currentRecord.wallet_address);
      setName(currentRecord.channel_name);
      setHlsStream(currentRecord.hls);
      setPrompt(currentRecord.current_prompt);
      setChannelID(currentRecord.channel_id)
      setIsChangedPreviewed(false);
      setIsPromptChanged(false);
      setCurrentSelectedWallet(channels.filter((element) => element.address == currentRecord.wallet_address)[0])
    }
  }, [currentRecord]);

  const handleOk = async() => {

    console.log("channel Details Updated")
    try {
      setIsUpdateBtnLoading(true)
      const response = await apiPOST("channel/UpdateChannelWalletDetails", {
        channel_name: name,
        stream_hls: hlsStream,
        channel_id: channelID,
        wallet_address: currentSelectedWallet.address 
      });
      setIsUpdateBtnLoading(false)
      message.success(`Updated record successfully`);
      message.success(`Updated record successfully`);
      if (!response.success) {
        //navigate("/login");
      }
    } catch (error) {
     // navigate("/login");
    }

    onCreate({
      name,
      walletAddress,
      hlsStream,
      prompt,
    });
  };

  const handleItemClick = async(item) => {
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedWallet(item);
  };

  const handleCancel = () => {
    onClose();
  };

  const handlePreviewChanges = () => {
    setIsChangedPreviewed(true);
    
    if(Hls.isSupported())
		{
      console.log("playing",hlsStream)
		  let	hls = new Hls();
			hls.loadSource(hlsStream);
			let vid = document.getElementById("preview_changes");
			hls.attachMedia(vid);
			previewBtnRef.current.innerText = "Play";
		}
  };

  return (
    <Modal
      title="Edit channel data"
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      loading={isUpdateBtnLoading} 
      okText="Update"
      cancelText="Cancel"
    >
      <Form.Item label="Name">
        <Input value={name} onChange={(e) => setName(e.target.value)} />
      </Form.Item>
      <Form.Item label="Wallet address">
      <div>
           <Dropdown
              type="primary"
              size="3rem"
              loading={false}
              menu={{
                items:
                channels.length > 0
                    ? channels.map((item) => ({
                        ...item,
                        onClick: () => handleItemClick(item),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                <Space>
              {channels.length > 0
                ? currentSelectedWallet.label || "No wallet found"
                : "No Wallets available"}

                <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </div>
      </Form.Item>
      <Form.Item label="HLS Stream">
        <Input
          value={hlsStream}
          onChange={(e) => setHlsStream(e.target.value)}
        />
      </Form.Item>

      {isChangedPreviewed && (
        <div style={{ display: "flex", justifyContent: "center",marginTop:"2%" }}>
          <video id="preview_changes" ref={playerRef} controls width="70%" height="50%" />
        </div>
      )}
    </Modal>
  );
};

export default EditChannelData;
