import { Row, Col, Dropdown, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../../utils/Metamask";
import { apiDELETE, apiGET, apiPOST } from "../../../apis/service";
import {
  refreshListOfChannelBoosts,
  selectListOfTopPlayers,
  setListOfTopPlayers,
} from "../../../redux/slice/channel_boosts/channel_boosts-slice";
import { Form } from "react-router-dom";
import { selectListOfAPIKeys, setListOfAPIKeys } from "../../../redux/slice/api_keys/api_keys-slice";

const TopRewardsTable = ({handleOpenRewardModal}) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [platfroms, setPlatfroms] = useState([]);
  const list_refresher = useSelector(refreshListOfChannelBoosts);
  const list_of_top_players = useSelector(selectListOfTopPlayers);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const list_of_api_keys = useSelector(selectListOfAPIKeys);
  const [currentSelectedKey, setCurrentSelectedKey] = useState(
    list_of_api_keys[0]
  );

  const handleKeyClick = async (item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedKey(item);
  };


  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiPOST("getSdksByWalletAddress", {
          walletAddress: current_connected_wallet,
        });
        if (response.data.length > 0) {
          dispatch(setListOfAPIKeys(response.data));
        } else {
          dispatch(setListOfAPIKeys([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };
    fetchData()
  }, [list_refresher]);


  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const dispatch = useDispatch();

  const handleFormatAddress = (address) => {
    return address.toUpperCase().replace('X','x')
  };
  const fetchDataByKey = async (item) => {
    setLoading(true);
    try {
      let response = await apiGET(`gaimify/GetLeaderBoardByCountry/All/${item.key}/All`, {
      });
      if (response.length > 0) {
        dispatch(setListOfTopPlayers(response));
      } else {
        dispatch(setListOfTopPlayers([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const refreshData = async () => {
    setLoading(true);
    try {
      let response = await apiGET(`gaimify/GetLeaderBoardByCountry/All/${currentSelectedKey.key}/All`, {
      });
      if (response.length > 0) {
        dispatch(setListOfTopPlayers(response));
      } else {
        dispatch(setListOfTopPlayers([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = [];
      list_of_api_keys.forEach((element) => {
        let tmp = {
          label: element.sdk_title,
          key: element.sdk_api_key,
          logo_url: element.logo_url,
        };
        temp.push(tmp);
      });
      setPlatfroms(temp);
      setCurrentSelectedKey(temp[0]);
    }
  }, [list_of_api_keys]);

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiGET(`gaimify/GetLeaderBoardByCountry/All/${currentSelectedKey.key}/All`, {
        });
        if (response.length > 0) {
          dispatch(setListOfTopPlayers(response));
        } else {
          dispatch(setListOfTopPlayers([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [currentSelectedKey]);

  useEffect(() => {
    if (list_of_top_players.length > 0) {
      let temp = [];

      list_of_top_players.forEach((element) => {
        temp.push({
          wallet: element.wallet_address,
          name: element.screen_name,
          flag: element.flag,
          points: element.points
        });
      });
      setItems(temp);
    }else{
      setItems([]);
    }
  }, [list_of_top_players]);


  const columns = [
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      render: (text, record, index) => (
        <div>
          {index + 1}
        </div>
      ),
    },
    {
      title: "Wallet Address",
      dataIndex: "wallet",
      key: "wallet",
      render: (text, record) => (
        <div>
          {handleFormatAddress(text)}
        </div>
      ),
    },
    {
      title: "Screen Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div>
          {text.toUpperCase()}
        </div>
      ),
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      render: (text, record) => (
        <div>
          {text}
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleOpenRewardModal(record)}
          >
            Give Reward
          </Button>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (

    <div style={{ marginTop: "1%" }}>
        <div className="sdks-dropdown">
        
        <label> <span style={{fontWeight: 'bold'}}>Select Platform </span></label>
           <Dropdown
              type="primary"
              size="3rem"
              loading={false}
              menu={{
                items:
                platfroms.length > 0
                    ? platfroms.map((it) => ({
                        ...it,
                        onClick: () => handleKeyClick(it),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                  <Space>
                    {platfroms.length > 0
                      ? currentSelectedKey.label || "No sdk found"
                      : "No SDKs available"}
                    <DownOutlined />
                  </Space>
                </Button>
            </Dropdown>
      </div>
      <Table
        pagination={pagination}
        columns={columns}
        dataSource={items}
        onChange={handleTableChange}
        loading={loading}
        rowKey="wallet"
      />
    </div>
  );
};

export default TopRewardsTable;