import { Divider, Form, Input, Modal, Radio, Space, Switch, Table } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { Button, message } from "antd";
import { CopyOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../../utils/Metamask";
import { apiGET, apiPOST } from "../../../apis/service";
import Highlighter from "react-highlight-words";
import {
  selectTSVWeek,
  setTSVWeek,
  refreshTSV,
  selectChannelViewers,
  setChannelViewers,
} from "../../../redux/slice/dashboard/dashboard-slice";

const ChannelViewerTSV = ({ channel_id, days }) => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const list_refresher = useSelector(refreshTSV);
  const list_channel_tsv = useSelector(selectTSVWeek);
  const [pagination, setPagination] = useState({
    pageSize: 20, // Set the desired number of rows per page
  });
  const channel_viewers = useSelector(selectChannelViewers);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const dispatch = useDispatch();
  const handleCopyAddress = (address) => {
    message.success(`Address ${address} copied to clipboard`);
  };

  const handleEditDomain = async (record) => {
    console.log("Edit domain:", record);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    //fetch the list api keys
    setLoading(true);
    const fetchData = async () => {
      // setLoading(true);
      try {
        let response = await apiGET(
          `tsv/getChannelViewerTsv/${channel_id}/${days}`,
          {}
        );
        if (response.result.length > 0) {
          setItems([]);
          dispatch(setTSVWeek(response.result));
        } else {
          setItems([]);
          dispatch(setTSVWeek([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }


      apiGET(`tsv/getChannelViewersAvg/${channel_id}/${days}`)
        .then((response) => {
          console.log(response);
          dispatch(setChannelViewers(response.result[0]));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();

    let topGamesFetcher = setInterval(() => {
      fetchData();
    }, 60000);

    return () => {
      clearInterval(topGamesFetcher)
    }
  }, [channel_id, days]);

  useEffect(() => {
    if (list_channel_tsv.length > 0) {
      let temp = [];

      list_channel_tsv.forEach((element) => {
        temp.push({
          total_time_spent: element.total_time_spent,
          viewer_address: element.viewer_address,
        });
      });
      setItems(temp);
    } else {
      setItems([]);
    }
  }, [list_channel_tsv]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (
        <div>
          <span>{index + 1}</span>
        </div>
      ),
    },
    {
      title: "Viewer Address",
      dataIndex: "viewer_address",
      key: "viewer_address",
      ...getColumnSearchProps("viewer_address"),
      render: (text, record) => (
        <div>
          <span>{text + ""}</span>
        </div>
      ),
    },
    {
      title: "Time Spent Viewing(Hours)",
      dataIndex: "total_time_spent",
      key: "total_time_spent",
      sorter: (a, b) => a.total_time_spent - b.total_time_spent,
      render: (text, record) => (
        <div>
          <span>{text.toFixed(2) + " h"}</span>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div style={{ marginTop: "1%" }}>
      <Divider/>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingLeft: "25px", paddingRight: "15px" }}>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
          <div style={{ fontWeight: "bold",fontSize:"1.5rem",color:"gray" }}>Viewers : </div>
          <div style={{ fontWeight: "normal",fontSize:"1.5rem" ,marginLeft:"5px"}}>{channel_viewers.viewers_count}</div>
        </div>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
          <div style={{ fontWeight: "bold",fontSize:"1.5rem",color:"gray" }}>Time Spent : </div>
          <div style={{ fontWeight: "normal",fontSize:"1.5rem" ,marginLeft:"5px"}}>{channel_viewers.total_time_spent ? channel_viewers.total_time_spent : 0}</div>
        </div>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
          <div style={{ fontWeight: "bold",fontSize:"1.5rem",color:"gray" }}>Avg: Time Spent : </div>
          <div style={{ fontWeight: "normal",fontSize:"1.5rem" ,marginLeft:"5px"}}>{(channel_viewers.average_time ? parseFloat(channel_viewers.average_time).toFixed(2) : 0) + " Minutes"}</div>
        </div>
      </div>
      <Divider/>
      <Table
      style={{marginTop:"20px"}}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        pagination={pagination}
        columns={columns}
        dataSource={items}
        onChange={handleTableChange}
        loading={loading}
      />
    </div>
  );
};

export default ChannelViewerTSV;
