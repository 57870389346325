import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  Dropdown,
  Col,
  message,
  Row,
  Space,
} from "antd";
import {
  CodeOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import {
  apiDELETE,
  apiGET,
  apiPOST,
  apiPOSTFormData,
} from "../../apis/service";
import {
  setListOfChannelPrompts,
  selectListOfChannelPrompts,
  refreshList,
} from "../../redux/slice/channel_details/channel_details-slice";

import {
  refreshListOfAPIKeys,
  selectListOfAPIKeys,
  setListOfAPIKeys,
} from "../../redux/slice/api_keys/api_keys-slice";
import {
  refreshListOfChannelDetails,
  selectListOfChannelDetails,
  setListOfChannelDetails,
} from "../../redux/slice/channel_details/channel_details-slice";
import EditChannelPrompts from "./EditChannelPrompts";
import TextArea from "antd/es/input/TextArea";

const ChannelPrompts = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState([]);
  const [channels, setChannels] = useState([]);
  const [types, setTypes] = useState([
    { label: "Content Quiz" },
    { label: "Ad Quiz" },
    { label: "Content Wager" },
  ]);
  const [isEditPromptDataOpen, setIsEditPromptDataOpen] = useState(false);
  const [currentSelectedType, setCurrentSelectedType] = useState(types[0]);
  const [promptText, setPromptText] =
    useState(`Internally: First analyze the content of this imperfect speech-to-text transcript from <<<Title>>>.  Next generate several possible 4-option polls based on it which obey the following criteria:
  1. The poll is silly, funny, whacky and engaging without being corny
  2. The incorrect options are not misleading
  3. The poll is relatively easy
  4. The poll is sfw - nothing related to sex, no curse words, no potty humor
  5. Neither the poll question nor answers are meta - they don't mention that they're based on a transcript
  6. Don't reference 'the speaker' in the question or options
  
  Finally select the best poll you generated, improve it to further obey the criteria, and call createPoll.
  
  <<<Transcript>>>`);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [currentSelectedPrompt, setCurrentSelectedPrompt] = useState(null);
  const [promptType, setPromptType] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const list_of_api_keys = useSelector(selectListOfAPIKeys);
  const [currentSelectedKey, setCurrentSelectedKey] = useState(
    list_of_api_keys[0]
  );
  const [currentSelectedChannel, setCurrentSelectedChannel] = useState({});
  const list_details_refresher = useSelector(refreshListOfAPIKeys);
  const list_of_channel_details = useSelector(selectListOfChannelDetails);
  const [isCreateChannelModalOpen, setCreateChannelModalOpen] = useState(false);
  const [isCreateChannelBtnLoading, setIsCreateChannelBtnLoading] =
    useState(false);
  const [data, setData] = useState([]);
  const [userName, setUserName] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const dispatch = useDispatch();

  const list_of_channel_prompts = useSelector(selectListOfChannelPrompts);
  const list_refresher = useSelector(refreshList);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleItemClick = (type) => {
    setCurrentSelectedType(type);
  };
  const handleChannelClick = (channel) => {
    console.log(channel);
    setCurrentSelectedKey(channel);

    // getPrompByChannel(channel.key);
  };

  const handleModelChannelClick = (channel) => {
    console.log(channel);
    setCurrentSelectedChannel(channel);
  };

  const handleOk_EditPromptData = async () => {
    setIsEditPromptDataOpen(false);
    await refreshData();
  };
  const handleCancel_EditPromptData = () => {
    setIsEditPromptDataOpen(false);
  };

  const handleOpenEditPromptModal = (record) => {
    console.log(record);
    setIsEditPromptDataOpen(true);
    setCurrentSelectedPrompt(record);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handlePromptTextChange = (e) => {
    setPromptText(e.target.value);
  };

  const handleWalletChange = (e) => {
    setWalletAddress(e.target.value);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const getPrompByChannel = async (channelID) => {
    setLoading(true);
    try {
      // get list of all api keys
      let response = await apiPOST("channel/GetChannelPrompts", {
        channel_id: channelID,
      });
      if (response.length > 0) {
        setData([]);
        console.log("response", response);
        dispatch(setListOfChannelPrompts(response));
      } else {
        setData([]);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const refreshData = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    setLoading(true);
    try {
      // get list of all api keys
      let response = await apiPOST("channel/GetChannelPromptsByApiKey", {
        apiKey: currentSelectedKey.key,
      });
      if (response.length > 0) {
        setData([]);
        console.log("response", response);
        dispatch(setListOfChannelPrompts(response));
      } else {
        setData([]);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  // get details of channels
  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiPOST("channel/GetChannelDetailsByApiKey", {
          apiKey: currentSelectedKey.key,
        });
        if (response.length > 0) {
          dispatch(setListOfChannelDetails(response));
        } else {
          dispatch(setListOfChannelDetails([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [currentSelectedKey]);

  useEffect(() => {
    fetchSDKs();
  }, []);

  // get details of channels
  const fetchSDKs = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    try {
      let response = await apiPOST("getSdksByWalletAddress", {
        walletAddress: current_connected_wallet,
      });
      console.log("cahnnel Wallet adresses", response.rows);
      if (response.data.length > 0) {
        dispatch(setListOfAPIKeys(response.data));
      } else {
        dispatch(setListOfAPIKeys([]));
      }
      // setLoading(false);
    } catch (e) {
      // setLoading(false);
      //display msg that error in loading sdks
    }
  };
  // fill items from api list
  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = [];
      list_of_api_keys.forEach((element) => {
        let tmp = {
          label: element.sdk_title,
          key: element.sdk_api_key,
          logo_url: element.logo_url,
        };
        temp.push(tmp);
      });
      setKeys(temp);
      setCurrentSelectedKey(temp[0]);
    }
  }, [list_of_api_keys]);

  // fill items from api list
  useEffect(() => {
    if (list_of_channel_details.length > 0) {
      let temp = [];
      list_of_channel_details.forEach((element) => {
        let tmp = {
          label: element.channel_name,
          key: element.channel_id,
        };
        temp.push(tmp);
      });
      setChannels(temp);
      setCurrentSelectedChannel(temp[0]);
    }
  }, [list_of_channel_details]);

  const handleDeletePrompt = async (promptID) => {
    console.log("Deleted", promptID);
    Modal.confirm({
      title: "Are you sure you want to delete this",
      onOk: async () => {
        try {
          let response = await apiPOST("channel/DeleteChannelPrompt", {
            promptID: promptID,
          });

          await refreshData();
        } catch (e) {
          console.log(e);
        }
      },
    });
  };
  const handleOpenCreateChannelModal = () => {
    setCreateChannelModalOpen(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "prompt_id",
      key: "prompt_id",
      render: (text, record, index) => <div>{index + 1}</div>,
    },
    {
      title: "Channel Name",
      dataIndex: "channel_name",
      key: "channel_name",
      ...getColumnSearchProps("channel_name"),
      render: (text, record, index) => <div>{text}</div>,
    },

    {
      title: "Prompt Type",
      dataIndex: "prompt_type",
      key: "prompt_type",
      filters: [
        {
          text: "Content Quiz",
          value: "Content Quiz",
        },
        {
          text: "Content Wager",
          value: "Content Wager",
        },
        {
          text: "Ad Quiz",
          value: "Ad Quiz",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.prompt_type.startsWith(value),
      width: "20%",
      render: (text, record) => <div>{record.prompt_type}</div>,
    },
    {
      title: "Prompt Text",
      dataIndex: "prompt_text",
      key: "prompt_text",
      ellipsis: true,
      render: (text, record) => (
        <div className="ellipsis-text">{record.prompt_text}</div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => <div>{record.created_at}</div>,
    },
    {
      title: "Action",
      dataIndex: "prompt_id",
      key: "prompt_id",
      render: (text, record) => (
        <div>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleOpenEditPromptModal(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeletePrompt(text)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleCreatePromptOk = async () => {
    console.log("new prompt adding..", promptText);
    if (promptText && currentSelectedType.label) {
      // Channel name and image are provided
      let hasTitlePlaceholder = promptText.includes("<<<Title>>>");
      let hasTranscriptPlaceholder = promptText.includes("<<<Transcript>>>");
      if ((!hasTitlePlaceholder, !hasTranscriptPlaceholder)) {
        message.error(
          "Prompt must have the <<<Title>>>  and <<<Transcript>>> tags"
        );
        message.error(
          "Prompt must have the <<<Title>>>  and <<<Transcript>>> tags"
        );
        return;
      }
      try {
        setIsCreateChannelBtnLoading(true);
        setCreateChannelModalOpen(false);
        apiPOST("channel/AddChannelPrompt", {
          prompt_text: promptText,
          prompt_type: currentSelectedType.label,
          channel_id: currentSelectedChannel.key,
        })
          .then(async (response) => {
            console.log(response);
            await refreshData();
            setIsCreateChannelBtnLoading(false);
            message.success(`New Prompt is created!`);
            message.success(`New Prompt is created!`);
          })
          .catch((error) => {
            console.log(error);
            setIsCreateChannelBtnLoading(false);
          });
      } catch (error) {
        // navigate("/login");
        message.error(error);
        message.error(error);
      }
      //setPromptText("")
      // Add your channel creation logic here
    } else {
      message.error("Please provide user name and wallet address");
      message.error("Please provide user name and wallet address");
    }
  };

  const handleCreatePromptCancel = async () => {
    setCreateChannelModalOpen(false);
    //setPromptText("")
  };

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        // get list of all api keys
        let response = await apiPOST("channel/GetChannelPromptsByApiKey", {
          apiKey: currentSelectedKey.key,
        });
        if (response.length > 0) {
          setData([]);
          console.log("response", response);
          dispatch(setListOfChannelPrompts(response));
        } else {
          setData([]);
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [currentSelectedKey]);

  useEffect(() => {
    if (list_of_channel_prompts.length > 0) {
      let temp = [];
      list_of_channel_prompts.forEach((element) => {
        console.log(element);
        let prompts = {
          key: element.prompt_id,
          prompt_id: element.prompt_id,
          channel_name: element.channel_name,
          channel_id: element.channel_id,
          prompt_type: element.type,
          prompt_text: element.prompt_text,
          created_at: element.created_at,
        };
        temp.push(prompts);
      });
      setData(temp);
    } else {
      setData([]);
    }
  }, [list_of_channel_prompts, list_refresher]);

  return (
    <div>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={4} lg={2}>
          <CodeOutlined style={{ fontSize: "3.5rem", color: "#08c" }} />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          className="edge-ai-title"
          style={{ textAlign: "left" }}
        >
          Command Prompts
        </Col>
        <Col xs={24} sm={24} md={16} lg={14}></Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          className="sdks-dropdown"
          style={{ textAlign: "right" }}
        >
          <div className="sdks-dropdown">
            <Form.Item
              label={<span style={{ fontWeight: "bold" }}>Select SDK</span>}
            >
              <Dropdown
                type="primary"
                size="3rem"
                loading={loading}
                menu={{
                  items:
                    keys.length > 0
                      ? keys.map((it) => ({
                          ...it,
                          onClick: () => handleChannelClick(it),
                        }))
                      : [], // Provide an empty array if there are no items
                }}
              >
                <Button type="primary">
                  <Space>
                    {keys.length > 0
                      ? currentSelectedKey.label || "No channel found"
                      : "No channels available"}

                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Form.Item>
          </div>
          <Form.Item>
            <Button
              type="primary"
              loading={isCreateChannelBtnLoading}
              style={{ marginRight: "2%" }}
              onClick={handleOpenCreateChannelModal}
            >
              Add Channel Prompts
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={pagination} // Add pagination prop and set pageSize
        onChange={handleTableChange} // Handle table change events
        scroll={{ x: "max-content" }} // Enable horizontal scrolling
        loading={loading}
        rowKey="prompt_id"
      />
      <EditChannelPrompts
        currentRecord={currentSelectedPrompt}
        isOpen={isEditPromptDataOpen}
        onClose={handleCancel_EditPromptData}
        onCreate={handleOk_EditPromptData}
      />

      <Modal
        title="Add New Channel Prompts"
        visible={isCreateChannelModalOpen}
        onOk={handleCreatePromptOk}
        onCancel={handleCreatePromptCancel}
        width={"90%"}
      >
        <Form>
          <Form.Item label="Select Channel">
            <div>
              <Dropdown
                type="primary"
                size="3rem"
                loading={loading}
                menu={{
                  items:
                    channels.length > 0
                      ? channels.map((it) => ({
                          ...it,
                          onClick: () => handleModelChannelClick(it),
                        }))
                      : [], // Provide an empty array if there are no items
                }}
              >
                <Button type="primary">
                  <Space>
                    {channels.length > 0
                      ? currentSelectedChannel.label || "No channel found"
                      : "No channels available"}

                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </Form.Item>
          <Form.Item label="Select Prompt Type">
            <div>
              <Dropdown
                type="primary"
                size="3rem"
                loading={false}
                menu={{
                  items:
                    types.length > 0
                      ? types.map((item) => ({
                          ...item,
                          onClick: () => handleItemClick(item),
                        }))
                      : [], // Provide an empty array if there are no items
                }}
              >
                <Button type="primary">
                  <Space>
                    {types.length > 0
                      ? currentSelectedType.label || "No type found"
                      : "No type available"}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </Form.Item>
          <Form.Item label="Prompt Text">
            <TextArea
              placeholder="Enter Prompt text"
              value={promptText}
              onChange={handlePromptTextChange}
              autoSize={{ minRows: 15 }}
              showCount={true}
              allowClear={true}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ChannelPrompts;
