import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Input, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress, getWalletAddressChecksum } from "../../utils/Metamask";
import { apiDELETE, apiGET, apiPOST, apiPOSTFormData } from "../../apis/service";
import {
  setSupperUserList,
  selectSupperUserList,
  refreshList,
  refreshSupperUserList
} from "../../redux/slice/auth/auth-slice";

const SuperUsers = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const [isCreateChannelModalOpen, setCreateChannelModalOpen] = useState(false);
  const [isCreateChannelBtnLoading, setIsCreateChannelBtnLoading] =
    useState(false);
  const [data, setData] = useState([]);
  const [userName, setUserName] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  const dispatch = useDispatch();

  const list_of_super_user = useSelector(selectSupperUserList);
  const list_refresher = useSelector(refreshSupperUserList);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handleWalletChange = (e) => {
    setWalletAddress(e.target.value);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const refreshData = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    setLoading(true);
    try {
      // get list of all api keys 
      let response = await apiGET("getAllSuperUserList");
      if (response.length > 0) {
        setData([]);
        console.log("response.data-super",response)
        dispatch(setSupperUserList(response));
      } else {
      }
  
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };
  
  const handleDeleteSDK = async(walletAddress) => {
    console.log("Deleted", walletAddress)
    Modal.confirm({ 
      title: "Are you sure you want to delete this", 
      onOk: async() => { 
        try {
          let response = await apiPOST("DeleteStudioSuperUser", {
            walletAddress: walletAddress
          });
          
          await refreshData()
        } catch (e) {
          console.log(e)
        }
      }, 
      });
  };
  const handleOpenCreateChannelModal = () => {
    setCreateChannelModalOpen(true);
  };

const columns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: (text, record, index) => (
      <div>
        {index+1}
      </div>
    ),
  },
  {
    title: "User Name",
    dataIndex: "user_name",
    key: "user_name",
    render: (text, record, index) => (
      <div>
        {text}
      </div>
    ),
  },
  
  {
    title: "Wallet Address",
    dataIndex: "wallet_address",
    key: "wallet_address",
    render: (text, record) => (
      <div>
       {record.wallet_address}
      </div>
    ),
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (text, record) => (
      <div>
       {record.created_at}
      </div>
    ),
  },
  {
    title: "Action",
    dataIndex: "wallet_address",
    key: "address",
    render: (text) => (
      <div>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteSDK(text)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];

const handleCreateChannelOk = async () => {
  console.log("new super user adding..", userName);
  if (userName && walletAddress) {
    // Channel name and image are provided
    try {
      setIsCreateChannelBtnLoading(true);
      setCreateChannelModalOpen(false);
      let wallet = await getWalletAddressChecksum(walletAddress)
      apiPOST("AddNewStudioSuperUser", {"walletAddress": wallet,"userName": userName })
        .then(async (response) => {
          console.log(response);
          message.info(response.message)
          message.info(response.message)
          await refreshData();
          setIsCreateChannelBtnLoading(false);
          
        })
        .catch((error) => {
          console.log(error);
          setIsCreateChannelBtnLoading(false);
        });
    } catch (error) {
      // navigate("/login");
      // message.error(error);
    }

    // Add your channel creation logic here
  } else {
    // message.error("Please provide user name and wallet address");
  }
};

const handleCreateChannelCancel = async () => {
  setCreateChannelModalOpen(false)
};
  

  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        // get list of all api keys 
        let response = await apiGET("getAllSuperUserList");
        if (response.length > 0) {
          setData([]);
          console.log("response.data-super",response)
          dispatch(setSupperUserList(response));
        } else {
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [list_refresher]);

  useEffect(() => {
    if (list_of_super_user.length > 0) {
      let temp = [];
      list_of_super_user.forEach((element) => {
        console.log(element);
        let api_key = {
          id: element.id,
          key: element.id,
          user_name: element.user_name,
          wallet_address: element.wallet_address,
          created_at: element.created_at,
        };
        temp.push(api_key);
      });
      setData(temp);
    }else{
      setData([])
    }
  }, [list_of_super_user,list_refresher]);
  

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          textAlign: "right",
        }}
      >
        {/* <div>
          {hasSelected ? (
            <Button
              type="primary"
              loading={deleteBtnLoading}
              onClick={handleDeleteAPIKeys}
              danger
            >
              Delete Selected [ {selectedRowKeys.length} ]
            </Button>
          ) : (
            ""
          )}
        </div> */}
      </div>

          <Button
            type="primary"
            loading={isCreateChannelBtnLoading}
            style={{ marginRight: "2%" }}
            onClick={handleOpenCreateChannelModal}
          >
            Add Super User
          </Button>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={pagination} // Add pagination prop and set pageSize
        onChange={handleTableChange} // Handle table change events
        scroll={{ x: "max-content" }} // Enable horizontal scrolling
        loading={loading}
        rowKey="id"
      />

<Modal
        title="Add New Super User"
        visible={isCreateChannelModalOpen}
        onOk={handleCreateChannelOk}
        onCancel={handleCreateChannelCancel}
      >
        <Form>
          <Form.Item label="User Name">
            <Input
              placeholder="Enter name"
              value={userName}
              onChange={handleUserNameChange}
            />
          </Form.Item>
          <Form.Item label="Wallet Address">
            <Input
              placeholder="Enter Wallet Address"
              value={walletAddress}
              onChange={handleWalletChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>

    
  );
};

export default SuperUsers;
