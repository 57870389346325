import { Divider, Modal, Radio, Switch, Table } from "antd";
import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../../utils/Metamask";
import { apiGET, apiPOST } from "../../../apis/service";
import {
  selectChannelGames,
  setChannelGames,
  refreshChannelGames
} from "../../../redux/slice/dashboard/dashboard-slice";

const ChannelGames = ({ apiKey }) => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const list_refresher = useSelector(refreshChannelGames);
  const list_channel_games = useSelector(selectChannelGames);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const dispatch = useDispatch();
  const handleCopyAddress = (address) => {
    message.success(`Address ${address} copied to clipboard`);
  };

  const handleEditDomain = async(record) => {
    console.log("Edit domain:", record);

  };

  // useEffect(() => {
  //   //fetch the list api keys
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       let response = await apiGET("gaimify/GetRecentGamesNumberByChannel/"+apiKey, {
  //       });
  //       if (response.length > 0) {
  //         // setItems([]);
  //         dispatch(setChannelGames(response));
  //       } else {
  //         // setItems([]);
  //         dispatch(setChannelGames([]));
  //       }
  //       setLoading(false);
  //     } catch (e) {
  //       setLoading(false);
  //       //display msg that error in loading sdks
  //     }
  //   };
  //   fetchData();
  //   let topGamesFetcher = setInterval(() => {
  //       fetchData();
  //     }, 60000);

  //     return() => {
  //       clearInterval(topGamesFetcher)
  //     }
  // }, [apiKey]);

  useEffect(() => {
    if (list_channel_games.length > 0) {
      let temp = [];

      list_channel_games.forEach((element) => {
        temp.push({
          channel_name: element.channel_name,
          channel_id: element.channel_id,
          total_game_count: element.total_game_count,
        });
      });
      setItems(temp);
    }else{
      setItems([]);
    }
  }, [list_channel_games]);


  const columns = [
    {
        title: "#",
        dataIndex: "index",
        key: "index",
        render: (text, record, index) => (
          <div>
            <span>{index+1}</span>
          </div>
        ),
      },
    {
      title: "Channel Name",
      dataIndex: "channel_name",
      key: "channel_name",
      render: (text, record) => (
        <div>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Channel ID",
      dataIndex: "channel_id",
      key: "channel_id",
    },
    {
        title: "Games Played",
        dataIndex: "total_game_count",
        key: "total_game_count",
      },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div style={{ marginTop: "1%" }}>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        pagination={pagination}
        columns={columns}
        dataSource={items}
        onChange={handleTableChange}
        loading={loading}
      />
    </div>
  );
};

export default ChannelGames;
