import React, { useState, useEffect } from "react";
import Navbar from "../components/general/Navbar"
import '../styles/about_us.css'
const About_us=(props)=>{
return <div>
    <Navbar/>
    <div className="container">
    <h3>About_us</h3>
    </div>
</div>
}

export default About_us
