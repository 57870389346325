import React, { useState, useEffect } from "react";
import { DatePicker, message } from "antd";

import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login";

import Documentation from "./pages/Documentation";
import About_us from "./pages/About_us";
import Contact from "./pages/Contact";
import Studio from "./pages/Studio";
import Leaderboard from "./pages/Leaderboard";

function App() {

  const navigate = useNavigate();

  useEffect(() => {
    if (window.ethereum) {
      // Subscribe to account change event
      window.ethereum.on('accountsChanged', (accounts) => {
        // The 'accounts' parameter contains the updated accounts array
        console.log('Selected account changed:', accounts[0]);
        // You can handle the account change here
        localStorage.removeItem('user');
        navigate('/login'); // Navigate to the login page or any other page
      });

      // Subscribe to network change event
      window.ethereum.on('networkChanged', (networkId) => {
        // The 'networkId' parameter contains the updated network ID
        console.log('Network changed:', networkId);
        // You can handle the network change here
        localStorage.removeItem('user');
        navigate('/login'); // Navigate to the login page or any other page
      });
    } else {
      console.log('Web3 is not available');
    }
    
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/studio" element={<Studio />} />
      <Route path="/documentation" element={<Documentation />} />
      <Route path="/about_us" element={<About_us />} /> 
      <Route path="/contact_us" element={<Contact />} />
      <Route path="/leaderboard" element={<Leaderboard />} />
    </Routes>
  
  );
}

export default App;
