import React, { useState, useEffect, useRef } from "react";
import { Button, Switch, Table, Modal, Form, Input, Dropdown, message, Col, Space } from "antd";
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import { apiDELETE, apiPOST } from "../../apis/service";
import { DownOutlined, EditOutlined, QrcodeOutlined, SearchOutlined } from "@ant-design/icons";
import EditChannelData from "./EditChannelData";
import QRCodeDisplay from "./QRCodeDisplay";
import {
  refreshListOfChannelDetails,
  selectListOfChannelDetails,
  setListOfChannelDetails,
} from "../../redux/slice/channel_details/channel_details-slice";
import {
  refreshListOfChannelWallets,
  selectListOfChannelWallets,
  setListOfChannelWallets,
} from "../../redux/slice/channel_wallets/channel_wallets-slice";

import {
  refreshListOfAPIKeys,
  selectListOfAPIKeys,
  setListOfAPIKeys,
} from "../../redux/slice/api_keys/api_keys-slice";
import { selectIsSupperUser } from "../../redux/slice/auth/auth-slice";

const ListOfChannels = () => {
  const [wagers, setWagers] = useState([
    { label: "30 Seconds" },
    { label: "1 Minutes" },
    { label: "2 Minutes" },
    { label: "3 Minutes" },
  ]);
  const [currentSelectedWager, setCurrentSelectedWager] = useState(wagers[0]);
  const [items, setItems] = useState([]);
  const [keys, setKeys] = useState([]);
  const [channels, setChannels] = useState([]);
  const list_refresher = useSelector(refreshListOfChannelDetails);
  const list_of_channel_details = useSelector(selectListOfChannelDetails);
  const [winPoint, setWinPoint] = useState("");
  const [losePoint, setLosePoint] = useState("");
  const [isEditWinModalOpen, setEditWinModalOpen] = useState(false);
  const [selectedChannel,setSelectedChannel]=useState({})
  const [pointFlag,setPointFlag]=useState("")
  const [switchValues, setSwitchValues] = useState({});

  const is_supper_user = useSelector(selectIsSupperUser);
  const [isCreateChannelModalOpen, setCreateChannelModalOpen] = useState(false);
  const list_of_channel_wallets = useSelector(selectListOfChannelWallets);
  const [isAddNewChannelBtnLoading,setIsAddNewChannelBtnLoading]=useState(false)

  const [channelName, setChannelName] = useState("");
  const [channelHls, setChannelHls] = useState("");
  const list_of_api_keys = useSelector(selectListOfAPIKeys);
  const [currentSelectedKey, setCurrentSelectedKey] = useState(
    list_of_api_keys[0]
  );
  
  const [currentSelectedWallet, setCurrentSelectedWallet] = useState(
    list_of_channel_wallets[0]
  );

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleCreateChannelOk = async() => {
    // Logic to handle creating the channel details
    if (channelName, channelHls) {
      // Channel name and image are provided
      try {
        setCreateChannelModalOpen(false);
        setIsAddNewChannelBtnLoading(true)
        const response = await apiPOST("channel/AddChannelWalletDetails", {
          channel_address: currentSelectedWallet.address,
          channel_name: channelName,
          stream_hls: channelHls,
          live: true,
        });
        setIsAddNewChannelBtnLoading(false)
        await fetchDataByKey()
        if (response.success) {
          console.log("updated")
        }
      } catch (error) {
        console.log("updated failed")
      }

      setChannelHls("")
      setChannelName("")
      
      // Add your channel creation logic here
    } else {
      message.error(`Please provide channel name, stream HLS and Prompt`);
      message.error(`Please provide channel name, stream HLS and Prompt`);
    }
    
  };

  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys)[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleOpenCreateChannelModal = () => {
    setCreateChannelModalOpen(true);
  };

  const handleCreateChannelCancel = () => {
    setCreateChannelModalOpen(false);
    setChannelHls("")
      setChannelName("")
  };

  const handleItemClick = async(item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedWallet(item);
  };

  const handleChannelNameChange = (e) => {
    setChannelName(e.target.value);
  };

  const handleChannelHlsChange = (e) => {
    setChannelHls(e.target.value);
  };

  const fetchDataByKey = async () => {
    setLoading(true);
    try {
      let response = await apiPOST("channel/GetChannelDetailsByApiKey", {
        apiKey: currentSelectedKey.key,
      });
      if (response.length > 0) {
        dispatch(setListOfChannelDetails(response));
      } else {
        dispatch(setListOfChannelDetails([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };
  const handleKeyClick = async(item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedKey(item);
    await fetchData(item)
  };

  const fetchData = async (item) => {
    setLoading(true);
    try {
      let response = await apiPOST("channel/GetChannelDetailsByApiKey", {
        apiKey: item.key,
      });
      if (response.length > 0) {
        //setItems([]);
        console.log("Channel Details", response )
        dispatch(setListOfChannelDetails(response));
      } else {
        dispatch(setListOfChannelDetails([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  useEffect(() => {
    fetchSDKs();
  }, []);
  
  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      setLoading(true);
      try {
        let response = await apiPOST("channel/GetChannelDetailsByApiKey", {
          apiKey: currentSelectedKey.key,
        });
        console.log("response-GetChannelDetailsByParent", response);
        if (response.length > 0) {
          setItems([]);
          dispatch(setListOfChannelDetails(response));
        } else {
          setItems([]);
          dispatch(setListOfChannelDetails([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
    fetchWallet();
  }, [currentSelectedKey]);

  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = [];
      list_of_api_keys.forEach((element) => {
        let tmp = {
          label: element.sdk_title,
          key: element.sdk_api_key,
          logo_url: element.logo_url,
        };
        temp.push(tmp);
      });
      setKeys(temp);
      setCurrentSelectedKey(temp[0]);
    }
  }, [list_of_api_keys]);

  useEffect(() => {
    if (list_of_channel_wallets.length > 0) {
      let temp = [];
      list_of_channel_wallets.forEach((element) => {
        let tmp = {
          label: element.name,
          address: element.channel_wallet,
        }
        temp.push(tmp);
      });
      console.log(temp)
      setChannels(temp);
      setCurrentSelectedWallet(temp[0]);
    }
  }, [list_of_channel_wallets]);

  const refreshData = async () => {
    setLoading(true);
    try {
      let response = await apiPOST("channel/GetChannelDetailsByApiKey", {
        apiKey: currentSelectedKey.key,
      });
      console.log("response-GetChannelDetails", response);
      if (response.length > 0) {
        setItems([]);
        dispatch(setListOfChannelDetails(response));
      } else {
        setItems([]);
        dispatch(setListOfChannelDetails([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  useEffect(() => {
    setLoading(true)
    if (list_of_channel_details.length > 0) {
      let temp = [];
      let tempSwitches = {}
      list_of_channel_details.forEach((element) => {
        temp.push({
          key: element.channel_id,
          channel_id: element.channel_id,
          channel_name: element.channel_name,
          hls: element.stream_hls,
          wallet_address : element.channel_address,
          // current_prompt: element.prompt_text,
          is_gamified: element.is_gamified,
          is_w2e: element.is_w2e,
          win_point: element.win_point,
          lose_point: element.lose_point,
          chat_gpt: element.chat_gpt,
          is_wager: element.is_wager,
          resolution_time: element.resolution_time,
        });
        tempSwitches[element.channel_id] = {
          isGamified : element.is_gamified,
          isW2E : element.is_w2e,
          chatGPT : element.chat_gpt,
          isWager : element.is_wager,
          resolutionTime : element.resolution_time == 30?element.resolution_time+" Seconds":element.resolution_time/60+" Minutes",
        }
        setLoading(false)
      });
      setSwitchValues(tempSwitches)
      setItems(temp);
    } else {
      setItems([]);
      setSwitchValues({})
      setLoading(false)
    }
  }, [list_of_channel_details]);

  const handleSwitchChanged = (status, record) => {
    console.log(status, record);
    const channelId = record.channel_id;
  setSwitchValues((prevSwitchValues) => ({
    ...prevSwitchValues,
    [channelId]: {
      ...prevSwitchValues[channelId],
      isGamified: status,
    },
  }));
    apiPOST("channel/SetIsGamified", {
      channel_id: record.channel_id,
      gamified: status,
    })
      .then(async(response) => {
        console.log(response);
        // await refreshData()
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleW2ESwitchChanged = (status, record) => {
    console.log(status, record);
    const channelId = record.channel_id;
    setSwitchValues((prevSwitchValues) => ({
      ...prevSwitchValues,
      [channelId]: {
        ...prevSwitchValues[channelId],
        isW2E: status,
      },
    }));
    apiPOST("channel/SetIsW2E", {
      channel_id: record.channel_id,
      isW2e: status,
    })
      .then(async (response) => {
        console.log(response);
        // await refreshData()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleWagerSwitchChanged = (status, record) => {
    console.log(status, record);
    const channelId = record.channel_id;
    setSwitchValues((prevSwitchValues) => ({
      ...prevSwitchValues,
      [channelId]: {
        ...prevSwitchValues[channelId],
        isWager: status,
      },
    }));
    apiPOST("channel/SetIsWager", {
      channel_id: record.channel_id,
      is_wager: status,
    })
      .then(async (response) => {
        console.log(response);
        // await refreshData()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGPTSwitchChanged = (value, record) => {
    console.log(value, record);
    const channelId = record.channel_id;
    setSwitchValues((prevSwitchValues) => ({
      ...prevSwitchValues,
      [channelId]: {
        ...prevSwitchValues[channelId],
        chatGPT: value,
      },
    }));
    apiPOST("channel/SetChatGPT", {
      channel_id: record.channel_id,
      gpt: value,
    })
      .then(async (response) => {
        console.log(response);
        // await refreshData()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResolutionTimeChanged = (value, record) => {
    console.log(value, record);
    const channelId = record.channel_id;
    setSwitchValues((prevSwitchValues) => ({
      ...prevSwitchValues,
      [channelId]: {
        ...prevSwitchValues[channelId],
        resolutionTime: value.label,
      },
    }));

    let time = parseInt(value.label)
    
    apiPOST("channel/SetResolutionTime", {
      channel_id: record.channel_id,
      resolution_time: time==30?time:time*60,
    })
      .then(async (response) => {
        console.log(response);
        // await refreshData()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchWallet = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    try {
      let response = await apiPOST("channel/GetChannelWalletsByKey", {
        apiKey: currentSelectedKey.key,
      });
      console.log("cahnnel Wallet adresses", response.rows);
      if (response.rows.length > 0) {
        dispatch(setListOfChannelWallets(response.rows));
      } else {
        dispatch(setListOfChannelWallets([]));
      }
      // setLoading(false);
    } catch (e) {
      // setLoading(false);
      //display msg that error in loading sdks
    }
  };
  const fetchSDKs = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    try {
      let response = await apiPOST("getSdksByWalletAddress", {
        walletAddress: current_connected_wallet,
      });
      console.log("cahnnel Wallet adresses", response.rows);
      if (response.data.length > 0) {
        dispatch(setListOfAPIKeys(response.data));
      } else {
        dispatch(setListOfAPIKeys([]));
      }
      // setLoading(false);
    } catch (e) {
      // setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const columns = [
    {
      title: "Channel Name",
      dataIndex: "channel_name",
      key: "channel_name",
      ...getColumnSearchProps('channel_name'),
    },
    {
      title: "HLS",
      dataIndex: "hls",
      key: "hls",
      render: (text) => {
        if (text.length > 15) {
          return `${text.substring(0, 15)}...`;
        }
        return text;
      },
    },
    {
      title: "Win Point",
      dataIndex: "win_point",
      key: "win_point",
      render: (text, record) => (
        <div>
          {text}
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleOpenEditWinModal("win",record)}
          >
            Edit
          </Button>
        </div>
      ),
    },
    {
      title: "Lose Point",
      dataIndex: "lose_point",
      key: "lose_point",
      render: (text, record) => (
        <div>
          {text}
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleOpenEditWinModal("lose",record)}
          >
            Edit
          </Button>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          {text}
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleOpenEditChannelModal(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<EditOutlined />}
            loading={deleteBtnLoading}
            onClick={() => handleDeleteAction(record)}
          >
            Delete
          </Button>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleOpenQRCodeModal(record)}
          >
            Get QR Code
          </Button>
        </div>
        
      ),
    },
    {
      title: "Gaimified TV",
      dataIndex: "is_gamified",
      key: "is_gamified",
      render: (text, record) => (
        <div>
          {console.log(record.channel_name+"Gamified",switchValues[record.channel_id].isGamified)}
          {switchValues[record.channel_id].isGamified?
          <Switch
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
          checked={true}
          onChange={(status) => handleSwitchChanged(status, record)}
        />
          :
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            checked={false}
            onChange={(status) => handleSwitchChanged(status, record)}
          />
          }
        </div>
      ),
    },
    {
      title: "W2E",
      dataIndex: "is_w2e",
      key: "is_w2e",
      render: (text, record) => (
        <div>
          {console.log(record.channel_name+"W2E",switchValues[record.channel_id].isW2E)}
          {switchValues[record.channel_id].isW2E
          ?
          <Switch
            checkedChildren="Enabled" 
            unCheckedChildren="Disabled"
            checked={true}
            onChange={(status) => handleW2ESwitchChanged(status, record)}
          />
          :
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            checked={false}
            onChange={(status) => handleW2ESwitchChanged(status, record)}
          />
           }
        </div>
      ),
    },
    {
      title: "Chat GPT",
      dataIndex: "chat_gpt",
      key: "chat_gpt",
      render: (text, record) => (
        <div>
          {switchValues[record.channel_id].chatGPT != 3.5
          ?
          <Switch
            checkedChildren="GPT-4" 
            unCheckedChildren="GPT-3.5"
            checked={true}
            onChange={(status) => handleGPTSwitchChanged(3.5, record)}
          />
          :
          <Switch
          checkedChildren="GPT-4" 
          unCheckedChildren="GPT-3.5"
          checked={false}
          onChange={(status) => handleGPTSwitchChanged(4, record)}
        />}
        </div>
      ),
    },
    {
      title: "Wagers",
      dataIndex: "is_wager",
      key: "is_wager",
      render: (text, record) => (
        <div>
          {switchValues[record.channel_id].isWager
          ?
          <Switch
            checkedChildren="Enabled" 
            unCheckedChildren="Disabled"
            checked={true}
            onChange={(status) => handleWagerSwitchChanged(status, record)}
          />
          :
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            checked={false}
            onChange={(status) => handleWagerSwitchChanged(status, record)}
          />
           }
        </div>
      ),
    },
    {
      title: "Wager Resolution",
      dataIndex: "resolution_time",
      key: "resolution_time",
      render: (text, record) => (
        <div>
         <Dropdown
                type="primary"
                size="3rem"
                loading={false}
                menu={{
                  items:
                    wagers.length > 0
                      ? wagers.map((item) => ({
                          ...item,
                          onClick: () => handleResolutionTimeChanged(item, record),
                        }))
                      : [], // Provide an empty array if there are no items
                }}
              >
                <Button type="primary">
                  <Space>
                    {switchValues[record.channel_id]
                      ? switchValues[record.channel_id].resolutionTime || "No type found"
                      : "No type available"}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
        </div>
      ),
    },
  ];

  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [isEditChannelDataOpen, setIsEditChannelDataOpen] = useState(false);

  const [currentSelectedChannel, setCurrentSelectedChannel] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 50, // Set the desired number of rows per page
  });

  // const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleEditWinCancel = () => {
    setEditWinModalOpen(false);
  };

  const handleChannelWinChange = (e) => {
    pointFlag=="win"?
    setWinPoint(e.target.value):
    setLosePoint(e.target.value)
  };

  const handleOpenEditWinModal = (flag,record) => {
    pointFlag=="win"?
    setWinPoint(record.win_point):
    setLosePoint(record.lose_point)
    console.log("record", record);
    setSelectedChannel(record)
    setPointFlag(flag)
    setEditWinModalOpen(true);
  };
  const handleEditWinOk = async() => {
    // Logic to handle creating the channel
    console.log(selectedChannel)
    try {
      if (pointFlag == "win"){
      let response = await apiPOST("channel/SetWinPoint", {
        channel_id: selectedChannel.channel_id,
        winPoint: winPoint
      });
      message.success(`Win points updated successfully`)
      message.success(`Win points updated successfully`)
    }else{
      let response = await apiPOST("channel/SetLosePoint", {
        channel_id: selectedChannel.channel_id,
        losePoint: losePoint
      });
      message.success(`Lose points updated successfully`)
      message.success(`Lose points updated successfully`)
    }
      
      await refreshData()
    } catch (e) {
      console.log(e)
    }

    setEditWinModalOpen(false);
  };

  const handleDeleteAction = async(record) => {
    console.log("Deleted", record)
    setDeleteBtnLoading(true)
    Modal.confirm({ 
      title: "Are you sure you want to delete this", 
      onOk: async() => { 
        try {
          let response = await apiPOST("channel/DeleteChannelDetails", {
            channel_id: record.channel_id
          });
          message.destroy(`Channel Details Deleted!`)
          message.destroy(`Channel Details Deleted!`)
          setDeleteBtnLoading(false)
          await refreshData()
        } catch (e) {
          console.log(e)
        }
      }, 
      }); 
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleOk_QRCodeModal = () => {
    setIsQRCodeModalOpen(false);
  };
  const handleCancel_QRCodeModal = () => {
    setIsQRCodeModalOpen(false);
  };
  const handleOk_EditChannelData = () => {
    setIsEditChannelDataOpen(false);
    refreshData();
  };
  const handleCancel_EditChannelData = () => {
    setIsEditChannelDataOpen(false);
  };

  const handleOpenEditChannelModal = (record) => {
    console.log(record);
    setIsEditChannelDataOpen(true);
    setCurrentSelectedChannel(record);
  };
  const handleOpenQRCodeModal = (record) => {
    console.log(record);
    setIsQRCodeModalOpen(true);
    setCurrentSelectedChannel(record);
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          textAlign: "right",
        }}
      >
      </div>

      <Button
            type="primary"
            loading={isAddNewChannelBtnLoading}
            style={{ marginRight: "2%" }}
            onClick={handleOpenCreateChannelModal}
          >
            Add New Channel Details
          </Button>
          <Col className="sdks-dropdown" style={{ textAlign: "right" }}>
          <div className="sdks-dropdown">
          <Form.Item label={<span style={{fontWeight: 'bold'}}>Select SDK</span>}>
           <Dropdown
              type="primary"
              size="3rem"
              loading={false}
              menu={{
                items:
                keys.length > 0
                    ? keys.map((it) => ({
                        ...it,
                        onClick: () => handleKeyClick(it),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                <Space>
              {keys.length > 0
                ? currentSelectedKey.label || "No wallets found"
                : "No Wallets available"}

                <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </Form.Item>
            </div>
            </Col>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={items}
        pagination={pagination} // Add pagination prop and set pageSize
        onChange={handleTableChange} // Handle table change events
        scroll={{ x: "max-content" }} // Enable horizontal scrolling
        loading={loading}
        rowKey="channel_id"
      />
      <EditChannelData
        currentRecord={currentSelectedChannel}
        channels={channels}
        isOpen={isEditChannelDataOpen}
        onClose={handleCancel_EditChannelData}
        onCreate={handleOk_EditChannelData}
      />
      <QRCodeDisplay
        currentRecord={currentSelectedChannel}
        isOpen={isQRCodeModalOpen}
        onClose={handleCancel_QRCodeModal}
        onCreate={handleOk_QRCodeModal}
      />

<Modal
        title={pointFlag=="win"?"Edit Win Point":"Edit Lose Point"}
        visible={isEditWinModalOpen}
        onOk={handleEditWinOk}
        onCancel={handleEditWinCancel}
      >
        <Form>
          <Form.Item label={pointFlag=="win"?"Win Point":"Lose Point"}>
            <Input
              placeholder="Enter Point value"
              value={pointFlag=="win"?winPoint:losePoint}
              onChange={handleChannelWinChange}
            />
          </Form.Item>
        </Form>
      </Modal>


      <Modal
        title="Add New Channel Details"
        visible={isCreateChannelModalOpen}
        onOk={handleCreateChannelOk}
        onCancel={handleCreateChannelCancel}
      >
        <Form>
          <Form.Item label="Select Wallet">
          <div>
           <Dropdown
              type="primary"
              size="3rem"
              loading={false}
              menu={{
                items:
                channels.length > 0
                    ? channels.map((item) => ({
                        ...item,
                        onClick: () => handleItemClick(item),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                <Space>
              {channels.length > 0
                ? currentSelectedWallet.label || "No wallet found"
                : "No Wallets available"}

                <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </div>
          </Form.Item>
          <Form.Item label="Channel Name">
            <Input
              placeholder="Enter channel name"
              value={channelName}
              onChange={handleChannelNameChange}
            />
          </Form.Item>
          <Form.Item label="Stream HLS">
            <Input
              placeholder="Enter Stream HLS"
              value={channelHls}
              onChange={handleChannelHlsChange}
            />
          </Form.Item>
          
        </Form>
      </Modal>
    </div>

    
  );
};

export default ListOfChannels;
