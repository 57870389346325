// api_keysSlice.js
import { createSlice } from "@reduxjs/toolkit";

const channel_detailsSlice = createSlice({
  name: "channel_details",
  initialState: {
    list_of_channel_details: [],
    list_of_channel_prompts: [],
    refresh_list: false,
  },
  reducers: {
    setListOfChannelDetails: (state, action) => {
      state.list_of_channel_details = action.payload;
    },
    setListOfChannelPrompts: (state, action) => {
      state.list_of_channel_prompts = action.payload;
    },
    setAPIKeyInListOfChannelDetails: (state, action) => {
      state.list_of_channel_details.push(action.payload);
    },
    refreshList: (state, action) => {
      state.refresh_list = !state.refresh_list;
    },
    removeAPIKeyFromListOfChannelDetails: (state, action) => {
      const channelDetails = action.payload;
      console.log("removing", channelDetails);
      state.list_of_channel_details = state.list_of_channel_details.filter((item,index) => {
        console.log("item.channel_details",item.channel_details==channelDetails[index])
        if (!channelDetails.includes(item.channel_details+"")) {
          return item;
        }
      });
      state.refresh_list=!state.refresh_list
    },
    updateAPIKeyFromListOfChannelDetails: (state, action) => {
      const updatedChannelDetails = action.payload;
      console.log("updating", updatedChannelDetails);
      state.list_of_channel_details = state.list_of_channel_details.map(item => {
        if (item.channel_id === updatedChannelDetails.channel_id) {
          return { ...item, ...updatedChannelDetails };
        }
        return item;
      });

      state.refresh_list = !state.refresh_list;
    },
  },
});

export const {
  setListOfChannelDetails,
  setListOfChannelPrompts,
  setAPIKeyInListOfChannelDetails,
  refreshList,
  removeAPIKeyFromListOfChannelDetails,
  updateAPIKeyFromListOfChannelDetails,
} = channel_detailsSlice.actions;

export const selectListOfChannelDetails = (state) => state.channel_details.list_of_channel_details;
export const selectListOfChannelPrompts = (state) => state.channel_details.list_of_channel_prompts;
export const refreshListOfChannelDetails = (state) => state.channel_details.refresh_list;

export default channel_detailsSlice.reducer;
