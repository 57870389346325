import { SketchOutlined, TabletOutlined } from "@ant-design/icons";
import { Col, List, Row } from "antd";
import "../../styles/supported-plateforms.css";
import Plateform from "./Plateform";
import { useState } from "react";
import Details from "./Details";

import { AiFillAlert,AiFillApple ,AiFillAndroid,AiOutlineAndroid} from "react-icons/ai";
import {MdWeb} from 'react-icons/md'
import {SiAppletv,SiAmazonfiretv} from 'react-icons/si'
const data = [
  {
    title: "Web",
    // icon: "https://img.icons8.com/bubbles/100/domain.png",
    icon:<MdWeb/>,
    desc: "Our SDK offers robust support for web platforms, ensuring that your application is accessible through any web browser. With this compatibility, users can conveniently access your services from both desktop and mobile devices, expanding your reach and providing a seamless web experience.",
    supported_platforms:"Browsers on desktop and mobile devices.",
    supported_os_versions:"Chrome 93, Firefox 93, and Safari 15."
  },
  {
    title: "iOS",
    //icon: "https://img.icons8.com/bubbles/500/mac-os.png",
    icon:<AiFillApple/>,
    desc: "With dedicated support for iOS, our SDK enables smooth integration with iPhone and iPad applications. By incorporating our SDK into your iOS app, you can provide a high-quality user experience tailored specifically for Apple's ecosystem, expanding your reach to iOS users and maximizing your application's potential.",
    supported_platforms:" iPhones and iPads.",
    supported_os_versions:" iOS 14 or later."
  },
  {
    title: "Android",
    //icon: "https://img.icons8.com/bubbles/500/android-os.png",
    icon:<AiFillAndroid/>,
    desc: "The Android platform is one of the most widely used operating systems worldwide, and our SDK is fully optimized for seamless integration with Android applications. By leveraging our SDK, you can tap into the vast user base of Android devices, delivering your services to a large audience and enhancing user engagement.",
    supported_platforms:" Android smartphones, tablets, and smart TVs.",
    supported_os_versions:" Android 5.1 or later."
  },
  {
    title: "Apple Tv",
    //icon: "https://img.icons8.com/bubbles/500/apple-tv.png",
    icon:<SiAppletv/>,
    desc: "Take advantage of the growing popularity of Apple TV by incorporating our SDK, which provides comprehensive support for this platform. By offering a unique and immersive experience on larger screens, you can engage users in a whole new way and ensure your services are accessible on the Apple TV platform.",
    supported_platforms:"Apple TV 4K and Apple TV HD.",
    supported_os_versions:"tvOS 14.0 or later."
  },
  {
    title: "Android Tv",
    //icon: "https://img.icons8.com/fluency/500/android-tv.png",
    icon:<AiOutlineAndroid/>,
    desc: "Our SDK extends compatibility to Android TV, allowing your application to be seamlessly integrated with smart TVs and set-top boxes. By incorporating our SDK into your Android TV app, you can reach users who prefer consuming content on their televisions, expanding your audience and increasing user engagement.",
    supported_platforms:" Google Chromecast with Google TV, NVIDIA Shield TV, Xiaomi Mi Box, Sony Bravia TVs, Philips TVs, Sharp TVs, Hisense TVs.",
    supported_os_versions:"Android TV 5.0 or later."
  },
  {
    title: "Amazon fire Tv",
    //icon: "https://img.icons8.com/plasticine/100/fire-tv.png",
    icon:<SiAmazonfiretv/>,
    desc: "Catering to Amazon's Fire TV ecosystem, our SDK provides seamless compatibility with Fire TV devices. By integrating our SDK into your Fire TV app, you can tap into Amazon's user base and deliver your services to a wide audience of Fire TV device owners.",
    supported_platforms:"Amazon Fire TV Stick, Fire TV Stick Lite, Fire TV Stick 4K, Fire TV Cube, Fire TV Edition TVs.",
    supported_os_versions:"Fire OS 5 or later."
  },
];
const SupportedPlateforms = () => {
  const [currentOpenedSection, setCurrentOpenedSection] = useState("list");
  const [currentSelectedRecord,setCurrentSelectedRecord]=useState(data[0])
  return (
    <div className="supported_plateform_heading">
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={4} lg={2}>
          <SketchOutlined style={{ fontSize: "3.5rem", color: "#08c" }} />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={10}
          className="supported-plateforms-title"
        >
          Supported platform
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          style={{ textAlign: "right" }}
        ></Col>
      </Row>
      {currentOpenedSection == "list" && (
        <div style={{ padding: "2rem" }}>
          <Row gutter={[16, 16]}>
            {data.map((item) => (
              <Col key={item.key} xs={24} sm={24} md={8} lg={6}>
                <Plateform record={item} setCurrentOpenedSection={setCurrentOpenedSection} setCurrentSelectedRecord={setCurrentSelectedRecord}/>
              </Col>
            ))}
          </Row>
        </div>
      )}
      {currentOpenedSection == "details" && (
        <div style={{ padding: "2rem" }}>
          <Details setCurrentOpenedSection={setCurrentOpenedSection} currentSelectedRecord={currentSelectedRecord}/>
        </div>
      )}
    </div>
  );
};

export default SupportedPlateforms;
