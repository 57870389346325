import React, { useState, useEffect } from "react";
import { Avatar, Button, Col, Row } from "antd";
import "../styles/login.css";
import ic_eat_icon from "../assets/images/eat_icon.png";

import {
  connectToMetaMask,
  createSignatureWithMetaMask,
  getCurrentWalletAddress,
  isWalletConnected,
} from "../utils/Metamask";
import { useDispatch } from "react-redux";
import { setCurrentConnectedWallet, setIsSupperUser } from "../redux/slice/auth/auth-slice";
import { useNavigate } from "react-router-dom";
import { apiPOST } from "../apis/service";

const Login = (props) => {
  const [isConnectingWallet, setIsConnectingWallet] = useState(false);
  const [connectBtnText, setConnectBtnText] = useState("Connect wallet");
  const dispatch = useDispatch();
  const navigation = useNavigate();
  useEffect(() => {
    isWalletConnected()
      .then(async (connected) => {
        if (connected) {
          console.log("Wallet is connected");
          //check if token
          let token = JSON.parse(localStorage.getItem("user"));
          if (token != null) {
            //verify if it is vaild still.
            let current_connected_wallet = await getCurrentWalletAddress();
            apiPOST("verifyAuthToken", {
              walletAddress: current_connected_wallet,
            })
              .then((response) => {
                if (response.success) {
                  console.log("response.isSupperAdmin",response.isSupperAdmin)
                  dispatch(setCurrentConnectedWallet(current_connected_wallet));
                  dispatch(setIsSupperUser(response.isSupperAdmin))
                  navigation("/studio");
                } else {
                  dispatch(setCurrentConnectedWallet(null));
                  setConnectBtnText("Connect wallet");
                }
              })
              .catch((error) => {
                setConnectBtnText(error);
                //TODO: Display error message
              });
          } else {
            console.log("token is null");
            setConnectBtnText("Re-authenticate");
          }
        } else {
          console.log("wallet is not connected");
          setConnectBtnText("Connect wallet");
        }
      })
      .catch((error) => {
        console.log("Error checking wallet connection:", error);
        //TODO: Display error message
      });
  }, []);
  const handleConnectWallet = () => {
    setIsConnectingWallet(true);
    setConnectBtnText("Connecting wallet");
    connectToMetaMask()
      .then((currentAccount) => {
        createSignatureWithMetaMask("sign it to access edge studio")
          .then((response) => {
            if(response.success){
            console.log("response.isSupperAdmin",response.isSupperAdmin)
            console.log("Connected account:", currentAccount);
            dispatch(setIsSupperUser(response.isSupperAdmin))
            dispatch(setCurrentConnectedWallet(currentAccount));
            setConnectBtnText("Connected wallet");
            setIsConnectingWallet(false);
            navigation("/studio");
            }else{
              setConnectBtnText("Signature not verified");  
            }
          })
          .catch((error) => {
            setConnectBtnText("Error : " + error);
            //TODO: Display error message
          });
        // Handle successful connection and use the current account address
      })
      .catch((error) => {
        // Handle error in connecting to MetaMask
        console.log("Error connecting to MetaMask:", error);
        setIsConnectingWallet(false);
        setConnectBtnText("Could connected wallet");
        //TODO: Display error message
      });
  };
  return (
    <div className="container">
      <div className="icon_container">
        <img src={ic_eat_icon} width={"20%"} />
      </div>
      <div className="icon_heading">
        <p>EDGE</p>
        <p style={{ color: "#0F93C3", marginLeft: "2%" }}>STUDIO</p>
      </div>
      <div className="icon_sub_heading">
        <h3>
          Unlock the door to your admin kingdom and access your dashboard with a
          single click!
        </h3>
      </div>
      <div className="connect_btn_container">
        <Button
          type="primary"
          style={{ backgroundColor: "black" }}
          loading={isConnectingWallet}
          onClick={handleConnectWallet}
        >
          {connectBtnText}
        </Button>
      </div>
    </div>
  );
};

export default Login;
