import React from "react";
import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../styles/nav-bar.css";
const LeftMenu = ({ mode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSwitchPage = (page) => {
    navigate(`/${page.key}`);
  };
  return (
    <Menu
      onClick={handleSwitchPage}
      className="custom-menu-item"
      defaultSelectedKeys={["studio"]}
      mode={mode}
    >
      <Menu.Item key="studio">
        <span className="menu-item-text">Studio</span>
      </Menu.Item>
      <Menu.Item key="documentation">
        <span className="menu-item-text">Documentation</span>
      </Menu.Item>
      {/* <Menu.Item key="about_us">
        <span className="menu-item-text">About Us</span>
      </Menu.Item>
      <Menu.Item key="contact_us">
        <span className="menu-item-text">Contact Us</span>
      </Menu.Item> */}
    </Menu>
  );
};

export default LeftMenu;
