import { Button, Col, Modal, Row, message, Dropdown, Space } from "antd";
import "../../styles/channel-wallet.css";
import ChannelsTable from "./Table/ChannelsTable";
import { DownOutlined, WalletOutlined } from "@ant-design/icons";
import { Form, Input, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { apiPOSTFile, apiPOST, apiPOSTFormData } from "../../apis/service";
import {
  refreshList,
  refreshListOfAPIKeys,
  selectListOfAPIKeys,
  setListOfAPIKeys,
} from "../../redux/slice/api_keys/api_keys-slice";

import { setListOfChannelWallets } from "../../redux/slice/channel_wallets/channel_wallets-slice";

const ChannelWallets = () => {
  const [channelName, setChannelName] = useState("");
  const [channelImage, setChannelImage] = useState(null);
  const [isCreateChannelBtnLoading, setIsCreateChannelBtnLoading] =
    useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isCreateChannelModalOpen, setCreateChannelModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const list_refresher = useSelector(refreshListOfAPIKeys);
  const list_of_api_keys = useSelector(selectListOfAPIKeys);
  const [currentSelectedKey, setCurrentSelectedKey] = useState(
    list_of_api_keys[0]
  );

  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = [];
      list_of_api_keys.forEach((element) => {
        let tmp = {
          label: element.sdk_title,
          key: element.sdk_api_key,
          logo_url: element.logo_url,
        };
        temp.push(tmp);
      });
      setItems(temp);
      setCurrentSelectedKey(temp[0]);
    }
  }, [list_of_api_keys]);
  const dispatch = useDispatch();
  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiPOST("getSdksByOwnerWalletAddress", {
          walletAddress: current_connected_wallet,
        });
        if (response.data.length > 0) {
          setItems([]);
          dispatch(setListOfAPIKeys(response.data));
        } else {
          setItems([]);
          dispatch(setListOfAPIKeys([]));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [list_refresher]);

  const handleOpenCreateChannelModal = () => {
    setCreateChannelModalOpen(true);
  };

  const handleItemClick = async (item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedKey(item);
    //await fetchData()
  };

  const handleKeyClick = async (item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedKey(item);
    await fetchDataByKey(item);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleCreateChannelOk = async () => {
    // Logic to handle creating the channel
    console.log("channel Creating", channelImage);

    if (channelName) {
      // Channel name and image are provided
      let current_connected_wallet = await getCurrentWalletAddress();
      try {
        let reqData = {
          "parent": current_connected_wallet,
          "walletAddress": current_connected_wallet,
          "apiKey": currentSelectedKey.key,
          "name": channelName
        }
        
        setIsCreateChannelBtnLoading(true);
        setCreateChannelModalOpen(false);
        apiPOST("channel/CreateChannelWallet", reqData)
          .then(async (response) => {
            console.log(response);
            await fetchData();
            setIsCreateChannelBtnLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsCreateChannelBtnLoading(false);
          });
      } catch (error) {
        // navigate("/login");
        message.error(error);
        message.error(error);
      }

      // Add your channel creation logic here
    } else {
      message.error("Please provide channel name and image");
      message.error("Please provide channel name and image");
    }
  };

  const fetchData = async () => {
    let current_connected_wallet = await getCurrentWalletAddress();
    try {
      let response = await apiPOST("channel/GetChannelWalletsByKey", {
        apiKey: currentSelectedKey.key,
      });
      if (response.rows.length > 0) {
        dispatch(setListOfChannelWallets(response.rows));
      } else {
        dispatch(setListOfChannelWallets([]));
      }
    } catch (e) {
      //display msg that error in loading sdks
    }
  };

  const fetchDataByKey = async (item) => {
    setLoading(true);
    try {
      let response = await apiPOST("channel/GetChannelWalletsByKey", {
        apiKey: item.key,
      });
      if (response.rows.length > 0) {
        //setItems([]);
        dispatch(setListOfChannelWallets(response.rows));
      } else {
        dispatch(setListOfChannelWallets([]));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      //display msg that error in loading sdks
    }
  };

  const handleGetConnectButtonCode = () => {
    message.success("Code copied to clip board");
    message.success("Code copied to clip board");
  };
  const handleCreateChannelCancel = () => {
    setCreateChannelModalOpen(false);
  };

  const handleChannelNameChange = (e) => {
    setChannelName(e.target.value);
  };

  const handleImageUploadChange = (info) => {
    console.log("New file", info);
    setSelectedFile(info.file);
    if (info.file.status === "done") {
      // Retrieve the uploaded image file
      const uploadedImage = info.file.originFileObj;
      setChannelImage(uploadedImage);
    }
  };

  return (
    <div className="channel_wallet_container">
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={4} lg={2}>
          <WalletOutlined style={{ fontSize: "3.5rem", color: "#08c" }} />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} className="api-key-title">
          Wallets
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={14}
          className="sdks-dropdown"
          style={{ textAlign: "right" }}
        >
          <Button
            type="primary"
            loading={isCreateChannelBtnLoading}
            style={{ marginRight: "2%" }}
            onClick={handleOpenCreateChannelModal}
          >
            Create New Wallet
          </Button>
          <Button
            type="primary"
            loading={false}
            style={{ marginRight: "2%" }}
            danger
            onClick={handleGetConnectButtonCode}
          >
            Get connect button code
          </Button>
          <div className="sdks-dropdown">
          <Form.Item label={<span style={{fontWeight: 'bold'}}>Select SDK</span>}>
            <Dropdown
              type="primary"
              size="3rem"
              loading={false}
              menu={{
                items:
                  items.length > 0
                    ? items.map((it) => ({
                        ...it,
                        onClick: () => handleKeyClick(it),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                  <Space>
                    {items.length > 0
                      ? currentSelectedKey.label || "No sdk found"
                      : "No SDKs available"}
                    <DownOutlined />
                  </Space>
                </Button>
            </Dropdown>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <div>
        {currentSelectedKey ? (
          <ChannelsTable apiKey={currentSelectedKey.key} />
        ) : (
          ""
        )}
      </div>
      <Modal
        title="Create New Wallet"
        visible={isCreateChannelModalOpen}
        onOk={handleCreateChannelOk}
        onCancel={handleCreateChannelCancel}
      >
        <Form>
          <Form.Item label="Select SDK">
            <div>
              <Dropdown
                type="primary"
                size="3rem"
                loading={false}
                menu={{
                  items:
                    items.length > 0
                      ? items.map((item) => ({
                          ...item,
                          onClick: () => handleItemClick(item),
                        }))
                      : [], // Provide an empty array if there are no items
                }}
              >
                <Button type="primary">
                  <Space>
                    {items.length > 0
                      ? currentSelectedKey.label || "No sdk found"
                      : "No SDKs available"}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </Form.Item>
          <Form.Item label="Wallet Name">
            <Input
              placeholder="Enter name"
              value={channelName}
              onChange={handleChannelNameChange}
            />
          </Form.Item>
          {/* <Form.Item label="Channel Icon">
            <Upload
              onChange={handleImageUploadChange}
              beforeUpload={() => false} // Prevent automatic upload
            >
              <Button icon={<UploadOutlined />}>Select Image</Button>
            </Upload>
            {channelImage && (
              <img
                src={URL.createObjectURL(channelImage)}
                alt="Selected Channel"
                style={{ width: "100%", marginTop: 16 }}
              />
            )}
          </Form.Item> */}
        </Form>
      </Modal>
    </div>
  );
};

export default ChannelWallets;
