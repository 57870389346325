// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    is_user_logged_in:false,
    current_connected_wallet:null,
    is_supper_user:false,
    list_super_users:[],
    list_all_users:[],
    refresh_list: false,
  },
  reducers: {
    setIsUserLoggedIn: (state,action) => {
      state.is_user_logged_in=action.payload
    },
    setCurrentConnectedWallet:(state,action)=>{
      state.current_connected_wallet=action.payload
    },
    setIsSupperUser:(state,action)=>{
      state.is_supper_user=action.payload
    },
    setSupperUserList:(state,action)=>{
      state.list_super_users=action.payload
    },
    setAllUserList:(state,action)=>{
      state.list_all_users=action.payload
    },
    refreshList: (state, action) => {
      state.refresh_list = !state.refresh_list;
    },
  },
});

export const {
    setIsUserLoggedIn,
    setCurrentConnectedWallet,
    setIsSupperUser,
    setAllUserList,
    setSupperUserList,
    refreshList
} = authSlice.actions;

export const selectIsUserLoggedIn = (state) => state.auth.is_user_logged_in;
export const selectCurrentConnectedWallet = (state) => state.auth.current_connected_wallet;
export const selectIsSupperUser = (state) => state.auth.is_supper_user;
export const selectSupperUserList = (state) => state.auth.list_super_users;
export const selectAllUserList = (state) => state.auth.list_all_users;
export const refreshSupperUserList = (state) => state.auth.refresh_list;

export default authSlice.reducer;
