import React from "react";
import { Button, Col, Divider, Dropdown, Form, Row, Space, message } from "antd";
import ic_eat_icon from "../../assets/images/eat_icon.png";
import "../../styles/logo-editor.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getCurrentWalletAddress } from "../../utils/Metamask";
import { apiPOST, apiPOSTFile, apiPOSTFormData } from "../../apis/service";
import {
  refreshList,
  refreshListOfAPIKeys,
  selectListOfAPIKeys,
  setListOfAPIKeys,
} from "../../redux/slice/api_keys/api_keys-slice";
import { DownOutlined } from "@ant-design/icons";
// const items = [
//   {
//     label: "Submit and continue",
//     key: "1",
//   },
// ];
const LogoEditor = () => {
  const [loading, setLoading] = useState(false);
  const [uploadImageBtnLoading,setupLoadImageBtnLoading]=useState(false)
  const [items, setItems] = useState([]);
  const fileInputRef = useRef(null);
  const list_refresher = useSelector(refreshListOfAPIKeys);
  const list_of_api_keys = useSelector(selectListOfAPIKeys);
  const [currentSelectedSdk, setCurrentSelectedSdk] = useState(
    list_of_api_keys[0]
  );

  useEffect(() => {
    if (list_of_api_keys.length > 0) {
      let temp = [];
      list_of_api_keys.forEach((element) => {
        temp.push({
          label: element.sdk_title,
          key: element.sdk_api_key,
          logo_url: element.logo_url,
        });
      });
      setItems(temp);
      setCurrentSelectedSdk(temp[0]);
    }
  }, [list_of_api_keys]);
  const dispatch = useDispatch();
  useEffect(() => {
    //fetch the list api keys
    const fetchData = async () => {
      let current_connected_wallet = await getCurrentWalletAddress();
      setLoading(true);
      try {
        let response = await apiPOST("getSdksByWalletAddress", {
          walletAddress: current_connected_wallet,
        });
        if (response.data.length > 0) {
          setItems([]);
          dispatch(setListOfAPIKeys(response.data));
        } else {
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //display msg that error in loading sdks
      }
    };

    fetchData();
  }, [list_refresher]);

  const handleItemClick = (item) => {
    // Handle dropdown visibility change
    console.log("Dropdown visibility: ", item);
    setCurrentSelectedSdk(item);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    // Do something with the selected file
    //TODO:Upload image to E2C bucket and get URL and update in db.
    let current_connected_wallet = await getCurrentWalletAddress();
    let formData = new FormData();
    formData.append("file",file);
    formData.append("walletAddress", current_connected_wallet);
    formData.append("sdkAPIKey",currentSelectedSdk.key);
    formData.append("sdkTitle",currentSelectedSdk.label);
    setupLoadImageBtnLoading(true)
    apiPOSTFile("uploadLogoImage", formData)
      .then((response) => {
        console.log(response);
        setupLoadImageBtnLoading(false)
        message.success("Logo Updated Successfully!");
        message.success("Logo Updated Successfully!");
        dispatch(refreshList())
      })
      .catch((error) => {
        console.log(error);
        setupLoadImageBtnLoading(false)
      });
  };
  return (
    <div className="logo-editor-container">
      <Row justify="center" align="middle" gutter={[16, 16]}>

        <Col
          xs={24}
          sm={24}
          md={16}
          lg={16}
          className="logo-editor-sdks-dropdown"
        >
          <div>
          <Form.Item label={<span style={{fontWeight: 'bold'}}>Select SDK</span>}>
           <Dropdown
              type="primary"
              size="3rem"
              icon={<DownOutlined />}
              loading={false}
              menu={{
                items:
                  items.length > 0
                    ? items.map((item) => ({
                        ...item,
                        onClick: () => handleItemClick(item),
                      }))
                    : [], // Provide an empty array if there are no items
              }}
            >
              <Button type="primary">
                <Space>
              {items.length > 0
                ? currentSelectedSdk.label || "No sdk found"
                : "No SDKs available"}

                <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Divider style={{ borderWidth: "2px" }} />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} className="image-container">
          {currentSelectedSdk ? (
            <>
              <div style={{ fontSize: "1.2rem", margin: "2%" }}>
                {currentSelectedSdk.label} with id :{" "}
                <span style={{ color: "blue" }}>{currentSelectedSdk.key}</span>{" "}
              </div>
              <img
                src={currentSelectedSdk.logo_url}
                width={"15%"}
                height={"60%"}
              />
            </>
          ) : (
            <div>No SDK available</div>
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} className="controlls-container">
          {currentSelectedSdk && (
            <>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Button
                type="primary"
                loading={uploadImageBtnLoading}
                onClick={handleButtonClick}
              >
                Update Image
              </Button>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LogoEditor;
