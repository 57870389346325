import React, { useState, useEffect } from "react";
import Navbar from "../components/general/Navbar";
import "../styles/contact_us.css";
import ContactUs from "../components/ContactUs/ContactUs";
const Contact = (props) => {
  return (
    <div>
      <Navbar />
      <div className="container">
       <ContactUs/>
      </div>
    </div>
  );
};

export default Contact;
